import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import BigNumber from 'bignumber.js'
import { Box, Flex } from '@chakra-ui/react'
import { promisify } from 'utilities'
import VoteCard from 'components/Vote/VoteOverview/VoteCard'
import ProposalDetail from 'components/Vote/VoteOverview/ProposalDetail'
import ProposalInfo from 'components/Vote/VoteOverview/ProposalInfo'
import { useGrid } from 'components/Basic/Grid'
import { Card } from 'components/Basic/Card'

export const Left = ({
  proposalInfo,
  agreeVotes,
  againstVotes,
  getVoters,
  setAgreeVotes,
  setAgainstVotes
}) => {
  const { gap } = useGrid()
  const numberCheck = number => {
    return new BigNumber(number).isNaN() ? '0' : number
  }

  const voteListFor = useMemo(() => {
    return (
      agreeVotes.result &&
      agreeVotes.result.map(v => ({
        label: v.address,
        value: v.votes
      }))
    )
  }, [agreeVotes.result])

  const voteListAgainst = useMemo(() => {
    return (
      againstVotes.result &&
      againstVotes.result.map(v => ({
        label: v.address,
        value: v.votes
      }))
    )
  }, [againstVotes.result])

  const loadMore = type => {
    if (type === 'for' && agreeVotes.total) {
      promisify(getVoters, {
        id: proposalInfo.id,
        limit: agreeVotes.total,
        filter: 'for'
      })
        .then(res => setAgreeVotes(res.data || {}))
        .catch(() => {
          setAgreeVotes({})
        })
    } else if (againstVotes.total) {
      promisify(getVoters, {
        id: proposalInfo.id,
        limit: againstVotes.total,
        filter: 'against'
      })
        .then(res => setAgainstVotes(res.data || {}))
        .catch(() => {
          setAgainstVotes({})
        })
    }
  }

  return (
    <>
      <Box>
        <Card flexDir="column">
          <ProposalInfo proposalInfo={proposalInfo} />
          <ProposalDetail proposalInfo={proposalInfo} />
        </Card>
      </Box>
      <Flex>
        <VoteCard
          flexGrow={1}
          label="For"
          forNumber={numberCheck(agreeVotes.sumVotes)}
          againstNumber={numberCheck(againstVotes.sumVotes)}
          type="agree"
          addressNumber={numberCheck(agreeVotes.total)}
          emptyNumber={4 - numberCheck(agreeVotes.total)}
          list={voteListFor}
          onViewAll={() => loadMore('for')}
        />
        <Box w={gap} />
        <VoteCard
          flexGrow={1}
          label="Against"
          forNumber={numberCheck(agreeVotes.sumVotes)}
          againstNumber={numberCheck(againstVotes.sumVotes)}
          type="against"
          addressNumber={numberCheck(againstVotes.total)}
          emptyNumber={4 - numberCheck(againstVotes.total)}
          list={voteListAgainst}
          onViewAll={() => loadMore('against')}
        />
      </Flex>
    </>
  )
}

Left.propTypes = {
  proposalInfo: PropTypes.object,
  agreeVotes: PropTypes.object,
  againstVotes: PropTypes.object,
  getVoters: PropTypes.func,
  setAgreeVotes: PropTypes.func,
  setAgainstVotes: PropTypes.func
}

Left.defaultProps = {
  proposalInfo: {},
  agreeVotes: 0,
  againstVotes: 0,
  getVoters: () => {},
  setAgreeVotes: () => {},
  setAgainstVotes: () => {}
}
