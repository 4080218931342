import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import BigNumber from 'bignumber.js'
import { compose } from 'recompose'
import { Icon } from 'antd'
import Button from '@material-ui/core/Button'
import NumberFormat from 'react-number-format'
import { connectAccount } from 'core'
import { getUsxControllerContract, methods } from 'utilities/ContractService'
import commaNumber from 'comma-number'
import feeImg from 'assets/img/fee_dark.png'
import usxImg from 'assets/img/coins/usx.png'
import { TabSection, TabContent } from 'components/Basic/SupplyModal'
import { getBigNumber } from 'utilities/common'
import { useWeb3React } from '@web3-react/core'
import * as constants from 'utilities/constants'

const format = commaNumber.bindWith(',', '.')

function MintTab({ settings }) {
  const [isLoading, setIsLoading] = useState(false)
  const [amount, setAmount] = useState(new BigNumber(0))
  const [mintableUsx, setMintableUsx] = useState(new BigNumber(0))
  const [feePercent, setFeePercent] = useState(new BigNumber(0))
  const { account } = useWeb3React()

  useEffect(() => {
    let mounted = true
    const getFeePercent = async () => {
      const appContract = getUsxControllerContract()
      const treasuryPercent = await methods.call(
        appContract.methods.treasuryPercent,
        []
      )
      if (mounted) {
        setFeePercent(
          new BigNumber(treasuryPercent).times(100).div(constants.mantissa)
        )
      }
      return () => {
        mounted = false
      }
    }
    if (mounted) getFeePercent()
    return () => {
      mounted = false
    }
  }, [])

  useEffect(() => {
    let mounted = true
    if (mounted) setMintableUsx(getBigNumber(settings.mintableUsx))
    return () => {
      mounted = false
    }
  }, [settings.mintableUsx])

  /**
   * Max amount
   */
  const handleMaxAmount = () => {
    const totalBorrowBalance = getBigNumber(settings.totalBorrowBalance)
    const totalBorrowLimit = getBigNumber(settings.totalBorrowLimit)
    const safeMax = BigNumber.maximum(
      totalBorrowLimit
        .times(40)
        .div(100)
        .minus(totalBorrowBalance),
      new BigNumber(0)
    )
    setAmount(BigNumber.minimum(mintableUsx, safeMax))
  }
  /**
   * Mint
   */
  const handleMintUSX = () => {
    if (account) {
      const appContract = getUsxControllerContract()
      setIsLoading(true)
      methods
        .send(
          appContract.methods.mintUSX,
          [
            amount
              .times(new BigNumber(10).pow(18))
              .dp(0)
              .toString(10)
          ],
          account
        )
        .then(() => {
          setAmount(new BigNumber(0))
          setIsLoading(false)
        })
        .catch(() => {
          setIsLoading(false)
        })
    }
  }

  return (
    <TabSection>
      <div className="flex flex-column align-center just-center body-content mint-usx-content">
        <div className="flex align-center input-wrapper">
          <NumberFormat
            autoFocus
            value={amount.isZero() ? '0' : amount.toString(10)}
            onValueChange={({ value }) => {
              setAmount(new BigNumber(value))
            }}
            isAllowed={({ value }) => {
              return new BigNumber(value || 0).isLessThanOrEqualTo(mintableUsx)
            }}
            thousandSeparator
            allowNegative={false}
            placeholder="0"
          />
          <span className="pointer max" onClick={() => handleMaxAmount()}>
            SAFE MAX
          </span>
        </div>
      </div>
      <TabContent className="flex flex-column align-center just-content usx-content-section">
        <div className="flex flex-column just-center align-center apy-content">
          <div className="description">
            <div className="flex align-center">
              <img className="asset-img" src={usxImg} alt="asset" />
              <div className="usx-balance">
                <span>Available USX</span>
                <span>Limit</span>
              </div>
            </div>
            <span>{format(mintableUsx.dp(2, 1).toString(10))} USX</span>
          </div>
          <div className="description">
            <div className="flex align-center">
              <img className="asset-img" src={feeImg} alt="asset" />
              <div className="usx-balance">
                <span>Mint Fee</span>
              </div>
            </div>
            <span>
              {!amount.isNaN()
                ? new BigNumber(amount)
                    .times(feePercent / 100)
                    .dp(4)
                    .toString(10)
                : 0}{' '}
              USX ({feePercent.toString(10)}%)
            </span>
          </div>
        </div>
        <Button
          className="button usx-auto"
          disabled={
            isLoading ||
            amount.isNaN() ||
            amount.isZero() ||
            amount.isGreaterThan(mintableUsx)
          }
          onClick={handleMintUSX}
        >
          {isLoading && <Icon type="loading" />} Mint USX
        </Button>
        <div className="description">
          <span>USX Balance</span>
          <span>
            {format(
              getBigNumber(settings.userUsxBalance)
                .dp(2, 1)
                .toString(10)
            )}{' '}
            USX
          </span>
        </div>
      </TabContent>
    </TabSection>
  )
}

MintTab.propTypes = {
  settings: PropTypes.object
}

MintTab.defaultProps = {
  settings: {}
}

const mapStateToProps = ({ account }) => ({
  settings: account.setting
})

export default compose(connectAccount(mapStateToProps, undefined))(MintTab)
