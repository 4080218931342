/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'antd'
import styled from 'styled-components'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import commaNumber from 'comma-number'
import moment from 'moment'
import { Card } from 'components/Basic/Card'
import { Center, Spinner } from '@chakra-ui/react'
import {
  getSrc1TokenContract,
  getWeb3
} from '../../../utilities/ContractService'
import { SRC1_SYMBOL, TIMESTAMP_START } from '../../../utilities/constants'
import { BASE_BSC_SCAN_URL } from '../../../config'

const TransactionsWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background-color: var(--color-bg-primary);
  padding: 27px 36px 29px 30px;

  .title {
    font-size: 17px;
    font-weight: 900;
    color: var(--color-text-main);
    margin-bottom: 23px;
  }

  .action-column {
    width: 40%;
    text-align: left;
  }

  .age-column {
    width: 40%;
    text-align: left;
  }

  .result-column {
    width: 20%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    word-break: break-word;
  }

  .header-text {
    font-size: 16px;
    font-weight: normal;
    padding: 15px 0px;
    color: var(--color-text-secondary);
    border-bottom: 1px solid var(--color-bg-active);
    border-top: 1px solid var(--color-bg-active);
  }

  .row-text {
    font-size: 17px;
    font-weight: 500;
    padding: 15px 0px;
    color: var(--color-text-main);
    border-bottom: 1px solid var(--color-bg-active);
  }

  .green-color {
    color: var(--color-green);
  }

  .red-color {
    color: var(--color-red);
  }

  .view-more {
    margin-top: auto;
    padding-top: 20px;
    font-size: 16px;
    font-weight: bold;
    color: var(--color-text-main);
  }

  .spinner {
    margin-top: 15px;
  }
`

const format = commaNumber.bindWith(',', '.')

function Transactions({ address }) {
  const [data, setData] = useState([])
  const [isLoading, setLoading] = useState(false)
  const getDate = blockNumber => {
    const timestamp = new BigNumber(blockNumber)
      .times(3)
      .plus(TIMESTAMP_START)
      .toNumber()
    const startDate = moment(timestamp * 1000)
    const curDate = moment(new Date())
    const duration = moment.duration(curDate.diff(startDate))

    const days = Math.floor(duration.asDays())
    const hours = Math.floor(duration.asHours()) - days * 24
    return `${days} days${hours ? `, ${hours}hrs` : ''} ago`
  }

  useEffect(() => {
    let mounted = true
    const fetchData = async () => {
      if (mounted) setLoading(true)
      const tempData = []
      const web3 = getWeb3()
      const newestBlock = await web3.eth.getBlockNumber()
      let latest = newestBlock
      const src1 = getSrc1TokenContract()
      do {
        // TODO: Optimize the fetch transactions
        // eslint-disable-next-line no-await-in-loop
        await src1.getPastEvents(
          'Transfer',
          {
            fromBlock: latest - 5000,
            toBlock: latest
          },
          (error, events) => {
            for (let i = 0; i < events.length; i += 1) {
              if (
                events[i].returnValues.dst.toLowerCase() ===
                address.toLowerCase()
              ) {
                tempData.push({
                  action: `Received ${SRC1_SYMBOL}`,
                  age: getDate(events[i].blockNumber),
                  result: format(
                    new BigNumber(events[i].returnValues.wad)
                      .div(1e18)
                      .decimalPlaces(2)
                      .toString(10)
                  ),
                  isReceived: true
                })
              } else if (
                events[i].returnValues.src.toLowerCase() ===
                address.toLowerCase()
              ) {
                tempData.push({
                  action: `Sent ${SRC1_SYMBOL}`,
                  age: getDate(events[i].blockNumber),
                  result: format(
                    new BigNumber(events[i].returnValues.wad)
                      .div(1e18)
                      .decimalPlaces(2)
                      .toString(10)
                  ),
                  isReceived: false
                })
              }
            }
          }
        )
        latest -= 5000
      } while (tempData.length < 4 && latest > newestBlock - 1e6)
      if (mounted) {
        setLoading(false)
        setData([...tempData])
      }
    }
    if (data.length === 0) {
      fetchData()
    }
    return () => {
      mounted = false
    }
  })

  const handleLink = () => {
    window.open(`${BASE_BSC_SCAN_URL}/address/${address}`, '_blank')
  }
  return (
    <Card>
      <TransactionsWrapper className="flex flex-column">
        <div className="title">Transactions</div>
        <div className="flex algin-center header-text">
          <div className="action-column">Action</div>
          <div className="age-column">Age</div>
          <div className="result-column">Result</div>
        </div>
        <div className="flex flex-column data-list">
          {isLoading ? (
            <div className="spinner">
              <Center>
                <Spinner color="primary.500" size="xl" speed="1s" />
              </Center>
            </div>
          ) : (
            data &&
            data.map((item, index) => (
              <div className="flex align-center row-text" key={index}>
                <div className="action-column">{item.action}</div>
                <div className="age-column">{item.age}</div>
                <div className="result-column">
                  <span>{item.result}</span>
                  {item.isReceived ? (
                    <Icon type="arrow-up" className="green-color" />
                  ) : (
                    <Icon type="arrow-down" className="red-color" />
                  )}
                </div>
              </div>
            ))
          )}
        </div>
        <div
          className="flex align-center just-center view-more pointer highlight"
          onClick={() => handleLink()}
        >
          {isLoading ? 'LOADING...' : 'VIEW MORE'}
        </div>
      </TransactionsWrapper>
    </Card>
  )
}

Transactions.propTypes = {
  address: PropTypes.string
  // transactions: PropTypes.array
}

Transactions.defaultProps = {
  address: ''
  // transactions: []
}

export default compose(withRouter)(Transactions)
