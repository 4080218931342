import React from 'react'
import { Flex, useColorModeValue } from '@chakra-ui/react'

export const Card = props => (
  <Flex
    h="100%"
    borderRadius="md"
    my={6}
    // mx="15px"
    justifyContent="center"
    bg={useColorModeValue('white', 'black.500')}
    py={6}
    px={6}
    {...props}
  />
)
