/* eslint-disable no-useless-escape */
import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import BigNumber from 'bignumber.js'
import MainLayout from 'containers/Layout/MainLayout'
import { connectAccount, accountActionCreators } from 'core'
import { promisify } from 'utilities'
import * as constants from 'utilities/constants'
import OverviewChart from 'components/Basic/OverviewChart'
import MarketInfo from 'components/MarketDetail/MarketInfo'
import MarketSummary from 'components/MarketDetail/MarketSummary'
import InterestRateModel from 'components/MarketDetail/InterestRateModel'
// import { getBigNumber } from 'utilities/common';
import { GridItem, Box, Center, Spinner } from '@chakra-ui/react'
import { Grid } from 'components/Basic/Grid'
import { Card } from 'components/Basic/Card'
import { useWeb3React } from '@web3-react/core'
import { MarketsContext } from 'context/MarketsContext'
import { logErrorService } from '../../utilities/errorHandler'

function MarketDetail({ match, settings, getMarketHistory }) {
  const [marketType, setMarketType] = useState('supply')
  const [currentAsset, setCurrentAsset] = useState('')
  const [data, setData] = useState([])
  const [marketInfo, setMarketInfo] = useState({})
  // const [currentAPY, setCurrentAPY] = useState(0);
  const { account } = useWeb3React()
  const { availableSbeps } = React.useContext(MarketsContext)

  useEffect(() => {
    let mounted = true
    if (match.params && match.params.asset) {
      if (mounted) setCurrentAsset(match.params.asset.toLowerCase())
    }
    return () => {
      mounted = false
    }
  }, [match])

  const getGovernanceData = useCallback(
    async mounted => {
      if (settings.markets && settings.markets.length > 0 && currentAsset) {
        const info = settings.markets.find(
          item => item.underlyingSymbol.toLowerCase() === currentAsset
        )
        if (mounted) setMarketInfo(info || {})
      }
    },
    [settings.markets, currentAsset]
  )

  useEffect(() => {
    let mounted = true
    try {
      if (settings.markets && settings.vaultUsxStaked) {
        getGovernanceData(mounted)
      }
    } catch (error) {
      logErrorService(error)
    }
    return () => {
      mounted = false
    }
  }, [getGovernanceData])

  const getGraphData = useCallback(
    (asset, type, limit, mounted) => {
      ;(async () => {
        const tempData = []
        await promisify(getMarketHistory, { asset, type, limit }).then(res => {
          res.data.result.forEach(m => {
            tempData.push({
              createdAt: m.createdAt,
              supplyApy: +new BigNumber(m.supplyApy || 0)
                .decimalPlaces(constants.graphDataDecimals)
                .toString(constants.stringFormat),
              borrowApy: +new BigNumber(m.borrowApy || 0)
                .decimalPlaces(constants.graphDataDecimals)
                .toString(constants.stringFormat),
              totalSupply: +new BigNumber(m.totalSupply || 0)
                .decimalPlaces(constants.graphDataDecimals)
                .toString(constants.stringFormat),
              totalBorrow: +new BigNumber(m.totalBorrow || 0)
                .decimalPlaces(constants.graphDataDecimals)
                .toString(constants.stringFormat)
            })
          })
          if (mounted) setData([...tempData.reverse()])
        })
      })()
    },
    [getMarketHistory]
  )

  useEffect(() => {
    let mounted = true
    try {
      if (currentAsset) {
        getGraphData(
          availableSbeps[currentAsset].address,
          '1day',
          30, // 1 month
          mounted
        )
      }
    } catch (error) {
      logErrorService(error)
    }
    return () => {
      mounted = false
    }
  }, [currentAsset])

  return (
    <MainLayout isHeader={false}>
      {(!account ||
        !settings.markets ||
        !currentAsset ||
        settings.accountLoading ||
        settings.wrongNetwork) && (
        <Center>
          <Spinner color="primary.500" size="xl" speed="1s" />
        </Center>
      )}
      {account &&
        settings.markets &&
        settings.decimals &&
        currentAsset &&
        marketInfo &&
        !settings.accountLoading &&
        !settings.wrongNetwork && (
          <>
            <Grid
              mobile={
                <>
                  <GridItem colSpan={{ base: 3, sm: 1 }}>
                    <Box>
                      <Card display="block" my={{ base: 0, sm: 6 }}>
                        <MarketInfo
                          marketInfo={marketInfo}
                          marketType={marketType}
                        />
                        <MarketSummary
                          marketInfo={marketInfo}
                          currentAsset={currentAsset}
                        />
                      </Card>
                    </Box>
                  </GridItem>
                  <GridItem colSpan={{ base: 3, sm: 2 }}>
                    <Box>
                      <Card display="block">
                        <div className="flex align-center market-tab-wrapper">
                          <div
                            className={`tab-item pointer ${
                              marketType === 'supply' ? 'tab-active' : ''
                            }`}
                            onClick={() => setMarketType('supply')}
                          >
                            Supply
                          </div>
                          <div
                            className={`tab-item pointer ${
                              marketType === 'borrow' ? 'tab-active' : ''
                            }`}
                            onClick={() => setMarketType('borrow')}
                          >
                            Borrow
                          </div>
                        </div>
                        {data ? (
                          <OverviewChart
                            marketType={marketType}
                            graphType="composed"
                            data={data}
                          />
                        ) : (
                          <Center>
                            <Spinner color="primary.500" size="xl" speed="1s" />
                          </Center>
                        )}
                      </Card>
                    </Box>
                    <Box>
                      <Card display="block">
                        <InterestRateModel currentAsset={currentAsset} />
                      </Card>
                    </Box>
                  </GridItem>
                </>
              }
            />
          </>
        )}
    </MainLayout>
  )
}

MarketDetail.propTypes = {
  match: PropTypes.object,
  settings: PropTypes.object,
  getMarketHistory: PropTypes.func.isRequired
}

MarketDetail.defaultProps = {
  match: {},
  settings: {}
}

const mapStateToProps = ({ account }) => ({
  settings: account.setting
})

const mapDispatchToProps = dispatch => {
  const { getMarketHistory } = accountActionCreators

  return bindActionCreators(
    {
      getMarketHistory
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connectAccount(mapStateToProps, mapDispatchToProps)
)(MarketDetail)
