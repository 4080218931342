import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { Text, Flex } from '@chakra-ui/react'
import { ArrowRight } from 'components/Icons/ArrowRight'

function Header({ title, history }) {
  const handleRoute = () => {
    if (title === 'Vote' || title === 'Details') {
      history.go(-1)
    }
    if (title === 'Market') {
      history.push('/market')
    }
  }

  return (
    <Flex alignItems="center" justifyContent="space-between" h="50px">
      <Flex alignItems="center" cursor="pointer" onClick={handleRoute}>
        {(title === 'Vote' || title === 'Details' || title === 'Market') && (
          <ArrowRight
            fill="primary.500"
            rotate="180deg"
            transform="auto"
            mr={6}
          />
        )}
        <Text fontSize="20px" color="primary.500">
          {title}
        </Text>
      </Flex>
    </Flex>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  history: PropTypes.object
}

Header.defaultProps = {
  title: '',
  history: {}
}
export default compose(withRouter)(Header)
