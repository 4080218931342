import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { Icon, Progress } from 'antd'
import Button from '@material-ui/core/Button'
import NumberFormat from 'react-number-format'
import { bindActionCreators } from 'redux'
import { connectAccount, accountActionCreators } from 'core'
import BigNumber from 'bignumber.js'
import { getSbepContract, methods } from 'utilities/ContractService'
import commaNumber from 'comma-number'
import arrowRightImg from 'assets/img/arrow-right.png'
import src1 from 'assets/img/source_one_64.png'
import usxImg from 'assets/img/coins/usx.png'
import { TabSection, Tabs, TabContent } from 'components/Basic/BorrowModal'
import { getBigNumber, getTransactionErrorMessage } from 'utilities/common'
import { useWeb3React } from '@web3-react/core'
import toast from 'components/Basic/Toast'

const format = commaNumber.bindWith(',', '.')

function BorrowTab({
  asset,
  settings,
  changeTab,
  onCancel,
  setSetting,
  amount,
  setAmount,
  borrowBalance,
  newBorrowBalance,
  borrowPercent,
  newBorrowPercent
}) {
  const [isLoading, setIsLoading] = useState(false)
  const { account } = useWeb3React()

  const hidePendingBorrow = () => {
    setSetting({
      pendingInfo: {
        type: '',
        status: false,
        amount: 0,
        symbol: ''
      }
    })
  }

  const hideBorrow = () => {
    setAmount(new BigNumber(0))
    onCancel()
    setIsLoading(false)
    hidePendingBorrow()
  }

  const handleTransactionError = code => {
    toast.error({
      title: getTransactionErrorMessage(code)
    })
  }

  /**
   * Borrow
   */
  const handleBorrow = () => {
    const appContract = getSbepContract(asset.id)
    if (asset && account) {
      setIsLoading(true)
      setSetting({
        pendingInfo: {
          type: 'Borrow',
          status: true,
          amount: amount.dp(8, 1).toString(10),
          symbol: asset.symbol
        }
      })
      methods
        .send(
          appContract.methods.borrow,
          [
            amount
              .times(new BigNumber(10).pow(settings.decimals[asset.id].token))
              .integerValue()
              .toString(10)
          ],
          account
        )
        .then(() => {
          hideBorrow()
        })
        .catch(e => {
          handleTransactionError(e.code)
          hidePendingBorrow()
          setIsLoading(false)
        })
    }
  }
  /**
   * Max amount
   */
  const handleMaxAmount = () => {
    const totalBorrowBalance = getBigNumber(settings.totalBorrowBalance)
    const totalBorrowLimit = getBigNumber(settings.totalBorrowLimit)
    const tokenPrice = getBigNumber(asset.tokenPrice)
    const safeMax = BigNumber.maximum(
      totalBorrowLimit
        // TODO: add accurate collateral factor
        .times(40)
        .div(100)
        .minus(totalBorrowBalance),
      new BigNumber(0)
    )
    setAmount(BigNumber.minimum(safeMax, asset.liquidity).div(tokenPrice))
  }

  return (
    <TabSection>
      <div className="flex flex-column align-center just-center body-content">
        <div className="flex align-center input-wrapper">
          <NumberFormat
            autoFocus
            value={amount.isZero() ? '0' : amount.toString(10)}
            onValueChange={values => {
              const { value } = values
              setAmount(new BigNumber(value))
            }}
            isAllowed={({ value }) => {
              const totalBorrowBalance = getBigNumber(
                settings.totalBorrowBalance
              )
              const totalBorrowLimit = getBigNumber(settings.totalBorrowLimit)
              return new BigNumber(value || 0)
                .plus(totalBorrowBalance)
                .isLessThanOrEqualTo(totalBorrowLimit)
            }}
            thousandSeparator
            allowNegative={false}
            placeholder="0"
          />
          <span className="pointer max" onClick={() => handleMaxAmount()}>
            SAFE MAX
          </span>
        </div>
      </div>
      <Tabs className="flex align-center">
        <div
          className="flex align-center just-center tab-item pointer tab-active"
          onClick={() => {
            changeTab('borrow')
          }}
        >
          Borrow
        </div>
        <div
          className="flex align-center just-center tab-item pointer"
          onClick={() => {
            changeTab('repayBorrow')
          }}
        >
          Repay Borrow
        </div>
      </Tabs>
      <TabContent className="flex flex-column align-center just-content">
        <div className="flex flex-column just-center align-center apy-content">
          <div className="description">
            <div className="flex align-center">
              <img className="asset-img" src={asset.img} alt="asset" />
              <span>Borrow APY</span>
            </div>
            <span>{asset.borrowApy.dp(2, 1).toString(10)}%</span>
          </div>
          <div className="description">
            <div className="flex align-center">
              <img
                style={{
                  width: 25,
                  height: 25,
                  marginLeft: 2,
                  marginRight: 16
                }}
                src={src1}
                alt="asset"
              />
              <span>Distribution APY</span>
            </div>
            <span>
              {getBigNumber(asset.src1BorrowApy)
                .dp(2, 1)
                .toString(10)}
              %
            </span>
          </div>
          <div className="description">
            <div className="flex align-center">
              <img
                style={{
                  width: 25,
                  height: 25,
                  marginLeft: 2,
                  marginRight: 16
                }}
                src={usxImg}
                alt="asset"
              />
              <span>Repay USX Balance</span>
            </div>
            <span>
              {getBigNumber(settings.userUsxMinted)
                .dp(2, 1)
                .toString(10)}{' '}
              USX
            </span>
          </div>
          {!new BigNumber(asset.borrowCaps || 0).isZero() && (
            <div className="description borrow-caps">
              <div className="flex align-center">
                <img
                  style={{
                    width: 25,
                    height: 25,
                    marginLeft: 2,
                    marginRight: 16
                  }}
                  src={src1}
                  alt="asset"
                />
                <span>Borrow Caps</span>
              </div>
              <span>
                {format(
                  new BigNumber(asset.borrowCaps || 0).dp(2, 1).toString(10)
                )}
              </span>
            </div>
          )}
        </div>
        <div className="flex flex-column just-center align-center apy-content">
          <div className="borrow-balance">
            <span>Borrow Balance</span>
            {amount.isZero() || amount.isNaN() ? (
              <span>${borrowBalance.dp(2, 1).toString(10)}</span>
            ) : (
              <div className="flex align-center just-between">
                <span>${borrowBalance.dp(2, 1).toString(10)}</span>
                <img
                  className="arrow-right-img"
                  src={arrowRightImg}
                  alt="arrow"
                />
                <span>${newBorrowBalance.dp(2, 1).toString(10)}</span>
              </div>
            )}
          </div>
          <div className="borrow-limit">
            <span>Borrow Limit Used</span>
            {amount.isZero() || amount.isNaN() ? (
              <span>{borrowPercent.dp(2, 1).toString(10)}%</span>
            ) : (
              <div className="flex align-center just-between">
                <span>{borrowPercent.dp(2, 1).toString(10)}%</span>
                <img
                  className="arrow-right-img"
                  src={arrowRightImg}
                  alt="arrow"
                />
                <span>{newBorrowPercent.dp(2, 1).toString(10)}%</span>
              </div>
            )}
          </div>
          <Progress
            percent={newBorrowPercent.toNumber()}
            strokeColor="#56a4f6"
            strokeWidth={7}
            showInfo={false}
          />
        </div>
        <Button
          className="button"
          disabled={
            isLoading ||
            amount.isZero() ||
            amount.isNaN() ||
            amount.isGreaterThan(asset.liquidity.div(asset.tokenPrice)) ||
            newBorrowPercent.isGreaterThan(100) ||
            (!new BigNumber(asset.borrowCaps || 0).isZero() &&
              amount.plus(asset.totalBorrows).isGreaterThan(asset.borrowCaps))
          }
          onClick={handleBorrow}
        >
          {isLoading && <Icon type="loading" />} Borrow
        </Button>
        <div className="description">
          <span>Protocol Balance</span>
          <span>
            {asset.borrowBalance &&
              format(asset.borrowBalance.dp(2, 1).toString(10))}{' '}
            {asset.symbol}
          </span>
        </div>
      </TabContent>
    </TabSection>
  )
}

BorrowTab.propTypes = {
  asset: PropTypes.object,
  settings: PropTypes.object,
  changeTab: PropTypes.func,
  onCancel: PropTypes.func,
  setSetting: PropTypes.func.isRequired
}

BorrowTab.defaultProps = {
  asset: {},
  settings: {},
  changeTab: () => {},
  onCancel: () => {}
}

const mapStateToProps = ({ account }) => ({
  settings: account.setting
})

const mapDispatchToProps = dispatch => {
  const { setSetting } = accountActionCreators

  return bindActionCreators(
    {
      setSetting
    },
    dispatch
  )
}

export default compose(connectAccount(mapStateToProps, mapDispatchToProps))(
  BorrowTab
)
