import * as constants from 'utilities/constants'
import { getAvailableSbeps } from './common'
import { getWeb3 } from './ContractService'
import { logErrorService } from './errorHandler'

export const sendSupply = async (from, amount, callback) => {
  const web3 = getWeb3()
  const availableSbeps = getAvailableSbeps()

  try {
    const contract = new web3.eth.Contract(
      JSON.parse(constants.CONTRACT_SBNB_ABI),
      availableSbeps.bnb.address
    )
    const contractData = contract.methods.mint().encodeABI()

    const tx = {
      from,
      to: availableSbeps.bnb.address,
      value: amount,
      data: contractData
    }

    await web3.eth.sendTransaction(tx, err => {
      if (!err) {
        callback(true)
      }
      callback(false)
    })
  } catch (err) {
    logErrorService(err)
    // eslint-disable-next-line no-console
    callback(false)
  }
}

export const sendRepay = async (from, amount, callback) => {
  const web3 = getWeb3()
  const { availableSbeps } = getAvailableSbeps()

  try {
    const contract = new web3.eth.Contract(
      JSON.parse(constants.CONTRACT_SBNB_ABI),
      availableSbeps.bnb.address
    )
    const contractData = contract.methods.repayBorrow().encodeABI()

    const tx = {
      from,
      to: availableSbeps.bnb.address,
      value: amount,
      data: contractData
    }
    // Send transaction
    await web3.eth.sendTransaction(tx, err => {
      if (!err) {
        callback(true)
      }
      callback(false)
    })
  } catch (err) {
    logErrorService(err)
    // eslint-disable-next-line no-console
    callback(false)
  }
}
