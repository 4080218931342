// import * as Sentry from '@sentry/react';
// import { BrowserTracing } from '@sentry/tracing';

/* if (process.env.SENTRY_KEY && process.env.SENTRY_PROJECT) {
  Sentry.init({
    dsn: `https://${process.env.SENTRY_KEY}.ingest.sentry.io/${process.env.SENTRY_PROJECT}`,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });
} */

export const logErrorService = error => {
  // Sentry.captureException(error);
  console.error(error)
}
