const auth = {
  user: null
}

const account = {
  setting: {
    selectedAddress: null,
    marketType: 'supply',
    borrowMarket: [],
    supplyMarket: [],
    latestBlockNumber: '',
    decimals: {},
    assetList: [],
    totalBorrowBalance: '0',
    totalBorrowLimit: '0',
    walletType: 'metamask',
    pendingInfo: {
      type: '',
      status: false,
      amount: 0,
      symbol: ''
    },
    vaultUsxStaked: null,
    withSRC1: true,
    markets: []
  }
}
export const initialState = {
  auth,
  account
}
