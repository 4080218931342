import React from 'react'
import PropTypes from 'prop-types'
import { Card } from 'components/Basic/Card'
import { Flex, Text } from '@chakra-ui/react'
import { ArrowRight } from 'components/Icons/ArrowRight'

export const ExchangeCard = ({ addressHandler, label }) => {
  return (
    <Card
      justifyContent="space-between"
      onClick={addressHandler}
      className="pointer"
      bg="secondary.500"
    >
      <Flex alignItems="center">
        <Text fontWeight="bold" color="#000" fontSize="lg" mr={2} mt="4px">
          {label}
        </Text>
      </Flex>
      <Flex alignItems="center" justifyContent="center">
        <ArrowRight w="15px" h="15px" ml="16px" fill="#000" />
      </Flex>
    </Card>
  )
}

ExchangeCard.propTypes = {
  addressHandler: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
}

ExchangeCard.defaultProps = {}
