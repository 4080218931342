import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Row, Col, Icon } from 'antd'
import BigNumber from 'bignumber.js'
import PropTypes from 'prop-types'
import { useWeb3React } from '@web3-react/core'
import NumberFormat from 'react-number-format'
import * as constants from '../../utilities/constants'
import useRefresh from '../../hooks/useRefresh'
import WithdrawHistoryModal from './WithdrawHistoryModal'
import WithdrawCard from './WithdrawCard'
import LoadingSpinner from '../Basic/LoadingSpinner'
import { CardItemWrapper } from './styles'
import {
  getSrc1VaultContract,
  getTokenAddressContract
} from '../../utilities/ContractService'
import { logErrorService } from '../../utilities/errorHandler'

const CardContentWrapper = styled.div`
  color: #fff;
  padding: 16px 40px 0 24px;
  .loading-spinner {
    margin: 16px 0;
  }
`

const getPoolDecimals = token =>
  new BigNumber(10).pow(constants.CONTRACT_POOL_ADDRESS[token].decimals)

const CardContent = ({
  poolId,
  stakedToken,
  rewardToken,
  userStakedAmount,
  pendingReward,
  lockPeriodSecond,
  setUpdateInfo
}) => {
  const { account } = useWeb3React()
  const { fastRefresh } = useRefresh()

  // user info
  const [stakeAmount, setStakeAmount] = useState(new BigNumber(0))
  const [userStakedTokenBalance, setUserStakedTokenBalance] = useState(
    new BigNumber(0)
  )
  const [userStakedTokenAllowance, setUserStakedTokenAllowance] = useState(
    new BigNumber(0)
  )
  const [pendingWithdrawals, setPendingWithdrawals] = useState([])
  const [withdrawableAmount, setWithdrawableAmount] = useState(new BigNumber(0))
  const [userEligibleStakedAmount, setUserEligibleStakedAmount] = useState(
    new BigNumber(0)
  )
  const [historyModalVisible, setHistoryModalVisible] = useState(false)

  const [loading, setLoading] = useState(true)

  // button loading status
  const [claimLoading, setClaimLoading] = useState(false)
  const [stakeLoading, setStakeLoading] = useState(false) // also applies to enabling

  const stakedTokenAddress = constants.CONTRACT_POOL_ADDRESS[stakedToken].token
  const rewardTokenAddress = constants.CONTRACT_POOL_ADDRESS[rewardToken].token

  const src1VaultContract = getSrc1VaultContract()
  const stakedTokenContract = getTokenAddressContract(stakedTokenAddress)

  const balanceData = useCallback(async () => {
    try {
      const isMounted = true
      let [balance, allowance, withdrawals] = ['0', '0', []]
      if (account) {
        ;[balance, allowance, withdrawals] = await Promise.all([
          stakedTokenContract.methods.balanceOf(account).call(),
          stakedTokenContract.methods
            .allowance(account, src1VaultContract.options.address)
            .call(),
          src1VaultContract.methods
            .getWithdrawalRequests(
              rewardTokenAddress,
              poolId.toNumber(),
              account
            )
            .call()
        ])
      }

      if (isMounted) {
        setUserStakedTokenBalance(new BigNumber(balance))
        setUserStakedTokenAllowance(new BigNumber(allowance))

        const pendingWithdrawalsTemp = withdrawals.map(withdrawal => ({
          amount: new BigNumber(withdrawal.amount),
          lockedUntil: new BigNumber(withdrawal.lockedUntil),
          eligible: false
        }))

        // the amount of all the eligible withdrawals,
        // let's just calculated locally to avoid more network requests
        setWithdrawableAmount(
          withdrawals.reduce((target, widthdrawal, i) => {
            if (
              new BigNumber(widthdrawal.lockedUntil)
                .multipliedBy(1000)
                .lt(Date.now())
            ) {
              // we assign the eligible check result for later usage
              // eslint-disable-next-line no-param-reassign
              pendingWithdrawalsTemp[i].eligible = true
              return target.plus(new BigNumber(widthdrawal.amount))
            }
            return target
          }, new BigNumber(0))
        )

        setPendingWithdrawals(pendingWithdrawalsTemp)

        // the amount of all the withdrawals user requested, eligible or not
        const pendWithdrawalTotalTemp = pendingWithdrawalsTemp.reduce(
          (target, widthdrawal) => {
            return target.plus(widthdrawal.amount)
          },
          new BigNumber(0)
        )

        // pending withdrawals should not be accounted into staked amount
        setUserEligibleStakedAmount(
          userStakedAmount.minus(pendWithdrawalTotalTemp)
        )

        // finish loading
        setLoading(false)
      }
    } catch (error) {
      logErrorService(error)
    }
  }, [])

  const handleClaim = async () => {
    setClaimLoading(true)
    try {
      await src1VaultContract.methods
        .deposit(rewardTokenAddress, poolId.toNumber(), 0)
        .send({ from: account })
    } catch (e) {
      console.error('>> claim reward error:  ', e)
    }
    setClaimLoading(false)
    setUpdateInfo(true)
  }

  const handleStake = async () => {
    setStakeLoading(true)
    try {
      if (!userStakedTokenAllowance.gt(0)) {
        await stakedTokenContract.methods
          .approve(src1VaultContract.options.address, constants.maxUint256)
          .send({
            from: account
          })
      } else {
        await src1VaultContract.methods
          .deposit(
            rewardTokenAddress,
            poolId.toNumber(),
            stakeAmount.multipliedBy(1e18).toString(10)
          )
          .send({ from: account })
      }
    } catch (e) {
      console.error('>> stake error:', e)
    }
    setStakeLoading(false)
    setUpdateInfo(true)
  }

  useEffect(() => {
    balanceData()
  }, [fastRefresh, account, userStakedAmount])

  if (loading) {
    return (
      <CardContentWrapper>
        <LoadingSpinner className="loading-spinner" />
      </CardContentWrapper>
    )
  }

  return (
    <CardContentWrapper>
      <Row justify="end" type="flex">
        {/* claim area */}
        <Col lg={{ span: 6 }} xs={{ span: 24 }}>
          <CardItemWrapper>
            <div className="card-item claim-rewards">
              <div>
                <div className="card-title">Available Rewards</div>
                <div className="center-amount">
                  {pendingReward
                    .div(getPoolDecimals(rewardToken))
                    .dp(6, 1)
                    .toString(10)}{' '}
                  {rewardToken.toUpperCase()}
                </div>
              </div>
              <button
                type="button"
                className="button claim-button"
                disabled={!pendingReward.gt(0) || !account || claimLoading}
                onClick={handleClaim}
              >
                {claimLoading && <Icon type="loading" />} Claim
              </button>
            </div>
          </CardItemWrapper>
        </Col>

        {/* withdraw area */}
        <Col lg={{ span: 11 }} xs={{ span: 24 }}>
          <WithdrawCard
            poolId={poolId}
            stakedToken={stakedToken}
            stakedTokenAddress={stakedTokenAddress}
            rewardToken={rewardToken}
            rewardTokenAddress={rewardTokenAddress}
            lockPeriodSecond={lockPeriodSecond}
            withdrawableAmount={withdrawableAmount}
            pendingWithdrawals={pendingWithdrawals}
            userEligibleStakedAmount={userEligibleStakedAmount}
            setUpdateInfo={setUpdateInfo}
          />
        </Col>

        {/* stake area */}
        <Col lg={{ span: 7 }} xs={{ span: 24 }}>
          <CardItemWrapper>
            <div className="card-item stake">
              <div className="withdraw-request">
                <div className="card-title">
                  Available {stakedToken.toUpperCase()} to stake:{' '}
                  {userStakedTokenBalance
                    .div(getPoolDecimals(stakedToken))
                    .toFixed(4)}
                </div>
                <div className="input-wrapper">
                  <NumberFormat
                    autoFocus
                    value={
                      stakeAmount.isZero() ? '0' : stakeAmount.toString(10)
                    }
                    onValueChange={values => {
                      const value = new BigNumber(values.value || 0)
                      const maxValue = userStakedTokenBalance
                        .div(getPoolDecimals(stakedToken))
                        .dp(4, 1)
                      setStakeAmount(value.gt(maxValue) ? maxValue : value)
                    }}
                    thousandSeparator
                    allowNegative={false}
                    placeholder="0"
                  />
                  <span
                    className="pointer max"
                    onClick={() => {
                      setStakeAmount(
                        userStakedTokenBalance.div(getPoolDecimals(stakedToken))
                      )
                    }}
                  >
                    MAX
                  </span>
                </div>
              </div>
              <button
                type="button"
                className="button stake-button"
                disabled={!account || !stakeAmount.gt(0) || stakeLoading}
                onClick={handleStake}
              >
                {stakeLoading && <Icon type="loading" />}{' '}
                {userStakedTokenAllowance.gt(0) ? 'Stake' : 'Enable'}
              </button>
            </div>
          </CardItemWrapper>
        </Col>
      </Row>
      <WithdrawHistoryModal
        visible={historyModalVisible}
        onCancel={() => setHistoryModalVisible(false)}
        pendingWithdrawals={pendingWithdrawals}
        withdrawableAmount={withdrawableAmount}
        stakedToken={stakedToken}
      />
    </CardContentWrapper>
  )
}

CardContent.propTypes = {
  poolId: PropTypes.instanceOf(BigNumber).isRequired,
  stakedToken: PropTypes.string.isRequired,
  rewardToken: PropTypes.string.isRequired,
  userStakedAmount: PropTypes.instanceOf(BigNumber).isRequired,
  pendingReward: PropTypes.instanceOf(BigNumber).isRequired,
  lockPeriodSecond: PropTypes.instanceOf(BigNumber).isRequired,
  setUpdateInfo: PropTypes.func.isRequired
}

export default CardContent
