import { toast as toastify } from 'react-toastify'

const toastOptions = {
  position: 'top-left',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  progress: undefined
}

function toast(toastifyFunction, content) {
  return toastifyFunction(content.title, toastOptions)
}

toast.info = content => toast(toastify.info, content)

toast.error = content => toast(toastify.error, content)

toast.success = content => toast(toastify.success, content)

toast.update = toastify.update

export default toast
