import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Web3 from 'web3'
import { Box, VStack, Button, Text, Tooltip } from '@chakra-ui/react'
import { InfoIcon } from '@chakra-ui/icons'
import {
  methods,
  getVoteContract,
  getTokenContract
} from 'utilities/ContractService'
import ProposalUser from 'components/Vote/VoteOverview/ProposalUser'
import ProposalHistory from 'components/Vote/VoteOverview/ProposalHistory'
import toast from 'components/Basic/Toast'
import { useWeb3React } from '@web3-react/core'
import { SRC1_SYMBOL } from 'utilities/constants'

export const Right = ({ proposalInfo }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isCancelLoading, setIsCancelLoading] = useState(false)
  const [status, setStatus] = useState('pending')
  const [cancelStatus, setCancelStatus] = useState('pending')
  const [isPossibleExcuted, setIsPossibleExcuted] = useState(false)
  const [excuteEta, setExcuteEta] = useState('')
  const [proposalThreshold, setProposalThreshold] = useState(0)
  const [proposerVotingWeight, setProposerVotingWeight] = useState(0)
  const { account } = useWeb3React()

  const handleUpdateProposal = statusType => {
    const appContract = getVoteContract()
    if (statusType === 'Queue') {
      setIsLoading(true)
      methods
        .send(appContract.methods.queue, [proposalInfo.id], account)
        .then(() => {
          setIsLoading(false)
          setStatus('success')
          toast.success({
            title: `Proposal list will be updated within a few seconds`
          })
        })
        .catch(() => {
          setIsLoading(false)
          setStatus('failure')
        })
    } else if (statusType === 'Execute') {
      setIsLoading(true)
      methods
        .send(appContract.methods.execute, [proposalInfo.id], account)
        .then(() => {
          setIsLoading(false)
          setStatus('success')
          toast.success({
            title: `Proposal list will be updated within a few seconds`
          })
        })
        .catch(() => {
          setIsLoading(false)
          setStatus('failure')
        })
    } else if (statusType === 'Cancel') {
      setIsCancelLoading(true)
      methods
        .send(appContract.methods.cancel, [proposalInfo.id], account)
        .then(() => {
          setIsCancelLoading(false)
          setCancelStatus('success')
          toast.success({
            title: `Current proposal is cancelled successfully. Proposal list will be updated within a few seconds`
          })
        })
        .catch(() => {
          setIsCancelLoading(false)
          setCancelStatus('failure')
        })
    }
  }

  useEffect(() => {
    let mounted = true
    const getIsPossibleExcuted = () => {
      const voteContract = getVoteContract()
      methods
        .call(voteContract.methods.proposals, [proposalInfo.id])
        .then(res => {
          if (mounted) {
            setIsPossibleExcuted(res && res.eta <= Date.now() / 1000)
            setExcuteEta(moment(res.eta * 1000).format('LLLL'))
          }
        })
    }
    if (proposalInfo.id) {
      getIsPossibleExcuted()
    }
    return () => {
      mounted = false
    }
  }, [proposalInfo])

  useEffect(() => {
    let mounted = true
    const updateBalance = async () => {
      if (account && proposalInfo.id) {
        const src1TokenContract = getTokenContract(SRC1_SYMBOL)
        const voteContract = getVoteContract()
        await methods
          .call(voteContract.methods.proposalThreshold, [])
          .then(res => {
            if (mounted) setProposalThreshold(+Web3.utils.fromWei(res, 'ether'))
          })
        await methods
          .call(src1TokenContract.methods.getCurrentVotes, [
            proposalInfo.proposer
          ])
          .then(res => {
            if (mounted)
              setProposerVotingWeight(+Web3.utils.fromWei(res, 'ether'))
          })
      }
    }
    if (account) {
      updateBalance()
    }
    return () => {
      mounted = false
    }
  }, [account])

  return (
    <>
      <Box>
        <ProposalUser proposalInfo={proposalInfo} />
      </Box>
      <Box>
        <ProposalHistory proposalInfo={proposalInfo} />
      </Box>
      <Box>
        <VStack spacing={4}>
          {proposalInfo.state !== 'Executed' &&
            proposalInfo.state !== 'Defeated' &&
            proposalInfo.state !== 'Canceled' && (
              <>
                {proposalInfo.state === 'Succeeded' && (
                  <Button
                    w="full"
                    isDisabled={isLoading || status === 'success'}
                    onClick={() => handleUpdateProposal('Queue')}
                    isLoading={isLoading}
                  >
                    {status === 'pending' || status === 'failure'
                      ? 'Queue'
                      : 'Queued'}
                  </Button>
                )}
                {proposalInfo.state === 'Queued' && (
                  <Button
                    w="full"
                    isDisabled={
                      isLoading || status === 'success' || !isPossibleExcuted
                    }
                    onClick={() => handleUpdateProposal('Execute')}
                    isLoading={isLoading}
                  >
                    {status === 'pending' || status === 'failure'
                      ? 'Execute'
                      : 'Executed'}
                  </Button>
                )}
                <Button
                  w="full"
                  isDisabled={
                    isCancelLoading ||
                    proposerVotingWeight >= proposalThreshold ||
                    cancelStatus === 'success'
                  }
                  onClick={() => handleUpdateProposal('Cancel')}
                  isLoading={isCancelLoading}
                >
                  {cancelStatus === 'pending' || cancelStatus === 'failure'
                    ? 'Cancel'
                    : 'Cancelled'}
                </Button>

                {proposalInfo.state === 'Queued' && !isPossibleExcuted && (
                  <Tooltip label={`You are able to excute at ${excuteEta}`}>
                    <InfoIcon />
                  </Tooltip>
                )}
              </>
            )}
          {proposalInfo.state !== 'Executed' &&
            proposalInfo.state !== 'Defeated' &&
            proposalInfo.state !== 'Canceled' &&
            proposerVotingWeight >= proposalThreshold && (
              <Text textAlign="center">
                You can&apos;t cancel the proposal while the proposer voting
                weight meets proposal threshold
              </Text>
            )}
        </VStack>
      </Box>
    </>
  )
}

Right.propTypes = {
  proposalInfo: PropTypes.object
}

Right.defaultProps = {
  proposalInfo: {}
}
