import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { bindActionCreators } from 'redux'
import { connectAccount, accountActionCreators } from 'core'
import SupplyMarket from 'components/Dashboard/Market/SupplyMarket'
import BorrowMarket from 'components/Dashboard/Market/BorrowMarket'
import { Card } from 'components/Basic/Card'
import MintTab from 'components/Basic/UsxTabs/MintTab'
import RepayUsxTab from 'components/Basic/UsxTabs/RepayUsxTab'
import { getBigNumber } from 'utilities/common'
import {
  Tabs,
  TabList,
  TabPanel,
  Tab,
  TabPanels,
  Box,
  Flex
} from '@chakra-ui/react'

const Market = ({ settings, setSetting }) => {
  const [currentTab, setCurrentTab] = useState('supply')
  const [suppliedAssets, setSuppliedAssets] = useState([])
  const [nonSuppliedAssets, setNonSuppliedAssets] = useState([])
  const [borrowedAssets, setBorrowedAssets] = useState([])
  const [nonBorrowedAssets, setNonBorrowedAssets] = useState([])

  useEffect(() => {
    const updateMarketTable = async () => {
      const tempArr = []
      settings.assetList.forEach(item => {
        const temp = {
          ...item,
          supplyApy: getBigNumber(item.supplyApy),
          borrowApy: getBigNumber(item.borrowApy),
          walletBalance: getBigNumber(item.walletBalance),
          supplyBalance: getBigNumber(item.supplyBalance),
          sTokenBalance: getBigNumber(item.sTokenBalance),
          borrowBalance: getBigNumber(item.borrowBalance),
          collateralFactor: getBigNumber(item.collateralFactor),
          tokenPrice: getBigNumber(item.tokenPrice),
          liquidity: getBigNumber(item.liquidity)
        }
        tempArr.push(temp)
      })

      const tempSuppliedData = []
      const tempNonSuppliableData = []
      const tempBorrowedData = []
      const tempNonBorrowedData = []
      tempArr.forEach(element => {
        if (element.supplyBalance.isZero()) {
          tempNonSuppliableData.push(element)
        } else {
          tempSuppliedData.push(element)
        }

        if (element.borrowBalance.isZero()) {
          tempNonBorrowedData.push(element)
        } else {
          tempBorrowedData.push(element)
        }
      })
      setSuppliedAssets([...tempSuppliedData])
      setNonSuppliedAssets([...tempNonSuppliableData])
      setBorrowedAssets([...tempBorrowedData])
      setNonBorrowedAssets([...tempNonBorrowedData])
    }

    if (settings.assetList && settings.assetList.length > 0) {
      updateMarketTable()
    }
  }, [settings.assetList])

  useEffect(() => {
    if (currentTab !== 'usx') {
      setSetting({ marketType: currentTab })
    }
  }, [currentTab, setSetting])

  return (
    <Card flexDir="column">
      <Box w="full">
        <Tabs
          isFitted
          variant="enclosed"
          colorScheme="gray"
          isLazy
          onChange={index => {
            const tabs = ['supply', 'borrow', 'usx']
            setCurrentTab(tabs[index])
          }}
        >
          <TabList mb="1em">
            <Tab>Supply Market</Tab>
            <Tab>Borrow Market</Tab>
            <Tab>Mint / Repay USX</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <SupplyMarket
                suppliedAssets={suppliedAssets}
                remainAssets={nonSuppliedAssets}
              />
            </TabPanel>
            <TabPanel>
              <BorrowMarket
                borrowedAssets={borrowedAssets}
                remainAssets={nonBorrowedAssets}
              />
            </TabPanel>
            <TabPanel>
              <Flex alignContent="center">
                <MintTab />
                <RepayUsxTab />
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Card>
  )
}

Market.propTypes = {
  settings: PropTypes.object,
  setSetting: PropTypes.func.isRequired
}

Market.defaultProps = {
  settings: {}
}

const mapStateToProps = ({ account }) => ({
  settings: account.setting
})

const mapDispatchToProps = dispatch => {
  const { setSetting } = accountActionCreators

  return bindActionCreators(
    {
      setSetting
    },
    dispatch
  )
}

export default compose(connectAccount(mapStateToProps, mapDispatchToProps))(
  Market
)
