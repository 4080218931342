import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connectAccount } from 'core'
import * as constants from 'utilities/constants'
import { Flex, Link, Circle } from '@chakra-ui/react'
import { useWeb3React } from '@web3-react/core'
import { BASE_BSC_SCAN_URL } from '../../config'

const mr = '21px'

function Footer({ settings }) {
  const { account } = useWeb3React()
  if (!account) {
    return null
  }
  return (
    <Flex
      py="26px"
      justifyContent={{ base: 'center', sm: 'flex-end' }}
      alignItems="center"
      flexDir={{ base: 'column', sm: 'row' }}
    >
      <div className="flex align-center">
        <Circle w="14px" h="14px" mr={mr} bg="secondary.500" />
        <Link href={BASE_BSC_SCAN_URL} target="_blank" rel="noreferrer" mr={mr}>
          Latest Block: {settings.latestBlockNumber || 0}
        </Link>
      </div>
      <Link
        href={`${BASE_BSC_SCAN_URL}/address/${constants.CONTRACT_SRC1_TOKEN_ADDRESS}`}
        target="_blank"
        rel="noreferrer"
        mr={mr}
      >
        {constants.SRC1_SYMBOL}
      </Link>
      <Link
        href="https://t.me/sourceprotocol"
        target="_blank"
        rel="noreferrer"
        mr={mr}
      >
        Support
      </Link>
      <Link
        href="https://sourceprotocol.io/Whitepaper.pdf"
        target="_blank"
        rel="noreferrer"
        mr={mr}
      >
        Whitepaper
      </Link>
    </Flex>
  )
}

Footer.propTypes = {
  settings: PropTypes.object
}

Footer.defaultProps = {
  settings: {}
}

const mapStateToProps = ({ account }) => ({
  settings: account.setting
})

export default compose(connectAccount(mapStateToProps, undefined))(Footer)
