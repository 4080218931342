import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import metamaskImg from 'assets/img/metamask.png'
import coinbaseImg from 'assets/img/coinbase.png'
import ledgerImg from 'assets/img/ledger.png'
import binanceImg from 'assets/img/binance.jpg'
import {
  Modal,
  ModalContent,
  ModalOverlay,
  Link,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Flex,
  Image,
  Text,
  Box,
  ModalHeader
} from '@chakra-ui/react'
import Logo from 'assets/img/source_one_logo.svg'
import useAuth from '../../hooks/useAuth'
import { ConnectorNames } from '../../utilities/connectors'
import { ArrowRight } from '../Icons/ArrowRight'
import { network } from '../../config/network'

const CardWrapper = styled.div`
  span {
    font-size: 16px !important;
    font-weight: bold;
  }

  img {
    max-height: 50px;
    max-width: 50px;
    margin: 20px;
  }
`

function ConnectModal({ visible, onCancel }) {
  const { login } = useAuth()

  const handleConnect = useCallback(
    type => {
      onCancel()
      login(type)
    },
    [login, onCancel]
  )

  return (
    <Modal isOpen={visible} onClose={onCancel} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <ModalHeader>
            <Flex flexDir="column" alignItems="center" justifyContent="center">
              <Image src={Logo} w="180px" mb={2} />
              <Text>Connect to start using Source</Text>
            </Flex>
          </ModalHeader>
          <Box>
            <div
              className="flex align-center just-between metamask-connect-btn"
              onClick={() => handleConnect(ConnectorNames.Injected)}
            >
              <CardWrapper className="flex align-center">
                <img src={metamaskImg} alt="metamask" />
                <span>MetaMask</span>
              </CardWrapper>
              <ArrowRight w="18px" h="18px" ml="16px" fill="#fff" />
            </div>
            <div
              className="flex align-center just-between metamask-connect-btn"
              onClick={() => handleConnect(ConnectorNames.BSC)}
            >
              <CardWrapper className="flex align-center">
                <img src={binanceImg} alt="binance" />
                <span>Binance smart chain</span>
              </CardWrapper>
              <ArrowRight w="18px" h="18px" ml="16px" fill="#fff" />
            </div>
            <div
              className={`flex align-center just-between wallet-connect-btn ${
                network.env === 'development' ? 'disabled' : ''
              }`}
            >
              <CardWrapper className="flex align-center just-between">
                <img src={coinbaseImg} alt="coinbase wallet" />
                <span>Wallet Connect</span>
              </CardWrapper>
              <span>Coming Soon</span>
            </div>
            <div className="flex align-center just-between ledger-connect-btn">
              <CardWrapper className="flex align-center">
                <img src={ledgerImg} alt="ledger" />
                <span>Ledger</span>
              </CardWrapper>
              <span>Coming Soon</span>
              {/* <img src={arrowRightImg} alt="arrow" /> */}
            </div>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Text textAlign="center" w="full">
            By connecting, I accept Source&rsquo;s{' '}
            <Link
              href="https://www.swipe.io/terms"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Service
            </Link>
          </Text>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

ConnectModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func
}

ConnectModal.defaultProps = {
  visible: false,
  onCancel: () => {}
}

export default ConnectModal
