import Web3 from 'web3'
import { checkIsValidNetwork, getAvailableMarkets } from 'utilities/common'
import * as constants from './constants'
import { logErrorService } from './errorHandler'
import { getFirstNodeUrl } from '../config/network'

let web3

export const getWeb3 = () => {
  const providerUrl = getFirstNodeUrl()

  if (!web3) {
    web3 = new Web3(
      // eslint-disable-next-line no-nested-ternary
      JSON.parse(localStorage.getItem('state')) &&
      JSON.parse(localStorage.getItem('state')).account.setting.walletType ===
        constants.binanceWalletType
        ? checkIsValidNetwork(constants.binanceWalletType)
          ? window.BinanceChain
          : providerUrl
        : checkIsValidNetwork('metamask')
        ? window.ethereum
        : providerUrl
    )
  }
  return web3
}

const call = (method, params) => {
  return new Promise((resolve, reject) => {
    method(...params)
      .call()
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        logErrorService(err)
        reject(err)
      })
  })
}

const send = (method, params, from) => {
  return new Promise((resolve, reject) => {
    method(...params)
      .send({ from })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        logErrorService(err)
        reject(err)
      })
  })
}

const sendWithOptions = (method, params, options) => {
  return new Promise((resolve, reject) => {
    method(...params)
      .send(options)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        logErrorService(err)
        reject(err)
      })
  })
}

const estimateGas = (method, params, from) => {
  return new Promise((resolve, reject) => {
    method(...params)
      .estimateGas({ from })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        logErrorService(err)
        reject(err)
      })
  })
}

export const getUsxTokenContract = () => {
  const instance = getWeb3()
  return new instance.eth.Contract(
    JSON.parse(constants.CONTRACT_USX_TOKEN_ABI),
    constants.CONTRACT_USX_TOKEN_ADDRESS
  )
}

export const getSrc1TokenContract = () => {
  const instance = getWeb3()
  return new instance.eth.Contract(
    JSON.parse(constants.CONTRACT_SRC1_ABI),
    constants.CONTRACT_SRC1_TOKEN_ADDRESS
  )
}

export const getUsxControllerContract = () => {
  const instance = getWeb3()
  return new instance.eth.Contract(
    JSON.parse(constants.CONTRACT_USX_CONTROLLER_ABI),
    constants.CONTRACT_USX_UNITROLLER_ADDRESS
  )
}

export const getUsxVaultContract = () => {
  const instance = getWeb3()
  return new instance.eth.Contract(
    JSON.parse(constants.CONTRACT_USX_VAULT_ABI),
    constants.CONTRACT_USX_VAULT_ADDRESS
  )
}

export const getSrc1VaultContract = () => {
  const instance = getWeb3()
  return new instance.eth.Contract(
    JSON.parse(constants.CONTRACT_SRC1_VAULT_ABI),
    constants.CONTRACT_SRC1_VAULT_ADDRESS
  )
}

export const getTokenContract = name => {
  const instance = getWeb3()
  const markets = getAvailableMarkets()
  const symbol = name === constants.SRC1_SYMBOL ? name.toLowerCase() : name

  return new instance.eth.Contract(
    JSON.parse(
      symbol === constants.SRC1_SYMBOL.toLowerCase()
        ? constants.CONTRACT_SRC1_ABI
        : constants.CONTRACT_BEP20_TOKEN_ABI
    ),
    markets[symbol || 'usdc']
      ? markets[symbol || 'usdc'].address
      : markets.usdc.address
  )
}

export const getTokenAddressContract = address => {
  const instance = getWeb3()
  return new instance.eth.Contract(
    JSON.parse(constants.CONTRACT_SBEP_ABI),
    address
  )
}

export const getSbepContract = name => {
  const instance = getWeb3()
  return new instance.eth.Contract(
    JSON.parse(
      name !== 'bnb' ? constants.CONTRACT_SBEP_ABI : constants.CONTRACT_SBNB_ABI
    ),
    constants.CONTRACT_SBEP_ADDRESS[name || 'usdc']
      ? constants.CONTRACT_SBEP_ADDRESS[name || 'usdc'].address
      : constants.CONTRACT_SBEP_ADDRESS.usdc.address
  )
}

export const getComptrollerContract = () => {
  const instance = getWeb3()
  return new instance.eth.Contract(
    JSON.parse(constants.CONTRACT_COMPTROLLER_ABI),
    constants.CONTRACT_COMPTROLLER_ADDRESS
  )
}

export const getPriceOracleContract = (
  address = constants.CONTRACT_PRICE_ORACLE_ADDRESS
) => {
  const instance = getWeb3()
  return new instance.eth.Contract(
    JSON.parse(constants.CONTRACT_PRICE_ORACLE_ABI),
    address
  )
}

export const getVoteContract = () => {
  const instance = getWeb3()
  return new instance.eth.Contract(
    JSON.parse(constants.CONTRACT_VOTE_ABI),
    constants.CONTRACT_VOTE_ADDRESS
  )
}

export const getInterestModelContract = address => {
  const instance = getWeb3()
  return new instance.eth.Contract(
    JSON.parse(constants.CONTRACT_INTEREST_MODEL_ABI),
    address
  )
}

export const getLatestBlockNumber = async () => {
  return new Promise((resolve, reject) => {
    const instance = getWeb3()
    instance.eth.getBlockNumber((err, blockNumber) => {
      if (err !== null) {
        reject(err)
      } else {
        resolve(blockNumber)
      }
    })
  })
}

export const methods = {
  call,
  send,
  estimateGas,
  sendWithOptions
}
