import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import BigNumber from 'bignumber.js'
import { bindActionCreators } from 'redux'
import { connectAccount, accountActionCreators } from 'core'
import OverviewChart from 'components/Basic/OverviewChart'
import { promisify } from 'utilities'
import * as constants from 'utilities/constants'
import commaNumber from 'comma-number'
import { addToken, getBigNumber } from 'utilities/common'
import { Card } from 'components/Basic/Card'
import { ArrowRight } from 'components/Icons/ArrowRight'
import {
  Image,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  IconButton,
  VStack,
  StackDivider,
  HStack,
  Box
} from '@chakra-ui/react'
import { PlusCircle } from 'components/Icons/PlusCircle'
import { MarketsContext } from 'context/MarketsContext'
import { logErrorService } from '../../utilities/errorHandler'

const format = commaNumber.bindWith(',', '.')

const PlusButton = ({ onClick }) => (
  <IconButton
    variant="outline"
    border="none"
    mb="5px"
    icon={<PlusCircle w="15px" h="15px" mr="16px" fill="secondary.500" />}
    onClick={onClick}
    _hover={{ bg: 'none' }}
  />
)

PlusButton.propTypes = {
  onClick: PropTypes.func.isRequired
}

const OverViewRow = ({ label, value }) => (
  <Flex width="full" alignItems="center" justifyContent="space-between">
    <Text>{label}</Text>
    <Text fontWeight="bold">{!value ? 0 : value}</Text>
  </Flex>
)

OverViewRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string
}

function Overview({ settings, getMarketHistory }) {
  const [currentAsset, setCurrentAsset] = useState(null)
  const [data, setData] = useState([])
  const [marketInfo, setMarketInfo] = useState({})
  const [currentAPY, setCurrentAPY] = useState(0)
  const { availableMarkets, availableSbeps } = React.useContext(MarketsContext)
  const [asset, setAsset] = useState(availableSbeps.bnb.address)

  useEffect(() => {
    let mounted = true
    const getGovernanceData = async () => {
      const info = settings.markets.find(
        item => item.underlyingSymbol.toLowerCase() === currentAsset
      )
      setMarketInfo(info || {})
    }
    try {
      if (
        currentAsset &&
        settings.markets &&
        settings.markets.length > 0 &&
        mounted
      ) {
        getGovernanceData()
      }
    } catch (error) {
      logErrorService(error)
    }
    return () => {
      mounted = false
    }
  }, [settings.markets, currentAsset])

  useEffect(() => {
    let mounted = true
    const getGraphData = async () => {
      let tempData = []
      const type = constants.graphTimeDataType
      const limit = constants.lastTimeDataPeriods
      const res = await promisify(getMarketHistory, { asset, type, limit })
      tempData = res.data.result
        .map(m => {
          return {
            createdAt: m.createdAt,
            supplyApy: +new BigNumber(m.supplyApy || 0).dp(8, 1).toString(10),
            borrowApy: +new BigNumber(m.borrowApy || 0).dp(8, 1).toString(10)
          }
        })
        .reverse()
      if (mounted) {
        setData([...tempData])
      }
    }
    try {
      if (currentAsset) {
        getGraphData()
      }
    } catch (error) {
      logErrorService(error)
    }
    return () => {
      mounted = false
    }
  }, [currentAsset, asset])

  useEffect(() => {
    let mounted = true
    try {
      if (mounted) setCurrentAsset('bnb')
    } catch (error) {
      logErrorService(error)
    }
    return () => {
      mounted = false
    }
  }, [])

  useEffect(() => {
    let mounted = true
    try {
      if (mounted && settings.assetList && settings.assetList.length > 0) {
        const currentMarketInfo =
          settings.assetList.filter(s => s && s.id === currentAsset).length !==
          0
            ? settings.assetList.filter(s => s.id === currentAsset)[0]
            : {}
        const supplyApy = getBigNumber(currentMarketInfo.supplyApy)
        const borrowApy = getBigNumber(currentMarketInfo.borrowApy)
        const supplyApyWithGovernanceToken = settings.withGovernanceToken
          ? supplyApy.plus(currentMarketInfo.governanceTokenSupplyApy)
          : supplyApy
        const borrowApyWithGovernanceToken = settings.withGovernanceToken
          ? getBigNumber(currentMarketInfo.governanceTokenBorrowApy).minus(
              borrowApy
            )
          : borrowApy
        setCurrentAPY(
          (settings.marketType || 'supply') === 'supply'
            ? supplyApyWithGovernanceToken.dp(2, 1).toString(10)
            : borrowApyWithGovernanceToken.dp(2, 1).toString(10)
        )
      }
    } catch (error) {
      logErrorService(error)
    }
    return () => {
      mounted = false
    }
  }, [
    currentAsset,
    settings.marketType,
    settings.assetList,
    settings.withGovernanceToken
  ])

  const handleChangeAsset = value => {
    setCurrentAsset(value)
    setAsset(availableSbeps[value].address)
  }

  const price = useMemo(() => {
    if (settings?.decimals?.[currentAsset]?.token) {
      return `$${new BigNumber(marketInfo.underlyingPrice || 0)
        .div(
          new BigNumber(10).pow(
            18 + 18 - parseInt(settings.decimals[currentAsset].token, 10)
          )
        )
        .dp(8, 1)
        .toString(10)}`
    }
    return '$0'
  }, [marketInfo.underlyingPrice, settings.decimals, currentAsset])

  if (!settings.decimals[currentAsset]) {
    return null
  }

  const supplyMarket = (settings.marketType || 'supply') === 'supply'

  return (
    <Card>
      <Box w="full">
        <Flex alignItems="center" justifyContent="space-between">
          <Flex alignItems="center" justifyContent="space-between" w="full">
            <Flex
              alignItems="center"
              justifyContent="flex-end"
              id="asset"
              mb={4}
            >
              <Menu>
                <MenuButton bg="black.400" borderRadius="lg" py={3} px={4}>
                  <Flex alignItems="center">
                    <Image
                      w="28px"
                      h="28px"
                      mr="12px"
                      src={availableMarkets[currentAsset].asset}
                      alt={`${availableMarkets[currentAsset].symbol} asset`}
                    />
                    {availableMarkets[currentAsset].symbol}
                    <ArrowRight
                      fill="white"
                      transform="auto"
                      rotate="90deg"
                      ml="12px"
                    />
                  </Flex>
                </MenuButton>
                <MenuList>
                  {Object.keys(availableSbeps)
                    .filter(key => key !== currentAsset)
                    .map((key, index) => (
                      <Flex
                        as={MenuItem}
                        alignContent="center"
                        justifyContent="space-between"
                        key={index}
                        onClick={() =>
                          handleChangeAsset(availableMarkets[key].id)
                        }
                      >
                        <Image
                          w="28px"
                          h="28px"
                          mr="12px"
                          src={availableMarkets[key]?.asset}
                          alt={`${availableMarkets[currentAsset].symbol} asset`}
                        />{' '}
                        <span>{availableMarkets[key]?.symbol}</span>
                      </Flex>
                    ))}
                </MenuList>
              </Menu>
            </Flex>
            {window.ethereum &&
              window.ethereum.networkVersion &&
              settings.walletType === 'metamask' && (
                <Flex alignItems="center">
                  <Text mr="15px">Add To MetaMask:</Text>
                  {currentAsset !== 'bnb' && (
                    <Flex alignItems="center">
                      {currentAsset.toUpperCase()}
                      <PlusButton
                        onClick={() =>
                          addToken(
                            currentAsset,
                            settings.decimals[currentAsset].token,
                            'token'
                          )
                        }
                      />
                    </Flex>
                  )}
                  <Flex alignItems="center">
                    {`s${
                      currentAsset === 'btcb'
                        ? 'BTC'
                        : currentAsset.toUpperCase()
                    }`}
                    <PlusButton
                      onClick={() =>
                        addToken(
                          currentAsset,
                          settings.decimals[currentAsset].stoken,
                          'stoken'
                        )
                      }
                    />
                  </Flex>
                </Flex>
              )}
          </Flex>
          {/* <p className="value">{`$${
              supplyMarket
                ? new BigNumber(marketInfo.totalSupply || 0).div(new BigNumber(10).pow(settings.decimals[currentAsset].stoken)).dp(2, 1).toString(10)
                : new BigNumber(marketInfo.totalBorrows || 0).div(new BigNumber(10).pow(settings.decimals[currentAsset].token)).dp(2, 1).toString(10)
            }`}
          </p> */}
        </Flex>
        <Flex alignItems="center" flexDir="column">
          <Text textTransform="uppercase">PRICE</Text>
          <Text fontSize="3xl">{price}</Text>
          <Flex>
            <Text mr={2}>{supplyMarket ? 'Supply APY' : 'Borrow APY'}</Text>
            <Text
              color={
                supplyMarket || currentAPY >= 0 ? 'secondary.500' : 'red.500'
              }
            >
              {currentAPY}%
            </Text>
          </Flex>
        </Flex>
        <OverviewChart
          marketType={settings.marketType || 'supply'}
          data={data}
        />
        <HStack spacing={6}>
          <VStack
            divider={<StackDivider borderColor="white" opacity="0.5" />}
            spacing={4}
            flexGrow={1}
          >
            <OverViewRow label="Price" value={price} />
            <OverViewRow
              label="Market Liquidity"
              value={`${format(
                new BigNumber(marketInfo.cash || 0)
                  .div(
                    new BigNumber(10).pow(
                      // Todo refact and get the mantissa cross dapp without magic numbers
                      18 - Number(settings.decimals[currentAsset].token) + 18
                    )
                  )
                  .dp(8, 1)
                  .toString(10)
              )} ${marketInfo.underlyingSymbol || ''}`}
            />
            <OverViewRow
              label="Reserves"
              value={`${new BigNumber(marketInfo.totalReserves || 0)
                .div(
                  new BigNumber(10).pow(settings.decimals[currentAsset].token)
                )
                .dp(8, 1)
                .toString(10)} ${marketInfo.underlyingSymbol || ''}`}
            />
            <OverViewRow
              label="Reserve Factor"
              value={`${new BigNumber(marketInfo.reserveFactor || 0)
                .div(new BigNumber(10).pow(18))
                .multipliedBy(100)
                .dp(8, 1)
                .toString(10)}%`}
            />
            <OverViewRow
              label="Collateral Factor"
              value={`${new BigNumber(marketInfo.collateralFactor || 0)
                .div(new BigNumber(10).pow(18))
                .times(100)
                .dp(2, 1)
                .toString(10)}%`}
            />
          </VStack>
          <VStack
            divider={<StackDivider borderColor="white" opacity="0.5" />}
            spacing={4}
            flexGrow={1}
          >
            <OverViewRow
              label="# of Suppliers"
              value={format(marketInfo.supplierCount) || ''}
            />
            <OverViewRow
              label="# of Borrowers"
              value={format(marketInfo.borrowerCount)}
            />
            <OverViewRow
              label="Total Supply"
              value={`$${format(
                new BigNumber(marketInfo.totalSupplyUsd || 0)
                  .dp(2, 1)
                  .toString(10)
              )}`}
            />
            <OverViewRow
              label="Total Borrow"
              value={`$${format(
                new BigNumber(marketInfo.totalBorrowsUsd || 0)
                  .dp(2, 1)
                  .toString(10)
              )}`}
            />
            <OverViewRow
              label="Exchange Rate"
              value={`1 ${marketInfo.underlyingSymbol || ''} = ${Number(
                new BigNumber(1)
                  .div(
                    new BigNumber(marketInfo.exchangeRate).div(
                      new BigNumber(10).pow(
                        18 +
                          +parseInt(
                            settings.decimals[currentAsset || 'prsx'].token,
                            10
                          ) -
                          +parseInt(
                            settings.decimals[currentAsset || 'prsx'].stoken,
                            10
                          )
                      )
                    )
                  )
                  .toString(10)
              ).toFixed(6)} ${marketInfo.symbol || ''}`}
            />
          </VStack>
        </HStack>
      </Box>
    </Card>
  )
}

Overview.propTypes = {
  settings: PropTypes.object,
  getMarketHistory: PropTypes.func.isRequired
}

Overview.defaultProps = {
  settings: {}
}

const mapStateToProps = ({ account }) => ({
  settings: account.setting
})

const mapDispatchToProps = dispatch => {
  const { getMarketHistory } = accountActionCreators

  return bindActionCreators(
    {
      getMarketHistory
    },
    dispatch
  )
}

export default compose(connectAccount(mapStateToProps, mapDispatchToProps))(
  Overview
)
