import React, { useState } from 'react'
import { Row, Col } from 'antd'
import BigNumber from 'bignumber.js'
import PropTypes from 'prop-types'
import * as constants from 'utilities/constants'
import { format } from 'utilities/common'
import { Tooltip } from '@chakra-ui/react'
import VaultCardContent from './CardContent'
import { VaultCardWrapper } from './styles'

import usxImg from '../../assets/img/coins/usx.png'
import src1Img from '../../assets/img/coins/src1.png'
import arrowDownImg from '../../assets/img/arrow-down.png'

function getTokenImg(name) {
  return {
    src1: src1Img,
    usx: usxImg
  }[name]
}

const VaultCard = ({
  poolId,
  stakedToken,
  rewardToken,
  userStakedAmount,
  pendingReward,
  lockPeriodSecond,
  apr,
  totalStaked,
  dailyEmission,
  setUpdateInfo
}) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <VaultCardWrapper>
      <div className={`header-container ${expanded ? '' : 'fold'}`}>
        <Row className="header">
          <Col
            className="col-item"
            lg={{ span: 3 }}
            md={{ span: 6 }}
            xs={{ span: 12 }}
          >
            <div className="title">Stake</div>
            <div className="content">
              <Tooltip label={stakedToken.toUpperCase()} aria-label="A tooltip">
                <img src={getTokenImg(stakedToken)} alt="stakedToken" />
              </Tooltip>
            </div>
          </Col>
          <Col
            className="col-item"
            lg={{ span: 3 }}
            md={{ span: 6 }}
            xs={{ span: 12 }}
          >
            <div className="title">Earn</div>
            <div className="content">
              <Tooltip label={rewardToken.toUpperCase()} aria-label="A tooltip">
                <img src={getTokenImg(rewardToken)} alt="rewardToken" />
              </Tooltip>
            </div>
          </Col>
          <Col
            className="col-item"
            lg={{ span: 4 }}
            md={{ span: 6 }}
            xs={{ span: 12 }}
          >
            <div className="title">Available Rewards</div>
            <div className="content">
              {format(
                pendingReward
                  .div(constants.mantissa)
                  .decimalPlaces(constants.vaultDisplayTokenDecimals)
                  .toString()
              )}
            </div>
          </Col>
          <Col
            className="col-item"
            lg={{ span: 4 }}
            md={{ span: 6 }}
            xs={{ span: 12 }}
          >
            <div className="title">{stakedToken.toUpperCase()} Staking APR</div>
            <div className="content">
              {format(
                apr
                  .multipliedBy(100)
                  .decimalPlaces(constants.vaultDisplayTokenDecimals)
                  .toString()
              )}
              %
            </div>
          </Col>
          <Col
            className="col-item"
            lg={{ span: 4 }}
            md={{ span: 6 }}
            xs={{ span: 12 }}
          >
            <div className="title">
              Total {stakedToken.toUpperCase()} Staked
            </div>
            <div className="content">
              {format(
                totalStaked
                  .div(constants.mantissa)
                  .decimalPlaces(constants.vaultDisplayTokenDecimals)
                  .toString()
              )}
            </div>
          </Col>
          <Col
            className="col-item"
            lg={{ span: 4 }}
            md={{ span: 6 }}
            xs={{ span: 12 }}
          >
            <div className="title">
              {rewardToken.toUpperCase()} Daily Emission
            </div>
            <div className="content">
              {format(
                dailyEmission
                  .div(constants.mantissa)
                  .decimalPlaces(constants.vaultDisplayTokenDecimals)
                  .toString()
              )}{' '}
              {rewardToken.toUpperCase()}
            </div>
          </Col>
          <Col
            className="col-item expand-icon-wrapper"
            lg={{ span: 2 }}
            xs={{ span: 24 }}
            onClick={() => setExpanded(!expanded)}
          >
            <img className="expand-icon" alt="open" src={arrowDownImg} />
          </Col>
        </Row>
      </div>
      <div>
        {expanded ? (
          <VaultCardContent
            className="content-container"
            poolId={poolId}
            stakedToken={stakedToken}
            rewardToken={rewardToken}
            userStakedAmount={userStakedAmount}
            pendingReward={pendingReward}
            lockPeriodSecond={lockPeriodSecond}
            setUpdateInfo={setUpdateInfo}
          />
        ) : null}
      </div>
    </VaultCardWrapper>
  )
}

VaultCard.propTypes = {
  poolId: PropTypes.instanceOf(BigNumber).isRequired,
  stakedToken: PropTypes.string.isRequired,
  rewardToken: PropTypes.string.isRequired,
  userStakedAmount: PropTypes.instanceOf(BigNumber).isRequired,
  pendingReward: PropTypes.instanceOf(BigNumber).isRequired,
  lockPeriodSecond: PropTypes.instanceOf(BigNumber).isRequired,
  apr: PropTypes.instanceOf(BigNumber).isRequired,
  totalStaked: PropTypes.instanceOf(BigNumber).isRequired,
  dailyEmission: PropTypes.instanceOf(BigNumber).isRequired,
  setUpdateInfo: PropTypes.func.isRequired
}

export default VaultCard
