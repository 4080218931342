import React from 'react'
import PropTypes from 'prop-types'
import { Text, Box, Flex, Progress } from '@chakra-ui/react'

function LineProgressBar({ label, percent, color, progressColor }) {
  return (
    <Box>
      <Flex alignItems="center" justifyContent="space-between" mb={2}>
        {label && (
          <Text fontSize="md" fontWeight="bold" color={color}>
            {label}
          </Text>
        )}
        {label && percent && (
          <Text fontSize="md" fontWeight="bold" color={color}>
            {percent}%
          </Text>
        )}
      </Flex>
      <Progress value={percent} size="sm" colorScheme={progressColor} />
    </Box>
  )
}

LineProgressBar.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  progressColor: PropTypes.string,
  percent: PropTypes.number
}

LineProgressBar.defaultProps = {
  label: null,
  percent: 0.0,
  color: undefined,
  progressColor: undefined
}

export default LineProgressBar
