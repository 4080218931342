import React from 'react'
import { createIcon } from '@chakra-ui/react'

export const Usx = createIcon({
  displayName: 'Usx',
  path: (
    <path
      d="M119.25 60.53a70.18 70.18 0 0 1-1.65 14.4 61.23 61.23 0 0 1-9.77 22.64 48.75 48.75 0 0 1-14.76 13.6c-8.4 5-19.35 
    8.18-33.27 8.17h-.18c-11.79 0-21.44-2.27-29.23-6a49.17 49.17 0 0 1-18-14.55A55.23 55.23 0 0 1 5.61 87.2 70.24 70.24 0 0 1 0 
    60.53V4.62a4.5 4.5 0 0 1 9 0V60.5A60.56 60.56 0 0 0 10.45 73a52.33 52.33 0 0 0 8.3 19.3 40.43 40.43 0 0 0 12.11 11.1 46.37 46.37 
    0 0 0 12.24 5 67.15 67.15 0 0 0 16.53 1.94h.17c10.63 0 18.83-2 25.26-5.07a40 40 0 0 0 14.69-11.91 45.73 45.73 0 0 0 5.65-9.71 58.55 
    58.55 0 0 0 3.8-12.59 60 60 0 0 0 1-10.53V4.62a4.5 4.5 0 0 1 9 0v55.11c.04.17.05.44.05.8ZM104 61.73V4.5a4.5 4.5 0 0 0-9 0V62.27a35.79 
    35.79 0 0 1-.73 6.79 36.2 36.2 0 0 1-2.62 8.14 29.3 29.3 0 0 1-3.89 6.24 27.34 27.34 0 0 1-10.16 7.68c-4.48 2-10.27 3.33-17.84 
    3.33h-.15c-9 0-15.5-1.89-20.3-4.56A27 27 0 0 1 31 82.72a32.59 32.59 0 0 1-5.73-12.43 36.55 36.55 0 0 1-1-8V4.5a4.5 4.5 0 0 0-9 0V62.27a44.25 
    44.25 0 0 0 .91 8.53A44.88 44.88 0 0 0 19.44 81a38 38 0 0 0 5.11 8.14A36.61 36.61 0 0 0 38 99.3c5.8 2.59 12.93 4.13 21.58 4.15h.16c10.21 0 
    18.32-2.15 24.59-5.66a35.93 35.93 0 0 0 11.08-9.53 41.5 41.5 0 0 0 7.33-15.89 45.24 45.24 0 0 0 1.26-10.1v-.54Zm-79.78.18Zm64.68-2.54V20a4.5 
    4.5 0 0 0-9 0v39.6a.77.77 0 0 1 0 .18 24.14 24.14 0 0 1-.43 4.17 23.26 23.26 0 0 1-1.53 5 17.6 17.6 0 0 1-2.22 3.76 15.35 15.35 0 0 1-5.68 4.52 
    23.86 23.86 0 0 1-10.28 2h-.14c-5.32 0-9-1.17-11.7-2.74a15.34 15.34 0 0 1-4.69-4.23 20.26 20.26 0 0 1-3.31-7.55 24.18 24.18 0 0 1-.59-4.92V20a4.5 
    4.5 0 1 0-9 0v39.73a33 33 0 0 0 .59 5.83 32.31 32.31 0 0 0 2.15 7 27.14 27.14 0 0 0 3.37 5.63 24.67 24.67 0 0 0 9 7.14 33.17 33.17 0 0 0 14.17 
    2.89h.16a31.29 31.29 0 0 0 16.16-3.94 24.44 24.44 0 0 0 7.37-6.67 29.4 29.4 0 0 0 4.81-10.93 33.36 33.36 0 0 0 .8-6.9c0-.2 0-.32-.01-.41ZM40.07 
    5.28A4.78 4.78 0 1 1 35.29.51a4.78 4.78 0 0 1 4.78 4.77Zm48.78 0A4.78 4.78 0 1 1 84.07.51a4.78 4.78 0 0 1 4.78 4.77Z"
    />
  ),
  viewBox: '0 0 120 120'
})
