import React from 'react'
import PropTypes from 'prop-types'
import { ChakraProvider } from '@chakra-ui/react'
import { theme } from 'theme/theme'

const Theme = ({ children }) => (
  <ChakraProvider theme={theme}>{children}</ChakraProvider>
)

Theme.propTypes = {
  children: PropTypes.node.isRequired
}

export default Theme
