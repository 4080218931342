import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connectAccount } from 'core'
import * as constants from 'utilities/constants'
import { addToken, getBigNumber } from 'utilities/common'
import { InfoCard, format } from 'components/Basic/InfoCard'
import { Text } from '@chakra-ui/react'
import { useWeb3React } from '@web3-react/core'
import { BASE_BSC_SCAN_URL } from '../../config'

function UsxInfo({ settings }) {
  const { account } = useWeb3React()
  const handleLink = () => {
    window.open(
      `${BASE_BSC_SCAN_URL}/token/${constants.CONTRACT_USX_TOKEN_ADDRESS}?a=${account}`,
      '_blank'
    )
  }

  return (
    <InfoCard
      color="secondary.500"
      address={account}
      addressHandler={handleLink}
      label={`${format(
        getBigNumber(settings.userUsxBalance)
          .dp(2, 1)
          .toString(10)
      )} USX`}
      addTokenLogic={!!window.ethereum || !!window.BinanceChain}
      addTokenHandler={() => addToken('usx', 18, 'token')}
      apr={
        settings.usxAPY && (
          <Text ml="10px" color="secondary.500">
            APY: {settings.usxAPY}%
          </Text>
        )
      }
      icon="usx"
    />
  )
}

UsxInfo.propTypes = {
  settings: PropTypes.object
}

UsxInfo.defaultProps = {
  settings: {}
}

const mapStateToProps = ({ account }) => ({
  settings: account.setting
})

export default compose(connectAccount(mapStateToProps, undefined))(UsxInfo)
