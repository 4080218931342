/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import BigNumber from 'bignumber.js'
import * as constants from 'utilities/constants'
import MainLayout from 'containers/Layout/MainLayout'
import { accountActionCreators, connectAccount } from 'core'
import {
  getSrc1VaultContract,
  getTokenAddressContract,
  methods
} from 'utilities/ContractService'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import { Center, Spinner } from '@chakra-ui/react'
import { MarketsContext } from 'context/MarketsContext'
import GeneralVaultPoolCard from '../../components/Vault/Card'
import { logErrorService } from '../../utilities/errorHandler'
import useRefresh from '../../hooks/useRefresh'

const VaultWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 24px;
`

// fast search token name by address
const tokenAddressNameMap = Object.keys(constants.CONTRACT_POOL_ADDRESS).reduce(
  (target, token) => {
    return {
      ...target,
      [constants.CONTRACT_POOL_ADDRESS[token].token]: token
    }
  },
  {}
)

function Vault() {
  const [poolInfos, setPoolInfos] = useState([])
  const [updateInfo, setUpdateInfo] = useState(false)
  const [loading, setLoading] = useState(true)
  const { account } = useWeb3React()

  const { fastRefresh } = useRefresh()

  const { availableMarkets } = React.useContext(MarketsContext)

  async function fetchOnePool(param) {
    const vaultContract = getSrc1VaultContract()
    const [poolInfo, rewardPerBlock, totalAllocPoints] = await Promise.all([
      methods.call(vaultContract.methods.poolInfos, [
        param.rewardToken,
        param.pid
      ]),
      methods.call(vaultContract.methods.rewardTokenAmountsPerBlock, [
        param.rewardToken
      ]),
      methods.call(vaultContract.methods.totalAllocPoints, [param.rewardToken])
    ])
    const token = getTokenAddressContract(poolInfo.token)
    const totalStaked = await methods.call(token.methods.balanceOf, [
      vaultContract.options.address
    ])

    let [userPendingRewards, userInfo] = [
      '0',
      {
        amount: '0',
        pendingWithdrawals: [],
        rewardDebt: '0'
      }
    ]

    if (account) {
      ;[userPendingRewards, userInfo] = await Promise.all([
        methods.call(vaultContract.methods.pendingReward, [
          param.rewardToken,
          param.pid,
          account
        ]),
        methods.call(vaultContract.methods.getUserInfo, [
          param.rewardToken,
          param.pid,
          account
        ])
      ])
    }

    const rewardPerBlockOfPool = new BigNumber(rewardPerBlock)
      .multipliedBy(poolInfo.allocPoint)
      .div(totalAllocPoints)
    const dailyEmission = new BigNumber(rewardPerBlockOfPool).multipliedBy(
      constants.blocksPerDay
    )

    return {
      poolId: new BigNumber(param.pid),
      stakedToken: tokenAddressNameMap[poolInfo.token.toLowerCase()],
      rewardToken: tokenAddressNameMap[param.rewardToken.toLowerCase()],
      pendingReward: new BigNumber(userPendingRewards),
      userStakedAmount: new BigNumber(userInfo.amount),
      lockPeriodSecond: new BigNumber(poolInfo.lockPeriod),
      apr: new BigNumber(dailyEmission).multipliedBy(365).div(totalStaked),
      totalStaked: new BigNumber(totalStaked),
      dailyEmission
    }
  }

  useEffect(() => {
    let mounted = true
    const getPools = async () => {
      try {
        const src1TokenAddress = availableMarkets.src1.address
        const vaultContract = getSrc1VaultContract()

        const src1TokenPoolLength = await methods.call(
          vaultContract.methods.poolLength,
          [src1TokenAddress]
        )

        const fetchPoolParameters = Array.from({
          length: src1TokenPoolLength
        }).map((_, index) => {
          return { rewardToken: src1TokenAddress, pid: index }
        })

        await Promise.all(
          fetchPoolParameters.map(param => fetchOnePool(param))
        ).then(data => {
          if (mounted) {
            setPoolInfos(data)
            setLoading(false)
            setUpdateInfo(false)
          }
        })
      } catch (error) {
        logErrorService(error)
      }
    }
    try {
      getPools()
    } catch (error) {
      logErrorService(error)
    }
    return () => {
      mounted = false
    }
  }, [fastRefresh, account, updateInfo])

  return (
    <MainLayout isHeader={false}>
      {loading ? (
        <>
          <Center>
            <Spinner color="primary.500" size="xl" speed="1s" />
          </Center>
        </>
      ) : (
        <VaultWrapper>
          {poolInfos.map((poolInfo, index) => {
            return (
              <GeneralVaultPoolCard
                key={index}
                poolId={poolInfo.poolId}
                stakedToken={poolInfo.stakedToken}
                rewardToken={poolInfo.rewardToken}
                userStakedAmount={poolInfo.userStakedAmount}
                pendingReward={poolInfo.pendingReward}
                lockPeriodSecond={poolInfo.lockPeriodSecond}
                apr={poolInfo.apr}
                totalStaked={poolInfo.totalStaked}
                dailyEmission={poolInfo.dailyEmission}
                setUpdateInfo={setUpdateInfo}
              />
            )
          })}
        </VaultWrapper>
      )}
    </MainLayout>
  )
}

Vault.propTypes = {}

Vault.defaultProps = {}

const mapStateToProps = ({ account }) => ({
  settings: account.setting
})

const mapDispatchToProps = dispatch => {
  const { setSetting } = accountActionCreators

  return bindActionCreators(
    {
      setSetting
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connectAccount(mapStateToProps, mapDispatchToProps)
)(Vault)
