import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connectAccount } from 'core'
import BigNumber from 'bignumber.js'
import { getTokenContract, methods } from 'utilities/ContractService'
import * as constants from 'utilities/constants'
import { addToken } from 'utilities/common'
import { InfoCard, format } from 'components/Basic/InfoCard'
import { useWeb3React } from '@web3-react/core'
import { MarketsContext } from 'context/MarketsContext'
import { BASE_BSC_SCAN_URL } from '../../config'
import { logErrorService } from '../../utilities/errorHandler'

function CoinInfo({ settings }) {
  const [address, setAddress] = useState('')
  const [balance, setBalance] = useState('')
  const { account } = useWeb3React()
  const { availableMarkets } = React.useContext(MarketsContext)

  const handleLink = () => {
    window.open(
      `${BASE_BSC_SCAN_URL}/token/${availableMarkets.src1.address}?a=${address}`,
      '_blank'
    )
  }

  useEffect(() => {
    let mounted = true
    const updateBalance = async () => {
      const src1TokenContract = getTokenContract(constants.SRC1_SYMBOL)
      let temp = await methods.call(src1TokenContract.methods.balanceOf, [
        account
      ])
      temp = new BigNumber(temp)
        .dividedBy(new BigNumber(10).pow(18))
        .dp(4, 1)
        .toString(10)
      if (mounted) {
        setBalance(temp)
        setAddress(account)
      }
    }
    try {
      if (account) {
        updateBalance()
      }
    } catch (error) {
      logErrorService(error)
    }
    return () => {
      mounted = false
    }
  }, [account])

  return (
    <InfoCard
      address={address}
      addressHandler={handleLink}
      label={`${format(balance)} ${constants.SRC1_SYMBOL}`}
      addTokenLogic={!!window.ethereum && settings.walletType === 'metamask'}
      addTokenHandler={() => addToken(constants.SRC1_SYMBOL, 18, 'token')}
      icon={constants.SRC1_SYMBOL}
    />
  )
}

CoinInfo.propTypes = {
  settings: PropTypes.object
}

CoinInfo.defaultProps = {
  settings: {}
}

const mapStateToProps = ({ account }) => ({
  settings: account.setting
})

export default compose(connectAccount(mapStateToProps, undefined))(CoinInfo)
