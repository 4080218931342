import { mode } from '@chakra-ui/theme-tools'

export const ModalStyle = {
  parts: ['dialog'],
  // Styles for the base style
  baseStyle: props => ({
    dialog: {
      bg: mode('white', 'black.500')(props),
      borderRadius: 'xl'
    }
  }),
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {},
  // The default `size` or `variant` values
  defaultProps: {}
}
