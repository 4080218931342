import React from 'react'
import PropTypes from 'prop-types'
import { Grid as CGrid, useMediaQuery } from '@chakra-ui/react'

export const useGrid = () => {
  const [isLargerThan1100] = useMediaQuery('(min-width: 1100px)')
  return {
    isLargerThan1100,
    gap: isLargerThan1100 ? 6 : 0,
    padding: isLargerThan1100 ? 3 : 0
  }
}

export const Grid = ({ desktop, mobile, templateColumns }) => {
  const { gap, padding, isLargerThan1100 } = useGrid()
  const child = () => {
    if (isLargerThan1100 && !desktop) {
      return mobile
    }
    if (isLargerThan1100) {
      return desktop
    }

    return mobile
  }
  return (
    <CGrid templateColumns={templateColumns} gap={gap} p={padding}>
      {child()}
    </CGrid>
  )
}

Grid.propTypes = {
  desktop: PropTypes.node,
  templateColumns: PropTypes.string,
  mobile: PropTypes.node.isRequired
}

Grid.defaultProps = {
  desktop: null,
  templateColumns: 'repeat(3, 1fr)'
}
