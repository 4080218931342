import React from 'react'
import { createIcon } from '@chakra-ui/react'

export const Currency = createIcon({
  displayName: 'Currency',
  path: (
    <path
      d="M13.7,0.22c-7.35,0-13.3,5.95-13.3,13.3s5.95,13.3,13.3,13.3S27,20.87,27,13.52S21.04,0.22,13.7,0.22z M20.49,15.9h-3.92
    l-2.31,6.1c-0.13,0.34-0.46,0.55-0.81,0.53c-0.35,0.02-0.68-0.19-0.81-0.53l-2.31-6.1H6.91c-0.45,0-0.82-0.37-0.82-0.82
    c0-0.45,0.37-0.82,0.82-0.82H9.7h1.76h3.97h1.76h3.3c0.45,0,0.82,0.37,0.82,0.82C21.31,15.53,20.94,15.9,20.49,15.9z M20.49,12.73
    h-2.72h-1.76h-5.13H9.12H6.91c-0.45,0-0.82-0.37-0.82-0.82c0-0.45,0.37-0.82,0.82-0.82H8.5L6.81,6.62c-0.16-0.42,0.05-0.9,0.48-1.06
    h0C7.7,5.4,8.18,5.61,8.34,6.04l1.91,5.05h6.38l1.91-5.05c0.16-0.42,0.64-0.64,1.06-0.48c0.42,0.16,0.64,0.64,0.48,1.06l-1.69,4.47
    h2.1c0.45,0,0.82,0.37,0.82,0.82C21.31,12.36,20.94,12.73,20.49,12.73z"
    />
  ),
  viewBox: '0 0 27 27'
})
