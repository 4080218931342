/* eslint-disable no-useless-escape */
import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import MainLayout from 'containers/Layout/MainLayout'
import CoinInfo from 'components/Dashboard/CoinInfo'
import Exchange from 'components/Dashboard/Exchange'
import UsxInfo from 'components/Dashboard/UsxInfo'
import BorrowLimit from 'components/Dashboard/BorrowLimit'
import Overview from 'components/Dashboard/Overview'
import WalletBalance from 'components/Dashboard/WalletBalance'
import Market from 'components/Dashboard/Market'
import { connectAccount, accountActionCreators } from 'core'
import {
  getTokenContract,
  getSbepContract,
  getComptrollerContract,
  getUsxControllerContract,
  getUsxTokenContract,
  methods,
  getWeb3
} from 'utilities/ContractService'
import BigNumber from 'bignumber.js'
import * as constants from 'utilities/constants'

import { GridItem, Box, Center, Spinner } from '@chakra-ui/react'
import { Grid } from 'components/Basic/Grid'
import { useWeb3React } from '@web3-react/core'
import { MarketsContext } from 'context/MarketsContext'
import { logErrorService } from '../../utilities/errorHandler'

function Dashboard({ settings, setSetting }) {
  const [mounted, setMounted] = useState(false)
  const { account } = useWeb3React()
  const { availableMarkets, availableSbeps } = React.useContext(MarketsContext)
  const updateMarketInfo = useCallback(() => {
    ;(async () => {
      if (!account || !settings.decimals || !settings.markets) {
        return
      }
      const appContract = getComptrollerContract()
      const usxControllerContract = getUsxControllerContract()
      const usxContract = getUsxTokenContract()

      try {
        let [
          userUsxBalance,
          userUsxMinted,
          { 1: mintableUsx },
          allowBalance
        ] = await Promise.all([
          methods.call(usxContract.methods.balanceOf, [account]),
          methods.call(appContract.methods.mintedUSXs, [account]),
          methods.call(usxControllerContract.methods.getMintableUSX, [account]),
          methods.call(usxContract.methods.allowance, [
            account,
            constants.CONTRACT_USX_UNITROLLER_ADDRESS
          ])
        ])
        userUsxBalance = new BigNumber(userUsxBalance).div(
          new BigNumber(10).pow(18)
        )
        userUsxMinted = new BigNumber(userUsxMinted).div(
          new BigNumber(10).pow(18)
        )
        mintableUsx = new BigNumber(mintableUsx).div(new BigNumber(10).pow(18))
        allowBalance = new BigNumber(allowBalance).div(
          new BigNumber(10).pow(18)
        )
        const userUsxEnabled = allowBalance.isGreaterThanOrEqualTo(
          userUsxMinted
        )
        const assetsIn = await methods.call(appContract.methods.getAssetsIn, [
          account
        ])
        let totalBorrowLimit = new BigNumber(0)
        let totalBorrowBalance = new BigNumber(userUsxMinted)
        const assetList = await Promise.all(
          Object.values(availableMarkets).map(async (item, index) => {
            if (!settings.decimals[item.id]) {
              return
            }
            let market = settings.markets.find(
              ele => ele.underlyingSymbol === item.symbol
            )
            if (!market) market = {}
            const asset = {
              key: index,
              id: item.id,
              img: item.asset,
              vimg: item.sasset,
              name: market.underlyingSymbol || '',
              symbol: market.underlyingSymbol || '',
              tokenAddress: market.underlyingAddress,
              vsymbol: market.symbol,
              stokenAddress: availableSbeps[item.id].address,
              supplyApy: new BigNumber(market.supplyApy || 0),
              borrowApy: new BigNumber(market.borrowApy || 0),
              src1SupplyApy: new BigNumber(market.supplySourceOneApy || 0),
              src1BorrowApy: new BigNumber(market.borrowSourceOneApy || 0),
              collateralFactor: new BigNumber(market.collateralFactor || 0).div(
                1e18
              ),
              tokenPrice: new BigNumber(market.tokenPrice || 0),
              liquidity: new BigNumber(market.liquidity || 0),
              borrowCaps: new BigNumber(market.borrowCaps || 0),
              totalBorrows: new BigNumber(market.totalBorrows2 || 0),
              walletBalance: new BigNumber(0),
              supplyBalance: new BigNumber(0),
              borrowBalance: new BigNumber(0),
              isEnabled: false,
              collateral: false,
              percentOfLimit: '0'
            }
            const tokenDecimal = settings.decimals[item.id].token
            const sBepContract = getSbepContract(item.id)
            asset.collateral = assetsIn
              .map(assetItem => assetItem.toLowerCase())
              .includes(asset.stokenAddress.toLowerCase())
            let borrowBalance
            let supplyBalance
            let totalBalance
            // wallet balance
            if (item.id !== 'bnb') {
              const tokenContract = getTokenContract(item.id)
              const [
                walletBalance,
                // eslint-disable-next-line no-shadow
                allowBalance,
                snapshot,
                balance
              ] = await Promise.all([
                methods.call(tokenContract.methods.balanceOf, [account]),
                methods.call(tokenContract.methods.allowance, [
                  account,
                  asset.stokenAddress
                ]),
                methods.call(sBepContract.methods.getAccountSnapshot, [
                  account
                ]),
                methods.call(sBepContract.methods.balanceOf, [account])
              ])
              supplyBalance = new BigNumber(snapshot[1])
                .times(new BigNumber(snapshot[3]))
                .div(new BigNumber(10).pow(18))
              borrowBalance = snapshot[2]
              totalBalance = balance
              asset.walletBalance = new BigNumber(walletBalance).div(
                new BigNumber(10).pow(tokenDecimal)
              )
              // allowance
              asset.isEnabled = new BigNumber(allowBalance)
                .div(new BigNumber(10).pow(tokenDecimal))
                .isGreaterThan(asset.walletBalance)
            } else {
              const web3 = getWeb3()
              const [snapshot, balance, walletBalance] = await Promise.all([
                methods.call(sBepContract.methods.getAccountSnapshot, [
                  account
                ]),
                methods.call(sBepContract.methods.balanceOf, [account]),
                web3.eth.getBalance(account)
              ])
              supplyBalance = new BigNumber(snapshot[1])
                .times(new BigNumber(snapshot[3]))
                .div(new BigNumber(10).pow(18))
              borrowBalance = snapshot[2]
              totalBalance = balance
              if (window.ethereum || window.BinanceChain) {
                asset.isEnabled = true
                asset.walletBalance = new BigNumber(walletBalance).div(
                  new BigNumber(10).pow(tokenDecimal)
                )
              }
            }
            // supply balance
            asset.supplyBalance = new BigNumber(supplyBalance).div(
              new BigNumber(10).pow(tokenDecimal)
            )
            // borrow balance
            asset.borrowBalance = new BigNumber(borrowBalance).div(
              new BigNumber(10).pow(tokenDecimal)
            )
            // percent of limit
            asset.percentOfLimit = new BigNumber(
              settings.totalBorrowLimit
            ).isZero()
              ? '0'
              : asset.borrowBalance
                  .times(asset.tokenPrice)
                  .div(settings.totalBorrowLimit)
                  .times(100)
                  .dp(0, 1)
                  .toString(10)
            // hypotheticalLiquidity
            asset.hypotheticalLiquidity = await methods.call(
              appContract.methods.getHypotheticalAccountLiquidity,
              [account, asset.stokenAddress, totalBalance, 0]
            )
            const supplyBalanceUSD = asset.supplyBalance.times(asset.tokenPrice)
            const borrowBalanceUSD = asset.borrowBalance.times(asset.tokenPrice)
            totalBorrowBalance = totalBorrowBalance.plus(borrowBalanceUSD)
            if (asset.collateral) {
              totalBorrowLimit = totalBorrowLimit.plus(
                supplyBalanceUSD.times(asset.collateralFactor)
              )
            }
            // eslint-disable-next-line consistent-return
            return asset
          })
        )
        if (mounted) {
          setSetting({
            assetList,
            userUsxMinted,
            totalBorrowLimit: totalBorrowLimit.toString(10),
            totalBorrowBalance,
            userUsxBalance,
            userUsxEnabled,
            mintableUsx
          })
        }
      } catch (error) {
        logErrorService(error)
      }
    })()
  }, [account, setSetting, settings.markets, settings.totalBorrowLimit])

  useEffect(() => {
    setMounted(true)
    try {
      if (mounted) {
        updateMarketInfo()
      }
    } catch (error) {
      logErrorService(error)
    }
    return () => {
      setMounted(false)
    }
  }, [account, updateMarketInfo])

  return (
    <>
      <MainLayout isHeader={false}>
        {(!account || settings.wrongNetwork) && (
          <Center>
            <Spinner color="primary.500" size="xl" speed="1s" />
          </Center>
        )}
        {account && !settings.wrongNetwork && (
          <Grid
            desktop={
              <>
                <GridItem colSpan={2}>
                  <Box>
                    <Overview />
                  </Box>
                  <Box>
                    <Market />
                  </Box>
                </GridItem>
                <GridItem colSpan={1}>
                  <Box>
                    <Exchange />
                  </Box>
                  <Box>
                    <CoinInfo />
                  </Box>
                  <Box>
                    <UsxInfo />
                  </Box>
                  <Box>
                    <BorrowLimit />
                  </Box>
                  <Box>
                    <WalletBalance />
                  </Box>
                </GridItem>
              </>
            }
            mobile={
              <GridItem colSpan={3}>
                <Box>
                  <CoinInfo />
                </Box>
                <Box>
                  <UsxInfo />
                </Box>
                <Box>
                  <BorrowLimit />
                </Box>
                <Box>
                  <WalletBalance />
                </Box>
                <Box>
                  <Overview />
                </Box>
                <Box>
                  <Market />
                </Box>
              </GridItem>
            }
          />
        )}
      </MainLayout>
    </>
  )
}

Dashboard.propTypes = {
  settings: PropTypes.object,
  setSetting: PropTypes.func.isRequired
}

Dashboard.defaultProps = {
  settings: {}
}

const mapStateToProps = ({ account }) => ({
  settings: account.setting
})

const mapDispatchToProps = dispatch => {
  const { setSetting } = accountActionCreators

  return bindActionCreators(
    {
      setSetting
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connectAccount(mapStateToProps, mapDispatchToProps)
)(Dashboard)
