export const SpinnerStyle = {
  parts: ['dialog'],
  // Styles for the base style
  baseStyle: {},
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {
    primary: () => {
      return {
        size: 'xl',
        speed: '1s',
        color: 'primary.500'
      }
    }
  },
  // The default `size` or `variant` values
  defaultProps: {
    variant: 'primary',
    size: 'xl',
    speed: '1s'
  }
}
