import * as constants from 'utilities/constants'
import BigNumber from 'bignumber.js'
import { network } from 'config/network'
import { logErrorService } from './errorHandler'

const ethers = require('ethers')
const commaNumber = require('comma-number')

export const format = commaNumber.bindWith(',', '.')

export const encodeParameters = (types, values) => {
  const abi = new ethers.utils.AbiCoder()
  return abi.encode(types, values)
}

export const getArgs = func => {
  // First match everything inside the function argument parens.
  const args = func.toString().match(/.*?\(([^)]*)\)/)
    ? func.toString().match(/.*?\(([^)]*)\)/)[1]
    : ''
  // Split the arguments string into an array comma delimited.
  return args
    .split(',')
    .map(arg => {
      // Ensure no inline comments are parsed and trim the whitespace.
      return arg.replace(/\/\*.*\*\//, '').trim()
    })
    .filter(arg => {
      // Ensure no undefined values are added.
      return arg
    })
}

export const checkIsValidNetwork = walletType => {
  if (walletType && (window.ethereum || window.BinanceChain)) {
    let netId
    if (walletType === constants.binanceWalletType && window.BinanceChain) {
      netId = +window.BinanceChain.chainId
    } else if (window.ethereum) {
      netId = +(window.ethereum.networkVersion ?? window.ethereum.chainId)
    }
    return Number(process.env.REACT_APP_CHAIN_ID) === netId
  }
  return false
}

export const getAvailableMarkets = () => {
  const markets = {}
  const allMarkets = Object.entries(constants.CONTRACT_TOKEN_ADDRESS)
  allMarkets.forEach(([key, value]) => {
    if (key === 'bnb' || Object.keys(network.tokens.token).includes(key)) {
      markets[key] = value
    }
  })
  return markets
}

export const getAvailableSbeps = () => {
  const markets = {}
  const allMarkets = Object.entries(constants.CONTRACT_SBEP_ADDRESS)
  allMarkets.forEach(([key, value]) => {
    if (Object.keys(network.tokens.sbep).includes(key)) {
      markets[key] = value
    }
  })
  return markets
}

export const addToken = async (asset = 'usx', decimal, type) => {
  const availableMarkets = getAvailableMarkets()
  const availableSbeps = getAvailableSbeps()
  let tokenAddress = ''
  let tokenSymbol = ''
  let tokenDecimals = 18
  let tokenImage = ''
  if (asset === 'usx') {
    tokenAddress = constants.CONTRACT_USX_TOKEN_ADDRESS
    tokenSymbol = 'USX'
    tokenDecimals = 18
    tokenImage = `${window.location.origin}/coins/usx.png`
  } else {
    tokenAddress =
      type === 'token'
        ? availableMarkets[asset].address
        : availableSbeps[asset].address
    tokenSymbol =
      type === 'token'
        ? asset.toUpperCase()
        : `s${(asset === 'btcb' ? 'btc' : asset).toUpperCase()}`
    tokenDecimals = decimal || (type === 'token' ? 18 : 8)
    tokenImage = `${window.location.origin}/coins/${
      type === 'token' ? asset : `v${asset === 'btcb' ? 'btc' : asset}`
    }.png`
  }

  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    const wasAdded = await window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20', // Initially only supports ERC20, but eventually more!
        options: {
          address: tokenAddress, // The address that the token is at.
          symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: tokenDecimals, // The number of decimals in the token
          image: tokenImage // A string url of the token logo
        }
      }
    })

    if (wasAdded) {
      // eslint-disable-next-line no-console
      console.log('Thanks for your interest!')
    } else {
      // eslint-disable-next-line no-console
      console.log('Your loss!')
    }
  } catch (error) {
    logErrorService(error)
    // eslint-disable-next-line no-console
    console.log(error)
  }
}

export const getBigNumber = value => {
  if (!value) {
    return new BigNumber(0)
  }
  if (BigNumber.isBigNumber(value)) {
    return value
  }
  return new BigNumber(value)
}

export const currencyFormatter = labelValue => {
  let suffix = ''
  let unit = 1
  const abs = Math.abs(Number(labelValue))
  if (abs >= 1.0e9) {
    // Nine Zeroes for Billions
    suffix = 'B'
    unit = 1.0e9
  } else if (abs >= 1.0e6) {
    // Six Zeroes for Millions
    suffix = 'M'
    unit = 1.0e6
  } else if (abs >= 1.0e3) {
    // Three Zeroes for Thousands
    suffix = 'K'
    unit = 1.0e3
  }
  return `$${format(new BigNumber(`${abs / unit}`).dp(2, 1))}${suffix}`
  // return Math.abs(Number(labelValue)) >= 1.0e9
  //   ? `$${format(
  //       new BigNumber(`${Math.abs(Number(labelValue)) / 1.0e9}`).dp(2, 1)
  //     )}B`
  //   : Math.abs(Number(labelValue)) >= 1.0e6
  //   ? `$${format(
  //       new BigNumber(`${Math.abs(Number(labelValue)) / 1.0e6}`).dp(2, 1)
  //     )}M`
  //   : Math.abs(Number(labelValue)) >= 1.0e3
  //   ? `$${format(
  //       new BigNumber(`${Math.abs(Number(labelValue)) / 1.0e3}`).dp(2, 1)
  //     )}K`
  //   : `$${format(new BigNumber(`${Math.abs(Number(labelValue))}`).dp(2, 1))}`;
}

export const getTransactionErrorMessage = code => {
  switch (code) {
    case constants.TRANSACTION_DENIED_BY_USER_CODE:
      return constants.TRANSACTION_DENIED_BY_USER_MESSAGE
    default:
      return constants.TRANSACTION_ERROR_DEFAULT_MESSAGE
  }
}
