import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { bindActionCreators } from 'redux'
import { connectAccount, accountActionCreators } from 'core'
import BigNumber from 'bignumber.js'
import AnimatedNumber from 'animated-number-react'
import { Card } from 'components/Basic/Card'
import { getBigNumber, format } from 'utilities/common'
import {
  Switch,
  Flex,
  Text,
  Heading,
  CircularProgress,
  CircularProgressLabel
} from '@chakra-ui/react'
import { useWeb3React } from '@web3-react/core'
import { SRC1_SYMBOL } from 'utilities/constants'
import { getUsxVaultContract, methods } from '../../utilities/ContractService'
import { logErrorService } from '../../utilities/errorHandler'

function WalletBalance({ settings, setSetting }) {
  const [netAPY, setNetAPY] = useState(0)
  const { account } = useWeb3React()

  const [totalSupply, setTotalSupply] = useState(new BigNumber(0))
  const [totalBorrow, setTotalBorrow] = useState(new BigNumber(0))

  useEffect(() => {
    let mounted = true
    const addUSXApy = async apy => {
      const vaultContract = getUsxVaultContract()
      const { 0: staked } = await methods.call(vaultContract.methods.userInfo, [
        account
      ])
      const amount = new BigNumber(staked).div(1e18)
      if ((amount.isNaN() || amount.isZero()) && mounted) {
        setNetAPY(apy.dp(2, 1).toNumber())
      } else if (mounted) {
        setNetAPY(
          apy
            .plus(settings.usxAPY)
            .decimalPlaces(2)
            .toNumber()
        )
      }
    }

    const updateNetAPY = async () => {
      let totalSum = new BigNumber(0)
      let totalSupplied = new BigNumber(0)
      let totalBorrowed = new BigNumber(settings.userUsxMinted)
      const assetList = settings.assetList
      assetList.forEach(asset => {
        if (!asset) return
        const {
          supplyBalance,
          borrowBalance,
          tokenPrice,
          supplyApy,
          borrowApy,
          src1SupplyApy,
          src1BorrowApy
        } = asset
        const supplyBalanceUSD = getBigNumber(supplyBalance).times(
          getBigNumber(tokenPrice)
        )
        const borrowBalanceUSD = getBigNumber(borrowBalance).times(
          getBigNumber(tokenPrice)
        )
        totalSupplied = totalSupplied.plus(supplyBalanceUSD)
        totalBorrowed = totalBorrowed.plus(borrowBalanceUSD)

        const supplyApyWithSRC1 = settings.withSRC1
          ? getBigNumber(supplyApy).plus(getBigNumber(src1SupplyApy))
          : getBigNumber(supplyApy)
        const borrowApyWithSRC1 = settings.withSRC1
          ? getBigNumber(src1BorrowApy).minus(getBigNumber(borrowApy))
          : getBigNumber(borrowApy).times(-1)

        totalSum = totalSum.plus(
          supplyBalanceUSD
            .times(supplyApyWithSRC1.div(100))
            .plus(borrowBalanceUSD.times(borrowApyWithSRC1.div(100)))
        )
      })

      let apy

      if (totalSum.isZero() || totalSum.isNaN()) {
        apy = new BigNumber(0)
      } else if (totalSum.isGreaterThan(0)) {
        apy = totalSupplied.isZero()
          ? 0
          : totalSum.div(totalSupplied).times(100)
      } else {
        apy = totalBorrowed.isZero()
          ? 0
          : totalSum.div(totalBorrowed).times(100)
      }
      setTotalSupply(totalSupplied)
      setTotalBorrow(totalBorrowed)
      addUSXApy(apy)
    }

    try {
      if (
        mounted &&
        account &&
        settings.assetList &&
        settings.assetList.length > 0
      ) {
        updateNetAPY()
      }
    } catch (error) {
      logErrorService(error)
    }
    return () => {
      mounted = false
    }
  }, [
    account,
    settings.assetList,
    settings.userUsxMinted,
    settings.withSRC1,
    settings.usxAPY
  ])

  const formatValue = value => {
    return `$${format(
      getBigNumber(value)
        .dp(2, 1)
        .toString(10)
    )}`
  }

  return (
    <Card flexDir="column">
      <Flex width="full" justifyContent="center">
        <CircularProgress
          value={netAPY}
          size={{ base: '200px', sm: '300px' }}
          color="primary.500"
          thickness="2px"
        >
          <CircularProgressLabel>
            <Text fontSize="4xl">{`${netAPY}%`}</Text>
            <Text fontSize="md" fontWeight="light">
              Net APY
            </Text>
          </CircularProgressLabel>
        </CircularProgress>
      </Flex>
      <Flex flexDir="column" alignItems="center" py={8}>
        <Switch
          size="lg"
          isChecked={settings.withSRC1}
          onChange={() => setSetting({ withSRC1: !settings.withSRC1 })}
          colorScheme="secondary.500"
          mb={2}
        />
        <Text size="sm">
          {`APY with${!settings.withSRC1 ? 'out' : ''} ${SRC1_SYMBOL}`}
        </Text>
      </Flex>
      <Flex>
        <Flex alignItems="center" flexDir="column" width="50%">
          <Text size="sm">Supply Balance</Text>
          <Heading fontSize="3xl" fontWeight="light" color="white">
            <AnimatedNumber
              value={totalSupply.dp(2, 1).toString(10)}
              formatValue={formatValue}
              duration={2000}
            />
          </Heading>
        </Flex>
        <Flex alignItems="center" flexDir="column" width="50%">
          <Text size="sm">Borrow Balance</Text>
          <Heading fontSize="3xl" fontWeight="light" color="white">
            <AnimatedNumber
              value={totalBorrow.dp(2, 1).toString(10)}
              formatValue={formatValue}
              duration={2000}
            />
          </Heading>
        </Flex>
      </Flex>
    </Card>
  )
}

WalletBalance.propTypes = {
  settings: PropTypes.object,
  setSetting: PropTypes.func.isRequired
}

WalletBalance.defaultProps = {
  settings: {}
}

const mapStateToProps = ({ account }) => ({
  settings: account.setting
})

const mapDispatchToProps = dispatch => {
  const { setSetting } = accountActionCreators

  return bindActionCreators(
    {
      setSetting
    },
    dispatch
  )
}

export default compose(connectAccount(mapStateToProps, mapDispatchToProps))(
  WalletBalance
)
