import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import Button from '@material-ui/core/Button'
import NumberFormat from 'react-number-format'
import { bindActionCreators } from 'redux'
import { connectAccount, accountActionCreators } from 'core'
import BigNumber from 'bignumber.js'
import {
  getTokenContract,
  getSbepContract,
  methods
} from 'utilities/ContractService'
import { sendRepay } from 'utilities/BnbContract'
import commaNumber from 'comma-number'
import arrowRightImg from 'assets/img/arrow-right.png'
import src1 from 'assets/img/source_one_64.png'
import usxImg from 'assets/img/coins/usx.png'
import { Icon, Progress } from 'antd'
import { TabSection, Tabs, TabContent } from 'components/Basic/BorrowModal'
import { getBigNumber, getTransactionErrorMessage } from 'utilities/common'
import { useWeb3React } from '@web3-react/core'
import toast from 'components/Basic/Toast'

const format = commaNumber.bindWith(',', '.')

function RepayBorrowTab({
  asset,
  settings,
  changeTab,
  onCancel,
  setSetting,
  amount,
  setAmount,
  borrowBalance,
  newBorrowBalance,
  borrowPercent,
  newBorrowPercent
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [isEnabled, setIsEnabled] = useState(false)
  const { account } = useWeb3React()

  const hidePendingRepay = () => {
    setSetting({
      pendingInfo: {
        type: '',
        status: false,
        amount: 0,
        symbol: ''
      }
    })
  }

  const hideRepayBorrow = () => {
    setAmount(new BigNumber(0))
    onCancel()
    setIsLoading(false)
    hidePendingRepay()
  }

  const handleTransactionError = code => {
    toast.error({
      title: getTransactionErrorMessage(code)
    })
  }

  /**
   * Approve underlying token
   */
  const onApprove = async () => {
    if (asset && account && asset.id !== 'bnb') {
      setIsLoading(true)
      const tokenContract = getTokenContract(asset.id)
      methods
        .send(
          tokenContract.methods.approve,
          [
            asset.stokenAddress,
            new BigNumber(2)
              .pow(256)
              .minus(1)
              .toString(10)
          ],
          account
        )
        .then(() => {
          setIsEnabled(true)
          setIsLoading(false)
        })
        .catch(e => {
          handleTransactionError(e.code)
          hidePendingRepay()
          setIsLoading(false)
        })
    }
  }
  /**
   * Repay Borrow
   */
  const handleRepayBorrow = async () => {
    const appContract = getSbepContract(asset.id)
    if (asset && account) {
      setIsLoading(true)
      setSetting({
        pendingInfo: {
          type: 'Repay Borrow',
          status: true,
          amount: amount.dp(8, 1).toString(10),
          symbol: asset.symbol
        }
      })
      if (asset.id !== 'bnb') {
        if (amount.eq(asset.borrowBalance)) {
          const gasLimit = await methods.estimateGas(
            appContract.methods.repayBorrow,
            [
              new BigNumber(2)
                .pow(256)
                .minus(1)
                .toString(10)
            ],
            account
          )
          methods
            .sendWithOptions(
              appContract.methods.repayBorrow,
              [
                new BigNumber(2)
                  .pow(256)
                  .minus(1)
                  .toString(10)
              ],
              { from: account, gas: gasLimit }
            )
            .then(() => {
              hideRepayBorrow()
            })
            .catch(e => {
              handleTransactionError(e.code)
              hidePendingRepay()
              setIsLoading(false)
            })
        } else {
          methods
            .send(
              appContract.methods.repayBorrow,
              [
                amount
                  .times(
                    new BigNumber(10).pow(settings.decimals[asset.id].token)
                  )
                  .integerValue()
                  .toString(10)
              ],
              account
            )
            .then(() => {
              hideRepayBorrow()
            })
            .catch(e => {
              handleTransactionError(e.code)
              hidePendingRepay()
              setIsLoading(false)
            })
        }
      } else {
        sendRepay(
          account,
          amount
            .times(new BigNumber(10).pow(settings.decimals[asset.id].token))
            .integerValue()
            .toString(10),
          () => {
            hideRepayBorrow()
          }
        ).catch(e => {
          handleTransactionError(e.code)
          hidePendingRepay()
          setIsLoading(false)
        })
      }
    }
  }

  /**
   * Max amount
   */
  const handleMaxAmount = () => {
    setAmount(BigNumber.minimum(asset.walletBalance, asset.borrowBalance))
  }

  useEffect(() => {
    setIsEnabled(asset.isEnabled)
  }, [asset.isEnabled])

  return (
    <TabSection>
      <div className="flex flex-column align-center just-center body-content">
        {isEnabled ? (
          <div className="flex align-center input-wrapper">
            <NumberFormat
              autoFocus
              value={amount.isZero() ? '0' : amount.toString(10)}
              onValueChange={values => {
                const { value } = values
                setAmount(new BigNumber(value))
              }}
              isAllowed={({ value }) => {
                return new BigNumber(value || 0).isLessThanOrEqualTo(
                  BigNumber.minimum(asset.walletBalance, asset.borrowBalance)
                )
              }}
              thousandSeparator
              allowNegative={false}
              placeholder="0"
            />
            <span className="pointer max" onClick={() => handleMaxAmount()}>
              MAX
            </span>
          </div>
        ) : (
          <>
            <img src={asset.img} alt="asset" />
            <p className="center warning-label">
              To Repay {asset.name} to the Source Marketplace, you need to
              approve it first.
            </p>
          </>
        )}
      </div>
      <Tabs className="flex align-center">
        <div
          className="flex align-center just-center tab-item pointer"
          onClick={() => {
            changeTab('borrow')
          }}
        >
          Borrow
        </div>
        <div
          className="flex align-center just-center tab-item pointer tab-active"
          onClick={() => {
            changeTab('repayBorrow')
          }}
        >
          Repay Borrow
        </div>
      </Tabs>
      <TabContent className="flex flex-column align-center just-content">
        <div className="flex flex-column just-center align-center apy-content">
          <div className="description">
            <div className="flex align-center">
              <img className="asset-img" src={asset.img} alt="asset" />
              <span>Borrow APY</span>
            </div>
            <span>{asset.borrowApy.dp(2, 1).toString(10)}%</span>
          </div>
          <div className="description">
            <div className="flex align-center">
              <img
                style={{
                  width: 25,
                  height: 25,
                  marginLeft: 2,
                  marginRight: 16
                }}
                src={src1}
                alt="asset"
              />
              <span>Distribution APY</span>
            </div>
            <span>
              {getBigNumber(asset.src1BorrowApy)
                .dp(2, 1)
                .toString(10)}
              %
            </span>
          </div>
          <div className="description">
            <div className="flex align-center">
              <img
                style={{
                  width: 25,
                  height: 25,
                  marginLeft: 2,
                  marginRight: 16
                }}
                src={usxImg}
                alt="asset"
              />
              <span>Repay USX Balance</span>
            </div>
            <span>
              {getBigNumber(settings.userUsxMinted)
                .dp(2, 1)
                .toString(10)}{' '}
              USX
            </span>
          </div>
        </div>
        {isEnabled && (
          <div className="flex flex-column just-center align-center apy-content">
            <div className="borrow-balance">
              <span>Borrow Balance</span>
              {amount.isZero() || amount.isNaN() ? (
                <span>${borrowBalance.dp(2, 1).toString(10)}</span>
              ) : (
                <div className="flex align-center just-between">
                  <span>${borrowBalance.dp(2, 1).toString(10)}</span>
                  <img
                    className="arrow-right-img"
                    src={arrowRightImg}
                    alt="arrow"
                  />
                  <span>${newBorrowBalance.dp(2, 1).toString(10)}</span>
                </div>
              )}
            </div>
            <div className="borrow-limit">
              <span>Borrow Limit Used</span>
              {amount.isZero() || amount.isNaN() ? (
                <span>{borrowPercent.dp(2, 1).toString(10)}%</span>
              ) : (
                <div className="flex align-center just-between">
                  <span>{borrowPercent.dp(2, 1).toString(10)}%</span>
                  <img
                    className="arrow-right-img"
                    src={arrowRightImg}
                    alt="arrow"
                  />
                  <span>{newBorrowPercent.dp(2, 1).toString(10)}%</span>
                </div>
              )}
            </div>
            <Progress
              percent={newBorrowPercent.toNumber()}
              strokeColor="#56a4f6"
              strokeWidth={7}
              showInfo={false}
            />
          </div>
        )}
        {!isEnabled && asset.id !== 'bnb' ? (
          <Button
            className="button"
            disabled={isLoading}
            onClick={() => {
              onApprove()
            }}
          >
            {isLoading && <Icon type="loading" />} Approve
          </Button>
        ) : (
          <Button
            className="button"
            disabled={
              isLoading ||
              amount.isZero() ||
              amount.isNaN() ||
              amount.isGreaterThan(
                BigNumber.minimum(asset.walletBalance, asset.borrowBalance)
              )
            }
            onClick={handleRepayBorrow}
          >
            {isLoading && <Icon type="loading" />} Repay Borrow
          </Button>
        )}
        <div className="description">
          <span>Wallet Balance</span>
          <span>
            {format(asset.walletBalance.dp(2, 1).toString(10))} {asset.symbol}
          </span>
        </div>
      </TabContent>
    </TabSection>
  )
}

RepayBorrowTab.propTypes = {
  asset: PropTypes.object,
  settings: PropTypes.object,
  changeTab: PropTypes.func,
  onCancel: PropTypes.func,
  setSetting: PropTypes.func.isRequired
}

RepayBorrowTab.defaultProps = {
  asset: {},
  settings: {},
  changeTab: () => {},
  onCancel: () => {}
}

const mapStateToProps = ({ account }) => ({
  settings: account.setting
})

const mapDispatchToProps = dispatch => {
  const { setSetting } = accountActionCreators

  return bindActionCreators(
    {
      setSetting
    },
    dispatch
  )
}

export default compose(connectAccount(mapStateToProps, mapDispatchToProps))(
  RepayBorrowTab
)
