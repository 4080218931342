/* eslint-disable no-useless-escape */
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import BigNumber from 'bignumber.js'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connectAccount, accountActionCreators } from 'core'
import {
  getTokenContract,
  getSbepContract,
  getComptrollerContract,
  getUsxControllerContract,
  methods
} from 'utilities/ContractService'
import MainLayout from 'containers/Layout/MainLayout'
import CoinInfo from 'components/Vote/CoinInfo'
import VotingWallet from 'components/Vote/VotingWallet'
import VotingPower from 'components/Vote/VotingPower'
import Proposals from 'components/Vote/Proposals'
import { promisify } from 'utilities'
import { checkIsValidNetwork } from 'utilities/common'
import * as constants from 'utilities/constants'
import { GridItem, Box, Center, Spinner } from '@chakra-ui/react'
import { Grid } from 'components/Basic/Grid'
import { useWeb3React } from '@web3-react/core'
import { MarketsContext } from 'context/MarketsContext'

let timeStamp = 0

function Vote({ settings, getProposals }) {
  const [balance, setBalance] = useState(0)
  const [votingWeight, setVotingWeight] = useState('0')
  const [proposals, setProposals] = useState({})
  const [current, setCurrent] = useState(1)
  const [isLoadingProposal, setIsLoadingPropoasl] = useState(false)
  const [earnedBalance, setEarnedBalance] = useState('0.00000000')
  const [usxMint, setUsxMint] = useState('0.00000000')
  const [delegateAddress, setDelegateAddress] = useState('')
  const [delegateStatus, setDelegateStatus] = useState('')
  const { account } = useWeb3React()
  const mount = useRef(false)
  const { availableSbeps } = React.useContext(MarketsContext)

  useEffect(() => {
    mount.current = true
    const loadInitialData = async () => {
      setIsLoadingPropoasl(true)
      const data = await promisify(getProposals, {
        offset: 0,
        limit: constants.proposalsDisplay
      })
        .then(res => {
          return res
        })
        .catch(err => {
          console.error(err)
          return false
        })
      if (mount.current) {
        setIsLoadingPropoasl(false)
        if (data) setProposals(data.data)
      }
    }
    loadInitialData()
    return () => {
      mount.current = false
    }
  }, [])

  const handleChangePage = (pageNumber, offset, limit) => {
    setCurrent(pageNumber)
    setIsLoadingPropoasl(true)
    promisify(getProposals, {
      offset,
      limit
    })
      .then(res => {
        setProposals(res.data)
        setIsLoadingPropoasl(false)
      })
      .catch(err => {
        console.error(err)
        setIsLoadingPropoasl(false)
      })
  }

  useEffect(() => {
    let mounted = true
    const updateBalance = async () => {
      if (account && checkIsValidNetwork(settings.walletType)) {
        const src1TokenContract = getTokenContract(constants.SRC1_SYMBOL)
        await methods
          .call(src1TokenContract.methods.getCurrentVotes, [account])
          .then(res => {
            const weight = new BigNumber(res)
              .div(constants.mantissa)
              .toString(constants.stringFormat)
            if (mounted) setVotingWeight(weight)
          })
        let temp = await methods.call(src1TokenContract.methods.balanceOf, [
          account
        ])
        temp = new BigNumber(temp)
          .dividedBy(constants.mantissa)
          .decimalPlaces(constants.displayDecimals)
          .toString(constants.stringFormat)
        if (mounted) setBalance(temp)
      }
    }
    const getVoteInfo = async () => {
      const myAddress = account
      if (!myAddress) return
      const appContract = getComptrollerContract()
      const usxContract = getUsxControllerContract()

      const [
        sourceOneInitialIndex,
        sourceOneAccrued,
        sourceOneUSXState,
        usxMinterIndex,
        usxMinterAmount
      ] = await Promise.all([
        methods.call(appContract.methods.sourceOneInitialIndex, []),
        methods.call(appContract.methods.sourceOneAccrued, [myAddress]),
        methods.call(usxContract.methods.sourceOneUSXState, []),
        methods.call(usxContract.methods.sourceOneUSXMinterIndex, [myAddress]),
        methods.call(appContract.methods.mintedUSXs, [myAddress])
      ])
      let sourceOneEarned = new BigNumber(0)
      await Promise.all(
        Object.values(availableSbeps).map(async item => {
          const sBepContract = getSbepContract(item.id)
          const [
            supplyState,
            supplierIndex,
            supplierTokens,
            borrowState,
            borrowerIndex,
            borrowBalanceStored,
            borrowIndex
          ] = await Promise.all([
            methods.call(appContract.methods.sourceOneSupplyState, [
              item.address
            ]),
            methods.call(appContract.methods.sourceOneSupplierIndex, [
              item.address,
              myAddress
            ]),
            methods.call(sBepContract.methods.balanceOf, [myAddress]),
            methods.call(appContract.methods.sourceOneBorrowState, [
              item.address
            ]),
            methods.call(appContract.methods.sourceOneBorrowerIndex, [
              item.address,
              myAddress
            ]),
            methods.call(sBepContract.methods.borrowBalanceStored, [myAddress]),
            methods.call(sBepContract.methods.borrowIndex, [])
          ])
          const supplyIndex = supplyState.index
          let deltaIndex = new BigNumber(supplyIndex).minus(
            +supplierIndex === 0 && +supplyIndex > 0
              ? sourceOneInitialIndex
              : supplierIndex
          )

          const supplierDelta = new BigNumber(supplierTokens)
            .multipliedBy(deltaIndex)
            .dividedBy(constants.deltaMantissa)

          sourceOneEarned = sourceOneEarned.plus(supplierDelta)
          if (+borrowerIndex > 0) {
            deltaIndex = new BigNumber(borrowState.index).minus(borrowerIndex)
            const borrowerAmount = new BigNumber(borrowBalanceStored)
              .multipliedBy(constants.mantissa)
              .dividedBy(borrowIndex)
            const borrowerDelta = borrowerAmount
              .times(deltaIndex)
              .dividedBy(constants.deltaMantissa)
            sourceOneEarned = sourceOneEarned.plus(borrowerDelta)
          }
        })
      )

      sourceOneEarned = sourceOneEarned
        .plus(sourceOneAccrued)
        .dividedBy(constants.mantissa)
        .decimalPlaces(constants.preciseDisplayDecimals)
        .toString(constants.stringFormat)

      const usxMintIndex = sourceOneUSXState.index
      const deltaIndex = new BigNumber(usxMintIndex).minus(
        new BigNumber(
          +usxMinterIndex === 0 && +usxMintIndex > 0
            ? sourceOneInitialIndex
            : usxMinterIndex
        )
      )
      const usxMinterDelta = new BigNumber(usxMinterAmount)
        .times(deltaIndex)
        .div(constants.mantissa * constants.deltaMantissa)
        .decimalPlaces(constants.preciseDisplayDecimals)
        .toString(constants.stringFormat)
      if (mounted) {
        setEarnedBalance(
          sourceOneEarned && sourceOneEarned !== '0'
            ? `${sourceOneEarned}`
            : '0.00000000'
        )
        setUsxMint(
          usxMinterDelta && usxMinterDelta !== '0'
            ? `${usxMinterDelta}`
            : '0.00000000'
        )
      }
    }
    const updateDelegate = async () => {
      if (account && timeStamp % 3 === 0) {
        const tokenContract = getTokenContract(constants.SRC1_SYMBOL)
        methods
          .call(tokenContract.methods.delegates, [account])
          .then(res => {
            if (mounted) setDelegateAddress(res)
            if (res !== constants.zeroAddress) {
              if (mounted)
                setDelegateStatus(res === account ? 'self' : 'delegate')
            } else if (mounted) {
              setDelegateStatus('')
            }
          })
          .catch(() => {})
      }
      timeStamp = Date.now()
    }
    getVoteInfo()
    updateBalance()
    updateDelegate()
    return () => {
      mounted = false
    }
  }, [settings.markets, account])

  return (
    <MainLayout isHeader={false}>
      {(!account || settings.wrongNetwork) && (
        <Center>
          <Spinner color="primary.500" size="xl" speed="1s" />
        </Center>
      )}
      {account && !settings.wrongNetwork && (
        <Grid
          desktop={
            <>
              <GridItem colSpan={2}>
                <Box>
                  <Proposals
                    address={account || ''}
                    isLoadingProposal={isLoadingProposal}
                    pageNumber={current}
                    proposals={proposals.result}
                    total={proposals.total || 0}
                    votingWeight={votingWeight}
                    onChangePage={handleChangePage}
                  />
                </Box>
              </GridItem>
              <GridItem colSpan={1}>
                <Box>
                  <CoinInfo
                    balance={balance !== '0' ? `${balance}` : '0.00000000'}
                    address={account || ''}
                  />
                </Box>
                <Box>
                  <VotingPower
                    power={
                      votingWeight !== '0'
                        ? `${new BigNumber(votingWeight).dp(8, 1).toString(10)}`
                        : '0.00000000'
                    }
                  />
                </Box>
                <Box>
                  <VotingWallet
                    balance={balance !== '0' ? `${balance}` : '0.00000000'}
                    earnedBalance={earnedBalance}
                    usxMint={usxMint}
                    delegateAddress={delegateAddress}
                    delegateStatus={delegateStatus}
                  />
                </Box>
              </GridItem>
            </>
          }
          mobile={
            <GridItem colSpan={3}>
              <Box>
                <CoinInfo
                  balance={balance !== '0' ? `${balance}` : '0.00000000'}
                  address={account || ''}
                />
              </Box>
              <Box>
                <VotingPower
                  power={
                    votingWeight !== '0'
                      ? `${new BigNumber(votingWeight).dp(8, 1).toString(10)}`
                      : '0.00000000'
                  }
                />
              </Box>
              <Box>
                <VotingWallet
                  balance={balance !== '0' ? `${balance}` : '0.00000000'}
                  earnedBalance={earnedBalance}
                  usxMint={usxMint}
                  delegateAddress={delegateAddress}
                  delegateStatus={delegateStatus}
                />
              </Box>
              <Box>
                <Proposals
                  address={account || ''}
                  isLoadingProposal={isLoadingProposal}
                  pageNumber={current}
                  proposals={proposals.result}
                  total={proposals.total || 0}
                  votingWeight={votingWeight}
                  onChangePage={handleChangePage}
                />
              </Box>
            </GridItem>
          }
        />
      )}
    </MainLayout>
  )
}

Vote.propTypes = {
  settings: PropTypes.object,
  getProposals: PropTypes.func.isRequired
}

Vote.defaultProps = {
  settings: {}
}

const mapStateToProps = ({ account }) => ({
  settings: account.setting
})

const mapDispatchToProps = dispatch => {
  const { getProposals, setSetting } = accountActionCreators

  return bindActionCreators(
    {
      getProposals,
      setSetting
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connectAccount(mapStateToProps, mapDispatchToProps)
)(Vote)
