import React from 'react'
import { createIcon } from '@chakra-ui/react'
import { SRC1_SYMBOL } from 'utilities/constants'

export const Src1 = createIcon({
  displayName: SRC1_SYMBOL,
  path: (
    <path
      d="M1028.41 512.7a5 5 0 0 1-5 5H997a6.83 6.83 0 0 1-4.09-1.51 4.6 4.6 0 0 1-1.91-3.49 5 5 0 0 1 5-5h27.42a5 5 0 0 1 5 
    5Zm-54.77 93.85a4.81 4.81 0 0 0 3.27 1.71h.44a5 5 0 0 0 5-5V505.4a5.36 5.36 0 0 1 5.36-5.37h35.66a5 5 0 0 0 5-5v-.47a4.85 4.85 
    0 0 0-1.73-3.25 5.56 5.56 0 0 0-3.56-1.3H897a5 5 0 0 0-5 5v.44a4.83 4.83 0 0 0 1.72 3.27 5.52 5.52 0 0 0 3.57 1.31h35.37a5.37 
    5.37 0 0 1 5.34 5.37V603a5.54 5.54 0 0 0 1.31 3.57 4.81 4.81 0 0 0 3.27 1.71h.44a5 5 0 0 0 5-5s0-.79 0-2.22v-6.12c0-5.2 
    0-12.44.05-20.64 0-16.39.07-36.6.07-51.84v-17a5.36 5.36 0 0 1 5.37-5.37H967a5.36 5.36 0 0 1 5.37 5.37V603a5.54 5.54 0 0 0 1.27 
    3.55Zm-8.44-76a5 5 0 1 0-10 0v72.69a5 5 0 1 0 10 0Zm-5-23.23a5.36 5.36 0 1 0 3.79 1.57 5.34 5.34 0 0 0-3.81-1.55Zm-66.76-26.56a5 
    5 0 0 0 3.55 1.47h126.42a5 5 0 1 0 0-10H897a5 5 0 0 0-3.55 8.57ZM928 509.15a5 5 0 0 0-3.55-1.47H897a5 5 0 0 0-5 5 5 5 0 0 0 5 
    5h27.4a5 5 0 0 0 3.55-8.57Z"
      transform="translate(-891.95 -472.21)"
    />
  ),
  viewBox: '0 0 130 130'
})
