import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import BigNumber from 'bignumber.js'
import { compose } from 'recompose'
import { Icon, Progress } from 'antd'
import Button from '@material-ui/core/Button'
import NumberFormat from 'react-number-format'
import { bindActionCreators } from 'redux'
import { connectAccount, accountActionCreators } from 'core'
import {
  getTokenContract,
  getSbepContract,
  methods,
  getWeb3
} from 'utilities/ContractService'
import commaNumber from 'comma-number'
import { sendSupply } from 'utilities/BnbContract'
import src1 from 'assets/img/source_one_64.png'
import arrowRightImg from 'assets/img/arrow-right.png'
import usxImg from 'assets/img/coins/usx.png'
import { TabSection, Tabs, TabContent } from 'components/Basic/SupplyModal'
import { getBigNumber } from 'utilities/common'
import { useWeb3React } from '@web3-react/core'
import { MarketsContext } from 'context/MarketsContext'

const format = commaNumber.bindWith(',', '.')
const abortController = new AbortController()

function SupplyTab({ asset, settings, changeTab, onCancel, setSetting }) {
  const [isLoading, setIsLoading] = useState(false)
  const [isEnabled, setIsEnabled] = useState(false)
  const [amount, setAmount] = useState(new BigNumber(0))
  const [borrowLimit, setBorrowLimit] = useState(new BigNumber(0))
  const [borrowPercent, setBorrowPercent] = useState(new BigNumber(0))
  const [newBorrowLimit, setNewBorrowLimit] = useState(new BigNumber(0))
  const [newBorrowPercent, setNewBorrowPercent] = useState(new BigNumber(0))
  const { account } = useWeb3React()
  const { availableSbeps } = React.useContext(MarketsContext)

  useEffect(() => {
    setIsEnabled(asset.isEnabled)
  }, [asset.isEnabled])

  /**
   * Get Allowed amount
   */
  useEffect(() => {
    const updateInfo = async () => {
      const totalBorrowBalance = getBigNumber(settings.totalBorrowBalance)
      const totalBorrowLimit = getBigNumber(settings.totalBorrowLimit)
      const tokenPrice = getBigNumber(asset.tokenPrice)
      const collateralFactor = getBigNumber(asset.collateralFactor)

      if (tokenPrice && !amount.isZero() && !amount.isNaN()) {
        const temp = totalBorrowLimit.plus(
          amount.times(tokenPrice).times(collateralFactor)
        )
        setNewBorrowLimit(BigNumber.maximum(temp, 0))
        setNewBorrowPercent(totalBorrowBalance.div(temp).times(100))
        if (totalBorrowLimit.isZero()) {
          setBorrowLimit(new BigNumber(0))
          setBorrowPercent(new BigNumber(0))
        } else {
          setBorrowLimit(totalBorrowLimit)
          setBorrowPercent(totalBorrowBalance.div(totalBorrowLimit).times(100))
        }
      } else if (BigNumber.isBigNumber(totalBorrowLimit)) {
        setBorrowLimit(totalBorrowLimit)
        setNewBorrowLimit(totalBorrowLimit)
        if (totalBorrowLimit.isZero()) {
          setBorrowPercent(new BigNumber(0))
          setNewBorrowPercent(new BigNumber(0))
        } else {
          setBorrowPercent(totalBorrowBalance.div(totalBorrowLimit).times(100))
          setNewBorrowPercent(
            totalBorrowBalance.div(totalBorrowLimit).times(100)
          )
        }
      }
    }

    if (asset.stokenAddress && account) {
      updateInfo()
    }
    return function cleanup() {
      abortController.abort()
    }
  }, [
    account,
    asset.stokenAddress,
    settings.totalBorrowBalance,
    settings.totalBorrowLimit,
    asset.tokenPrice,
    asset.collateralFactor,
    amount
  ])
  /**
   * Approve underlying token
   */
  const onApprove = async () => {
    if (asset.id && account && asset.id !== 'bnb') {
      setIsLoading(true)
      const tokenContract = getTokenContract(asset.id)
      methods
        .send(
          tokenContract.methods.approve,
          [
            asset.stokenAddress,
            new BigNumber(2)
              .pow(256)
              .minus(1)
              .toString(10)
          ],
          account
        )
        .then(() => {
          setIsEnabled(true)
          setIsLoading(false)
        })
        .catch(() => {
          setIsLoading(false)
        })
    }
  }

  /**
   * Supply
   */
  const handleSupply = () => {
    const appContract = getSbepContract(asset.id)

    if (asset.id && account) {
      setIsLoading(true)
      setSetting({
        pendingInfo: {
          type: 'Supply',
          status: true,
          amount: amount.dp(8, 1).toString(10),
          symbol: asset.symbol
        }
      })
      if (asset.id !== 'bnb') {
        methods
          .send(
            appContract.methods.mint,
            [
              amount
                .times(new BigNumber(10).pow(settings.decimals[asset.id].token))
                .toString(10)
            ],
            account
          )
          .then(() => {
            setAmount(new BigNumber(0))
            setIsLoading(false)
            setSetting({
              pendingInfo: {
                type: '',
                status: false,
                amount: 0,
                symbol: ''
              }
            })
            onCancel()
          })
          .catch(() => {
            setIsLoading(false)
            setSetting({
              pendingInfo: {
                type: '',
                status: false,
                amount: 0,
                symbol: ''
              }
            })
          })
      } else {
        sendSupply(
          account,
          amount
            .times(new BigNumber(10).pow(settings.decimals[asset.id].token))
            .toString(10),
          () => {
            setAmount(new BigNumber(0))
            setIsLoading(false)
            setSetting({
              pendingInfo: {
                type: '',
                status: false,
                amount: 0,
                symbol: ''
              }
            })
            onCancel()
          }
        )
      }
    }
  }
  /**
   * Max amount
   */
  const handleMaxAmount = async () => {
    if (asset.name === 'BNB') {
      const web3 = getWeb3()
      const gasPrice = await web3.eth.getGasPrice((error, gasPriced) => {
        return gasPriced
      })
      const estimateGas = await web3.eth.estimateGas({
        from: account,
        to: availableSbeps.bnb.address,
        amount: asset.walletBalance
      })
      setAmount(
        new BigNumber(
          asset.walletBalance - (estimateGas * gasPrice * 1.7) / 1e18
        )
      )
    } else {
      setAmount(asset.walletBalance)
    }
  }

  return (
    <TabSection>
      <div className="flex flex-column align-center just-center body-content">
        {asset.id === 'bnb' || isEnabled ? (
          <div className="flex align-center input-wrapper">
            <NumberFormat
              autoFocus
              value={amount.isZero() ? '0' : amount.toString(10)}
              onValueChange={({ value }) => {
                setAmount(new BigNumber(value))
              }}
              isAllowed={({ value }) => {
                return new BigNumber(value || 0).isLessThanOrEqualTo(
                  asset.walletBalance
                )
              }}
              thousandSeparator
              allowNegative={false}
              placeholder="0"
            />
            <span className="pointer max" onClick={() => handleMaxAmount()}>
              MAX
            </span>
          </div>
        ) : (
          <>
            <img src={asset.img} alt="asset" />
            <p className="center warning-label">
              To Supply {asset.name} to the Source Marketplace, you need to
              approve it first.
            </p>
          </>
        )}
      </div>
      <Tabs className="flex align-center">
        <div
          className="flex align-center just-center tab-item pointer tab-active"
          onClick={() => {
            changeTab('supply')
          }}
        >
          Supply
        </div>
        <div
          className="flex align-center just-center tab-item pointer"
          onClick={() => {
            changeTab('withdraw')
          }}
        >
          Withdraw
        </div>
      </Tabs>
      <TabContent className="flex flex-column align-center just-center">
        <div className="flex flex-column just-center align-center apy-content">
          <div className="description">
            <div className="flex align-center">
              <img className="asset-img" src={asset.img} alt="asset" />
              <span>Supply APY</span>
            </div>
            <span>{asset.supplyApy.dp(2, 1).toString(10)}%</span>
          </div>
          <div className="description">
            <div className="flex align-center">
              <img
                style={{
                  width: 25,
                  height: 25,
                  marginLeft: 2,
                  marginRight: 16
                }}
                src={src1}
                alt="asset"
              />
              <span>Distribution APY</span>
            </div>
            <span>
              {getBigNumber(asset.src1SupplyApy)
                .dp(2, 1)
                .toString(10)}
              %
            </span>
          </div>
          <div className="description">
            <div className="flex align-center">
              <img
                style={{
                  width: 25,
                  height: 25,
                  marginLeft: 2,
                  marginRight: 16
                }}
                src={usxImg}
                alt="asset"
              />
              <span>Available USX Limit</span>
            </div>
            <span>
              {getBigNumber(settings.mintableUsx)
                .dp(2, 1)
                .toString(10)}{' '}
              USX
            </span>
          </div>
        </div>
        {isEnabled && (
          <div className="flex flex-column just-center align-center apy-content">
            <div className="borrow-limit">
              <span>Borrow Limit</span>
              {amount.isZero() || amount.isNaN() ? (
                <span>${format(borrowLimit.dp(2, 1).toString(10))}</span>
              ) : (
                <div className="flex align-center just-between">
                  <span>${format(borrowLimit.dp(2, 1).toString(10))}</span>
                  <img
                    className="arrow-right-img"
                    src={arrowRightImg}
                    alt="arrow"
                  />
                  <span>${format(newBorrowLimit.dp(2, 1).toString(10))}</span>
                </div>
              )}
            </div>
            <div className="flex align-center just-between borrow-limit-used">
              <span>Borrow Limit Used</span>
              {amount.isZero() || amount.isNaN() ? (
                <span>{borrowPercent.dp(2, 1).toString(10)}%</span>
              ) : (
                <div className="flex align-center just-between">
                  <span>{borrowPercent.dp(2, 1).toString(10)}%</span>
                  <img
                    className="arrow-right-img"
                    src={arrowRightImg}
                    alt="arrow"
                  />
                  <span>{newBorrowPercent.dp(2, 1).toString(10)}%</span>
                </div>
              )}
            </div>
            <Progress
              percent={newBorrowPercent.toNumber()}
              strokeColor="#56a4f6"
              strokeWidth={7}
              showInfo={false}
            />
          </div>
        )}
        {!isEnabled && asset.id !== 'bnb' ? (
          <Button
            className="button"
            disabled={isLoading}
            onClick={() => {
              onApprove()
            }}
          >
            {isLoading && <Icon type="loading" />} Enable
          </Button>
        ) : (
          <Button
            className="button"
            disabled={
              isLoading ||
              amount.isNaN() ||
              amount.isZero() ||
              amount.isGreaterThan(asset.walletBalance)
            }
            onClick={handleSupply}
          >
            {isLoading && <Icon type="loading" />} Supply
          </Button>
        )}
        <div className="description">
          <span>Wallet Balance</span>
          <span>
            {format(asset.walletBalance.dp(2, 1).toString(10))} {asset.symbol}
          </span>
        </div>
      </TabContent>
    </TabSection>
  )
}

SupplyTab.propTypes = {
  asset: PropTypes.object,
  settings: PropTypes.object,
  changeTab: PropTypes.func,
  onCancel: PropTypes.func,
  setSetting: PropTypes.func.isRequired
}

SupplyTab.defaultProps = {
  asset: {},
  settings: {},
  changeTab: () => {},
  onCancel: () => {}
}

const mapStateToProps = ({ account }) => ({
  settings: account.setting
})

const mapDispatchToProps = dispatch => {
  const { setSetting } = accountActionCreators

  return bindActionCreators(
    {
      setSetting
    },
    dispatch
  )
}

export default compose(connectAccount(mapStateToProps, mapDispatchToProps))(
  SupplyTab
)
