import React from 'react'
import PropTypes from 'prop-types'
import commaNumber from 'comma-number'
import { Card } from 'components/Basic/Card'
import { Flex, Text, IconButton, Button } from '@chakra-ui/react'
import { Currency } from 'components/Icons/Currency'
import { Usx } from 'components/Icons/USX'
import { Src1 } from 'components/Icons/SRC1'
import { PlusCircle } from 'components/Icons/PlusCircle'
import { OpenNewWindow } from 'components/Icons/OpenNewWindow'
import { shortenAddress } from 'utilities/shortenAddress'
import { SRC1_SYMBOL } from 'utilities/constants'

export const format = commaNumber.bindWith(',', '.')

function currency(icon, color) {
  if (icon === 'currency') {
    return <Currency w="25px" h="25px" mr="16px" fill={color} />
  }
  if (icon === SRC1_SYMBOL) {
    return <Src1 w="25px" h="25px" mr="16px" fill={color} />
  }
  return <Usx w="25px" h="25px" mr="16px" fill={color} />
}

export const InfoCard = ({
  address,
  addressHandler,
  label,
  addTokenHandler,
  addTokenLogic,
  apr,
  color,
  icon
}) => {
  return (
    <Card justifyContent="space-between">
      <Flex alignItems="center">
        {currency(icon, color)}
        <Text fontWeight="bold" fontSize="lg" mr={2} mt="4px">
          {label}
        </Text>
        {addTokenLogic && (
          <IconButton
            variant="outline"
            border="none"
            icon={<PlusCircle w="15px" h="15px" mr="16px" fill={color} />}
            onClick={addTokenHandler}
            _hover={{ bg: 'none' }}
          />
        )}
        {apr}
      </Flex>
      <Button
        display="flex"
        alignItems="center"
        justifyContent="center"
        onClick={addressHandler}
        variant="ghost"
      >
        <Text mt="7px">{shortenAddress(address, 4, 4)}</Text>
        <Flex alignItems="center" justifyContent="center">
          <OpenNewWindow w="15px" h="15px" ml="16px" />
        </Flex>
      </Button>
    </Card>
  )
}

InfoCard.propTypes = {
  address: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  addressHandler: PropTypes.func.isRequired,
  addTokenLogic: PropTypes.bool,
  addTokenHandler: PropTypes.func.isRequired,
  apr: PropTypes.node,
  color: PropTypes.string,
  icon: PropTypes.string
}

InfoCard.defaultProps = {
  apr: null,
  color: 'primary.500',
  addTokenLogic: false,
  icon: 'currency'
}
