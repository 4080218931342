import React from 'react'
import PropTypes from 'prop-types'
import Sidebar from 'containers/Layout/Sidebar'
import Header from 'containers/Layout/Header'
import Footer from 'containers/Layout/Footer'

function MainLayout({ title, isHeader, children }) {
  return (
    <>
      <Sidebar>
        {isHeader && <Header title={title} />}
        {children}
        <Footer />
      </Sidebar>
    </>
  )
}

MainLayout.propTypes = {
  title: PropTypes.string,
  isHeader: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

MainLayout.defaultProps = {
  title: '',
  isHeader: true,
  children: null
}

export default MainLayout
