import sample from 'lodash/sample'

const networks = {
  '97': {
    usxTokenAddress: '0xa2f5b7c9ba9d1e3147fe8ca0e391294b8d8daa18',
    src1VaultAddress: '0x7699ccf7d7e8f6562ca60cb1961e70b920c13d9c',
    usxVaultAddress: '0x88cd8503cfc4c33e34962a3724ab2c70362bfbbb',
    usxUnitrollerAddress: '0xb327c190e16a896ed650bb84fbf192e1412b9a3c',
    comptrollerAddress: '0x9d0190d7b36f433157a2b2ff1754d2deda1c23c6',
    priceOracleAddress: '0x90e724b20661cf3c514e40e82fea4249983b89cd',
    voteAddress: '0xEA96E194fA9cb3a2B2BDb557e6014CF469B40b77',
    explorer: 'https://testnet.bscscan.com',
    rpc: [
      'https://data-seed-prebsc-1-s1.binance.org:8545',
      'https://data-seed-prebsc-2-s1.binance.org:8545',
      'https://data-seed-prebsc-1-s2.binance.org:8545'
    ],
    timestampGenesisBlock: 1594600774,
    api: 'https://poc-source-api.herokuapp.com/api',
    name: 'Binance Smart Chain Testnet',
    env: 'development',
    tokens: {
      token: {
        usx: '0xa2f5b7c9ba9d1e3147fe8ca0e391294b8d8daa18',
        dai: '0xec5dcb5dbf4b114c9d0f65bccab49ec54f6a0867',
        usdc: '0x64544969ed7EBf5f083679233325356EbE738930',
        usdt: '0x337610d27c682e347c9cd60bd4b3b107c9d34ddd',
        busd: '0x78867bbeef44f2326bf8ddd1941a4439382ef2a7',
        src1: '0x6c8cc1c84f6798489eb1cb6dbf407470fc46f35b',
        btcb: '0x6ce8da28e2f864420840cf74474eff5fd80e65b8',
        eth: '0xd66c6b4f0be8ce5b39d52e0fd1344c389929b378',
        xrp: '0xa83575490d7df4e2f47b7d38ef351a2722ca45b9',
        ada: '0x2df3cad5eceff487c808483a466b80a68b214b32',
        link: '0x84b9b910527ad5c03a9ca831909e21e236ea7b06',
        matic: '0xd366fca9aa694114f3c91909e681c4035840f2a6',
        srcx: '0x454B90716a9435E7161a9AEa5Cf00e0aCBE565aE'
      },
      sbep: {
        bnb: '0xa9ceafd103b55fbb476b1f2b9492343c25a2e6de',
        usx: '0xd6ca05bd420a5c3e775de7e811ecc68d35af173a',
        dai: '0x4980675d2d5cdec1f5c13cb7d5790a3bb8f514a8',
        usdc: '0xd81cbc0292d327068864086c523f3881af62714b',
        usdt: '0x3021a266049ca487b04286d521ae9cc1f697b9c0',
        busd: '0xb5a64766b87f5b820fd344849e03218d2ddd7b61',
        src1: '0xda6e87b941d36437caea1117364c2b19107c6441',
        btcb: '0xffc5ffa81ec70edabde6577a34043ec7ac2470c4',
        eth: '0xe1883ce7aa24ea66ebcad9e241f43520b67c890e',
        xrp: '0xc849246b4859a84a6bee0f91b71fcaebdd1f901e',
        ada: '0xa121a0e4b577f24ada2e55057fb38aa2ba54057c',
        link: '0x19e4171a657c5bd7e36d6b13386102d3fea32497',
        matic: '0x9a66d5a5d61665534da734830e9dba72c2f43243',
        srcx: '0xd9570b8c69c63a3d0b47c2e097a784db16a51f1d'
      }
    }
  },
  '56': {
    usxTokenAddress: '',
    src1VaultAddress: '',
    usxVaultAddress: '',
    usxUnitrollerAddress: '',
    comptrollerAddress: '',
    priceOracleAddress: '',
    voteAddress: '',
    explorer: 'https://bscscan.com',
    rpc: [
      'https://bsc-dataseed1.ninicoin.io',
      'https://bsc-dataseed1.defibit.io',
      'https://bsc-dataseed.binance.org'
    ],
    timestampGenesisBlock: 1599097364,
    api: '',
    name: 'Binance Smart Chain',
    environment: 'production',
    tokens: {
      token: {
        usx: '',
        dai: '',
        usdc: '',
        usdt: '',
        busd: '',
        src1: '',
        btcb: '',
        eth: '',
        xrp: '',
        ada: '',
        link: '',
        matic: '',
        srcx: ''
      },
      sbep: {
        bnb: '',
        usx: '',
        dai: '',
        usdc: '',
        usdt: '',
        busd: '',
        src1: '',
        btcb: '',
        eth: '',
        xrp: '',
        ada: '',
        link: '',
        matic: '',
        srcx: ''
      }
    }
  },
  '5': {
    usxTokenAddress: '0xc7e8ed373ae03a4b31816620ac84b256d4ea19b0',
    src1VaultAddress: '0x323360fb05a09ebd606236435fa10f8c2a801070',
    usxVaultAddress: '0xb6ccd19e607713ece4773fe98864a25de26f1184',
    usxUnitrollerAddress: '0x73067a34230b3ca97759644111e6e209fb6424a1',
    comptrollerAddress: '0x7c1b10804b7fb880e21ef065e441a64903646b31',
    priceOracleAddress: '0xbc02ba7442d12baf8af34acc7eff16a795dd6724',
    voteAddress: '0xe27d4221bf22d061dff3e1a6ed2cd1ac75853abc',
    explorer: 'https://goerli.etherscan.io',
    rpc: [
      'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
      'https://rpc.goerli.mudit.blog',
      'https://rpc.ankr.com/eth_goerli'
    ],
    timestampGenesisBlock: 1599097364,
    api: 'https://dev-source-one-goerli-api.herokuapp.com/api',
    name: 'Ethereum Goerli Testnet RPC',
    environment: 'development',
    tokens: {
      token: {
        usx: '0xc7e8ed373ae03a4b31816620ac84b256d4ea19b0',
        ada: '0x97c75325a653f4168ebec7de625c4a8c741f6901',
        dai: '0xd787ec2b6c962f611300175603741db8438674a0',
        usdc: '0x0aa78575e17ac357294bb7b5a9ea512ba07669e2',
        usdt: '0xc6bbcbd377ac3dcc6f345f94b4e225ce76c00b7e',
        busd: '0xb809b9b2dc5e93cb863176ea2d565425b03c0540',
        src1: '0xb179a53678916f9280c483f501c1d63563d70695',
        btcb: '0x432abdafe0015dbd69c6398b387ea4f8c3694123',
        eth: '0xd3f8e37445bf1d457dc82d1341ebe6c6ecb581bf',
        xrp: '0x0d19f421e0625ac584a26c43f5cafa77444934e2',
        link: '0xc94bf8f0f35015d01da0dc3141fa37c605961030',
        matic: '0xefc9746314b7311e330963928018ebc77ae38c37',
        srcx: '0x22cDdC1b962e69969E804faCcF6333ddc3AcDc40'
      },
      sbep: {
        bnb: '0xe7ca9b31d4da213277c37d4c6c7cd63c0ffd0e4d',
        ada: '0x930f5bda4a704e0198c7da4414ee46c7222007be',
        usx: '0xe7ca9b31d4da213277c37d4c6c7cd63c0ffd0e4d',
        dai: '0x5d34da49fc8c776db47c6ffa13fdcf3db06794be',
        usdc: '0x483a7571280c4ad85343e03a0188dc23a47b87a9',
        usdt: '0xf559d3e2e5f4ac4faf806963c841fd341fd34dd8',
        busd: '0x28d5199aacf0eb79a9879c0f4146160bb59e9825',
        src1: '0xc978a803bab3e6f96c2a3e6b9c253e85f910f33d',
        btcb: '0x7993adeaf1fb40ce208a2153ce384bc6b77522d9',
        eth: '0x1785becf5d75db98f07a5869ec4eee8887a962c5',
        xrp: '0x98f7675577333bff56bf70f58bb3a6f4f7947428',
        link: '0x8f60b32063bf967d1aabc77aa2942afa9df57a9f',
        matic: '0x989fe8b2fa7ac1628f93d14b216087a3473b1c37',
        srcx: '0xC1063344108d24044B1C3FCbBA5E337e3f181fE4'
      }
    }
  }
}

export const network = networks[process.env.REACT_APP_CHAIN_ID]

export const getNodeUrl = () => {
  return sample(networks[process.env.REACT_APP_CHAIN_ID].rpc)
}

export const getNodeUrls = () => {
  return networks[process.env.REACT_APP_CHAIN_ID].rpc
}

export const getFirstNodeUrl = () => {
  return getNodeUrls()[0]
}

export const getExplorerUrl = () => {
  return networks[process.env.REACT_APP_CHAIN_ID].explorer
}
