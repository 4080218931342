import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints, mode } from '@chakra-ui/theme-tools'
import { colors } from './colors'
import { ButtonStyle as Button } from './components/Button'
import { ModalStyle as Modal } from './components/Modal'
import { SpinnerStyle as Spinner } from './components/Spinner'

// Breakpoints
const breakpoints = createBreakpoints({
  sm: '480px',
  md: '768px',
  lg: '992px',
  xl: '1280px',
  '2xl': '1920px'
})

const styles = {
  global: props => ({
    body: {
      backgroundColor: mode('gray.50', 'black.600')(props),
      backgroundSize: 'cover'
    },
    html: {
      scrollBehavior: 'smooth',
      height: '100%'
    },
    h1: {
      fontSize: '4xl'
    },
    h2: {
      fontSize: '3xl'
    },
    h3: {
      fontSize: '2xl'
    },
    h4: {
      fontSize: 'xl'
    },
    h5: {
      fontSize: 'lg'
    },
    h6: {
      fontSize: 'sm'
    }
    // '.scroll-container': {
    //   visibility: 'hidden',
    //   paddingRight: '12px'
    // },
    // '.scroll-container::-webkit-scrollbar': {
    //   background: 'transparent',
    //   width: '8px',
    //   height: '8px'
    // },
    // '.scroll-container::-webkit-scrollbar-thumb': {
    //   border: 'none',
    //   boxShadow: 'none',
    //   background: mode('blackAlpha.50', 'whiteAlpha.300')(props),
    //   borderRadius: '8px',
    //   minHeight: '40px'
    // },
    // '.scroll-container::-webkit-scrollbar-thumb:hover': {
    //   backgroundColor: mode('blackAlpha.800', 'whiteAlpha.800')(props)
    // },
    // '.scroll-container > div,.scroll-container:hover,.scroll-container:focus': {
    //   visibility: 'visible'
    // }
  })
}

export const theme = extendTheme({
  config: { initialColorMode: 'dark' },
  breakpoints,
  fonts: {
    body: 'Open Sans, Helvetica Neue',
    heading: 'Open Sans, Helvetica Neue',
    mono: 'Open Sans, Helvetica Neue'
  },
  colors,
  components: {
    Button,
    Modal,
    Spinner
  },
  shadows: {
    xl: '0 2px 4px 2px rgba(0,0,0,.15),0 2px 10px 2px rgba(0,0,0,.2)'
  },
  styles
})
