import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { bindActionCreators } from 'redux'
import BigNumber from 'bignumber.js'
import {
  getTokenContract,
  getSbepContract,
  getComptrollerContract,
  getUsxTokenContract,
  getLatestBlockNumber,
  methods
} from 'utilities/ContractService'
import { promisify } from 'utilities'
import * as constants from 'utilities/constants'
import ConnectModal from 'components/Basic/ConnectModal'
import { connectAccount, accountActionCreators } from 'core'
import toast from 'components/Basic/Toast'
import {
  Box,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure
} from '@chakra-ui/react'
import { useWeb3React } from '@web3-react/core'
import { MarketsContext } from 'context/MarketsContext'
import { SidebarContent, MobileNav } from './SidebarUi'
import { logErrorService } from '../../utilities/errorHandler'
import { network } from '../../config/network'

const abortController = new AbortController()

function Sidebar({ settings, setSetting, getGovernanceSourceOne, children }) {
  const { account } = useWeb3React()
  const [totalUsxMinted, setTotalUsxMinted] = useState('0')
  const [tvl, setTVL] = useState(new BigNumber(0))
  const { chainId } = useWeb3React()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal
  } = useDisclosure()
  const { availableMarkets, availableSbeps } = React.useContext(MarketsContext)

  useEffect(() => {
    try {
      if (chainId !== Number(process.env.REACT_APP_CHAIN_ID)) {
        toast.error({
          title: `Please connect your wallet to ${network.name}`
        })
      }
    } catch (error) {
      logErrorService(error)
    }
  }, [chainId])

  const getMarkets = useCallback(() => {
    let mounted = true
    try {
      ;(async () => {
        const res = await promisify(getGovernanceSourceOne, {})
        if (!res.status) {
          throw new Error('Governance Source One status error')
        }

        const markets = Object.keys(availableSbeps)
          .map(item =>
            res.data.markets.find(
              market =>
                market.underlyingSymbol.toLowerCase() === item.toLowerCase()
            )
          )
          .filter(item => !!item)
        const latestBlockNumber = await getLatestBlockNumber()
        if (mounted) {
          setSetting({
            markets,
            dailySourceOne: res.data.dailySourceOne,
            latestBlockNumber
          })
        }
      })()
    } catch (error) {
      logErrorService(error)
    }
    return () => {
      mounted = false
    }
  }, [getGovernanceSourceOne, setSetting])

  useEffect(() => {
    getMarkets()
    const updateTimer = setInterval(() => {
      getMarkets()
    }, 60000)
    return function cleanup() {
      abortController.abort()
      if (updateTimer) {
        clearInterval(updateTimer)
      }
    }
  }, [getMarkets])

  useEffect(() => {
    let mounted = true
    const getTotalUsxMinted = async () => {
      // total usx minted
      const usxContract = getUsxTokenContract()
      let tvm = await methods.call(usxContract.methods.totalSupply, [])
      tvm = new BigNumber(tvm).div(new BigNumber(10).pow(18))
      if (mounted) {
        setTotalUsxMinted(tvm)
      }
    }
    getTotalUsxMinted()
    return () => {
      mounted = false
    }
  }, [])

  useEffect(() => {
    let mounted = true
    const setDecimals = async () => {
      const decimals = {}
      Object.values(availableMarkets).forEach(async item => {
        decimals[`${item.id}`] = {}
        if (item.id !== 'bnb') {
          const tokenContract = getTokenContract(item.id)
          const tokenDecimals = await methods.call(
            tokenContract.methods.decimals,
            []
          )
          const sBepContract = getSbepContract(item.id)
          const stokenDecimals = await methods.call(
            sBepContract.methods.decimals,
            []
          )
          decimals[`${item.id}`].token = Number(tokenDecimals)
          decimals[`${item.id}`].stoken = Number(stokenDecimals)
          decimals[`${item.id}`].price = 18 + 18 - Number(tokenDecimals)
        } else {
          decimals[`${item.id}`].token = 18
          decimals[`${item.id}`].stoken = 8
          decimals[`${item.id}`].price = 18
        }
      })
      if (mounted) setSetting({ decimals })
    }
    const initSettings = async () => {
      if (mounted)
        setSetting({
          pendingInfo: {
            type: '',
            status: false,
            amount: 0,
            symbol: ''
          }
        })
    }

    setDecimals()
    initSettings()

    return () => {
      mounted = false
    }
  }, [])

  useEffect(() => {
    let mounted = true
    const updateMarketInfo = async () => {
      if (!account || !settings.decimals || !settings.markets) {
        return
      }
      const appContract = getComptrollerContract()
      const usxContract = getUsxTokenContract()

      try {
        let [vaultUsxStaked, sourceOneUSXVaultRate] = await Promise.all([
          methods.call(usxContract.methods.balanceOf, [
            constants.CONTRACT_SRC1_VAULT_ADDRESS
          ]),
          methods.call(appContract.methods.sourceOneUSXVaultRate, [])
        ])
        // Total Usx Staked
        vaultUsxStaked = new BigNumber(vaultUsxStaked).div(constants.mantissa)

        // source one usx vault rate
        sourceOneUSXVaultRate = new BigNumber(sourceOneUSXVaultRate)
          .div(constants.mantissa)
          .times(constants.blocksPerDay)

        // USX APY
        const src1Market = settings.markets.find(
          ele => ele.underlyingSymbol === constants.SRC1_SYMBOL
        )
        const usxAPY = new BigNumber(sourceOneUSXVaultRate)
          .times(src1Market ? src1Market.tokenPrice : 0)
          .times(365 * 100)
          .div(vaultUsxStaked)
          .decimalPlaces(2, 1)
          .toString(10)

        const totalLiquidity = (settings.markets || []).reduce(
          (accumulator, market) => {
            return new BigNumber(accumulator).plus(
              new BigNumber(market.totalSupplyUsd)
            )
          },
          vaultUsxStaked
        )
        if (mounted) {
          setSetting({
            usxAPY,
            vaultUsxStaked
          })
          setTVL(totalLiquidity)
        }
      } catch (error) {
        logErrorService(error)
      }
    }
    if (mounted) {
      updateMarketInfo()
    }
    return () => {
      mounted = false
    }
  }, [account, settings.markets])

  return (
    <>
      <Box minH="100vh" bg={useColorModeValue('gray.100', 'black.600')}>
        <SidebarContent
          onClose={() => onClose}
          display={{ base: 'none', md: 'flex' }}
          settings={settings}
          tvl={tvl}
          totalUsxMinted={totalUsxMinted}
          setIsOpenModal={onOpenModal}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent>
            <SidebarContent
              onClose={onClose}
              settings={settings}
              tvl={tvl}
              totalUsxMinted={totalUsxMinted}
              setIsOpenModal={onOpenModal}
            />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav display={{ base: 'flex', md: 'none' }} onOpen={onOpen} />
        <Box
          ml={{ base: 0, md: 60 }}
          p="4"
          bg={useColorModeValue('white', 'black.600')}
        >
          {children}
        </Box>
      </Box>
      <ConnectModal visible={isOpenModal} onCancel={onCloseModal} />
    </>
  )
}

Sidebar.propTypes = {
  settings: PropTypes.object,
  setSetting: PropTypes.func.isRequired,
  getGovernanceSourceOne: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
}

Sidebar.defaultProps = {
  settings: {}
}

const mapStateToProps = ({ account }) => ({
  settings: account.setting
})

const mapDispatchToProps = dispatch => {
  const { setSetting, getGovernanceSourceOne } = accountActionCreators

  return bindActionCreators(
    {
      setSetting,
      getGovernanceSourceOne
    },
    dispatch
  )
}

export default compose(connectAccount(mapStateToProps, mapDispatchToProps))(
  Sidebar
)
