import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import LineProgressBar from 'components/Basic/LineProgressBar'
import BigNumber from 'bignumber.js'
import commaNumber from 'comma-number'
import { compose } from 'recompose'
import { connectAccount } from 'core'
import { Card } from 'components/Basic/Card'
import { getBigNumber } from 'utilities/common'
import { Text } from '@chakra-ui/react'
import { useWeb3React } from '@web3-react/core'

const format = commaNumber.bindWith(',', '.')
const abortController = new AbortController()

function BorrowLimit({ settings }) {
  const [available, setAvailable] = useState('0')
  const [borrowPercent, setBorrowPercent] = useState(0)
  const { account } = useWeb3React()

  useEffect(() => {
    if (account) {
      const totalBorrowBalance = getBigNumber(settings.totalBorrowBalance)
      const totalBorrowLimit = getBigNumber(settings.totalBorrowLimit)
      const total = BigNumber.maximum(totalBorrowLimit, 0)
      setAvailable(total.dp(2, 1).toString(10))
      setBorrowPercent(
        total.isZero() || total.isNaN()
          ? 0
          : totalBorrowBalance
              .div(total)
              .times(100)
              .dp(0, 1)
              .toNumber()
      )
    }
    return function cleanup() {
      abortController.abort()
    }
  }, [account, settings.totalBorrowBalance, settings.totalBorrowLimit])

  return (
    <Card
      bg="primary.500"
      justifyContent="center"
      flexDir="column"
      textAlign="center"
    >
      <Text fontSize="4xl" color="black">
        ${format(available)}
      </Text>
      <Text fontSize="md" fontWeight="bold" mb={6} color="black">
        Available Credit
      </Text>
      <LineProgressBar
        label="Borrow Limit"
        percent={borrowPercent}
        color="black"
        progressColor="blackAlpha"
      />
    </Card>
  )
}

BorrowLimit.propTypes = {
  settings: PropTypes.object
}

BorrowLimit.defaultProps = {
  settings: {}
}

const mapStateToProps = ({ account }) => ({
  settings: account.setting
})

export default compose(connectAccount(mapStateToProps, undefined))(BorrowLimit)
