/* eslint-disable no-useless-escape */
import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { connectAccount, accountActionCreators } from 'core'
import MainLayout from 'containers/Layout/MainLayout'
import { promisify } from 'utilities'
import { GridItem } from '@chakra-ui/react'

import { Grid } from 'components/Basic/Grid'
import { Left } from 'components/Vote/VoteOverview/Left'
import { Right } from 'components/Vote/VoteOverview/Right'
import { logErrorService } from '../../utilities/errorHandler'
import { voteListCount } from '../../utilities/constants'

function VoteOverview({ settings, getVoters, getProposalById, match }) {
  const [proposalInfo, setProposalInfo] = useState({})
  const [agreeVotes, setAgreeVotes] = useState({})
  const [againstVotes, setAgainstVotes] = useState({})

  useEffect(() => {
    let mounted = true
    const getProposal = async () => {
      if (match.params && match.params.id) {
        await promisify(getProposalById, {
          id: match.params.id
        }).then(res => {
          if (mounted) setProposalInfo(res.data)
        })
      }
    }
    try {
      getProposal()
    } catch (error) {
      logErrorService(error)
    }
    return () => {
      mounted = false
    }
  }, [match, getProposalById])

  const loadVotes = useCallback(
    async (limit, mounted) => {
      if (proposalInfo.id && mounted) {
        await promisify(getVoters, {
          id: proposalInfo.id,
          limit,
          filter: 'for'
        })
          .then(res => {
            if (mounted) {
              setAgreeVotes(res.data || {})
            }
          })
          .catch(() => {
            if (mounted) setAgreeVotes({})
          })
        await promisify(getVoters, {
          id: proposalInfo.id,
          limit,
          filter: 'against'
        })
          .then(res => {
            if (mounted) {
              setAgainstVotes(res.data || {})
            }
          })
          .catch(() => {
            if (mounted) setAgainstVotes({})
          })
      }
    },
    [getVoters, proposalInfo]
  )

  useEffect(() => {
    let mounted = true
    try {
      loadVotes(voteListCount, mounted)
    } catch (error) {
      logErrorService(error)
    }
    return () => {
      mounted = false
    }
  }, [loadVotes])

  return (
    <MainLayout title="Vote">
      <Grid
        desktop={
          <>
            <GridItem colSpan={2}>
              <Left
                proposalInfo={proposalInfo}
                agreeVotes={agreeVotes}
                againstVotes={againstVotes}
                getVoters={getVoters}
                setAgainstVotes={setAgainstVotes}
                setAgreeVotes={setAgreeVotes}
              />
            </GridItem>
            <GridItem colSpan={1}>
              <Right proposalInfo={proposalInfo} settings={settings} />
            </GridItem>
          </>
        }
        mobile={
          <GridItem colSpan={3}>
            <Left
              proposalInfo={proposalInfo}
              agreeVotes={agreeVotes}
              againstVotes={againstVotes}
              getVoters={getVoters}
              setAgainstVotes={setAgainstVotes}
              setAgreeVotes={setAgreeVotes}
            />
            <Right proposalInfo={proposalInfo} settings={settings} />
          </GridItem>
        }
      />
    </MainLayout>
  )
}

VoteOverview.propTypes = {
  match: PropTypes.object,
  settings: PropTypes.object,
  getProposalById: PropTypes.func.isRequired,
  getVoters: PropTypes.func.isRequired
}

VoteOverview.defaultProps = {
  match: {},
  settings: {}
}

const mapStateToProps = ({ account }) => ({
  settings: account.setting
})

const mapDispatchToProps = dispatch => {
  const { getProposalById, getVoters } = accountActionCreators

  return bindActionCreators(
    {
      getProposalById,
      getVoters
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connectAccount(mapStateToProps, mapDispatchToProps)
)(VoteOverview)
