import React, { useEffect } from 'react'
import { compose } from 'recompose'
import { connectAccount } from 'core'
import { ExchangeCard } from 'components/Basic/ExchangeCard'
import { useWeb3React } from '@web3-react/core'
import { SRC1_SYMBOL } from 'utilities/constants'

const abortController = new AbortController()

function Exchange() {
  const { account } = useWeb3React()

  const handleLink = () => {
    window.open(
      `https://app.rango.exchange/swap/BSC.BNB/BSC.${SRC1_SYMBOL}/`,
      '_blank'
    )
  }

  useEffect(() => {
    return function cleanup() {
      abortController.abort()
    }
  }, [account])

  return <ExchangeCard addressHandler={handleLink} label="Exchange" />
}

Exchange.propTypes = {}

Exchange.defaultProps = {
  settings: {}
}

const mapStateToProps = ({ account }) => ({
  settings: account.setting
})

export default compose(connectAccount(mapStateToProps, undefined))(Exchange)
