import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import commaNumber from 'comma-number'
import { Card } from 'components/Basic/Card'

const VotingPowerWrapper = styled.div`
  width: 100%;

  .title {
    font-size: 20px;
    color: var(--color-text-main);
    font-weight: bold;
  }

  .content {
    color: var(--color-text-main);
    font-size: 28.5px;
    font-weight: 900;
  }

  span {
    color: var(--color-bg-main);
  }
`

const format = commaNumber.bindWith(',', '.')

function VotingPower({ power }) {
  const getBefore = value => {
    const position = value.indexOf('.')
    return position !== -1 ? value.slice(0, position + 5) : value
  }

  const getAfter = value => {
    const position = value.indexOf('.')
    return position !== -1 ? value.slice(position + 5) : null
  }

  return (
    <Card bg="primary.500">
      <VotingPowerWrapper className="flex flex-column">
        <p className="title">Voting Weight</p>
        <p className="content">
          {getBefore(format(power))}
          <span>{getAfter(format(power))}</span>
        </p>
      </VotingPowerWrapper>
    </Card>
  )
}

VotingPower.propTypes = {
  power: PropTypes.string
}

VotingPower.defaultProps = {
  power: '0.00000000'
}

export default VotingPower
