import React from 'react'
import { createIcon } from '@chakra-ui/react'

export const OpenNewWindow = createIcon({
  displayName: 'OpenNewWindow',
  path: (
    <path
      d="M23.62,14.82v11.25H0V4.69h13.5v2.25H2.25v16.88h19.12v-9H23.62z M27,1.32H14.64l4.54,4.5l-7.85,7.95l3.18,3.18
	L22.36,9L27,13.69V1.32z"
    />
  ),
  viewBox: '0 0 27 27'
})
