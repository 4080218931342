import React, { useMemo } from 'react'
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  useColorModeValue,
  Link,
  Text,
  Button,
  Image
} from '@chakra-ui/react'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import commaNumber from 'comma-number'
import { getBigNumber } from 'utilities/common'
import { Chart } from 'components/Icons/Chart'
import { Market } from 'components/Icons/Market'
import { Vault } from 'components/Icons/Vault'
import { Src1 } from 'components/Icons/SRC1'
import { Vote } from 'components/Icons/Vote'
import { Menu } from 'components/Icons/Menu'
import { shortenAddress } from 'utilities/shortenAddress'
import Logo from 'assets/img/source_one_logo.svg'
import { useWeb3React } from '@web3-react/core'

const format = commaNumber.bindWith(',', '.')

const LinkItems = [
  { name: 'Dashboard', icon: Chart, href: '/dashboard' },
  { name: 'Vote', icon: Vote, href: '/vote' },
  { name: 'Source One SRC1', icon: Src1, href: '/src1' },
  { name: 'Market', icon: Market, href: '/market' },
  { name: 'Vault', icon: Vault, href: '/vault' }
]

const LogoLink = () => (
  <Link as={NavLink} to="/" alt="logo" fontSize="2xl" fontWeight="bold">
    <Image src={Logo} w="180px" />
  </Link>
)

export const SidebarContent = ({
  onClose,
  settings,
  tvl,
  totalUsxMinted,
  setIsOpenModal,
  ...rest
}) => {
  const { account } = useWeb3React()
  const { push } = useHistory()
  const { pathname } = useLocation()
  const totalList = useMemo(
    () => [
      {
        title: 'Total Value Locked',
        description: format(new BigNumber(tvl).dp(2, 1).toString(10))
      },
      {
        title: 'Total USX Minted',
        description: format(
          getBigNumber(totalUsxMinted)
            .dp(0, 1)
            .toString(10)
        )
      }
    ],
    [tvl, totalUsxMinted]
  )

  return (
    <Flex
      bg={useColorModeValue('white', 'black.500')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      flexDir="column"
      display="flex"
      {...rest}
    >
      <Flex
        h="20"
        alignItems="center"
        mx="6"
        mb="6"
        justifyContent="space-between"
      >
        <LogoLink />
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {LinkItems.map(link => (
        <Button
          key={link.name}
          leftIcon={<link.icon mr="4px" />}
          onClick={() => push(link.href)}
          variant="ghost"
          justifyContent="flex-start"
          borderRadius="none"
          px="6"
          _hover={{
            borderRight: '2px',
            borderColor: 'primary.500',
            color: 'primary.500',
            fill: 'primary.500',
            bg: 'primary.400'
          }}
          isActive={pathname.includes(link.href)}
          _active={{
            borderRight: '2px',
            borderColor: 'primary.500',
            color: 'primary.500',
            fill: 'primary.500',
            bg: 'primary.400'
          }}
        >
          {link.name}
        </Button>
      ))}
      <Flex alignItems="flex-end" mx="6" pb="6" flexGrow={1}>
        <Flex flexDir="column">
          {account && (
            <>
              {totalList.map(item => (
                <Box key={item.title} mb="2">
                  <Text>{item.title}</Text>
                  <Text fontWeight="bold">{item.description}</Text>
                </Box>
              ))}
            </>
          )}
          <Button onClick={setIsOpenModal}>
            {!account ? 'Connect' : shortenAddress(account, 6, 4)}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

SidebarContent.propTypes = {}

export const MobileNav = ({ onOpen, ...rest }) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'black.500')}
      justifyContent="flex-start"
      {...rest}
    >
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<Menu />}
        border="none"
        ml={2}
      />
      <LogoLink />
    </Flex>
  )
}
