import React from 'react'
import { createIcon } from '@chakra-ui/react'

export const PlusCircle = createIcon({
  displayName: 'PlusCircle',
  path: (
    <g>
      <path
        d="M13.55,0C6.12,0,0.09,6.02,0.09,13.45s6.02,13.45,13.45,13.45S27,20.88,27,13.45S20.98,0,13.55,0z
		 M20.27,14.58h-5.61v5.61h-2.24v-5.61H6.82v-2.24h5.61V6.73h2.24v5.61h5.61V14.58z"
      />
    </g>
  ),
  viewBox: '0 0 27 27'
})
