import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { Icon } from 'antd'
import Button from '@material-ui/core/Button'
import NumberFormat from 'react-number-format'
import { connectAccount } from 'core'
import BigNumber from 'bignumber.js'
import {
  getUsxControllerContract,
  getUsxTokenContract,
  methods
} from 'utilities/ContractService'
import commaNumber from 'comma-number'
import * as constants from 'utilities/constants'
import usxImg from 'assets/img/coins/usx.png'
import { TabSection, TabContent } from 'components/Basic/BorrowModal'
import { getBigNumber } from 'utilities/common'
import { useWeb3React } from '@web3-react/core'

const format = commaNumber.bindWith(',', '.')

function RepayUsxTab({ settings }) {
  const { account } = useWeb3React()
  const [isLoading, setIsLoading] = useState(false)
  const [amount, setAmount] = useState(new BigNumber(0))
  const [userUsxMinted, setUserUsxMinted] = useState(new BigNumber(0))
  const [usxBalance, setUsxBalance] = useState(new BigNumber(0))

  useEffect(() => {
    setUserUsxMinted(getBigNumber(settings.userUsxMinted))
  }, [settings.userUsxMinted])

  useEffect(() => {
    setUsxBalance(getBigNumber(settings.userUsxBalance))
  }, [settings.userUsxBalance])

  /**
   * Max amount
   */
  const handleMaxAmount = () => {
    setAmount(BigNumber.minimum(userUsxMinted, usxBalance))
  }

  /**
   * Approve USX token
   */
  const onUsxApprove = async () => {
    if (account) {
      setIsLoading(true)
      const usxContract = getUsxTokenContract()
      methods
        .send(
          usxContract.methods.approve,
          [
            constants.CONTRACT_USX_UNITROLLER_ADDRESS,
            new BigNumber(2)
              .pow(256)
              .minus(1)
              .toString(10)
          ],
          account
        )
        .then(() => {
          setIsLoading(false)
        })
        .catch(() => {
          setIsLoading(false)
        })
    }
  }

  /**
   * Repay USX
   */
  const handleRepayUSX = () => {
    if (account) {
      const appContract = getUsxControllerContract()
      setIsLoading(true)
      methods
        .send(
          appContract.methods.repayUSX,
          [
            amount
              .times(new BigNumber(10).pow(18))
              .dp(0)
              .toString(10)
          ],
          account
        )
        .then(() => {
          setAmount(new BigNumber(0))
          setIsLoading(false)
        })
        .catch(() => {
          setIsLoading(false)
        })
    }
  }

  return (
    <TabSection>
      <div className="flex flex-column align-center just-center body-content repay-usx-content">
        {settings.userUsxEnabled ? (
          <div className="flex align-center input-wrapper">
            <NumberFormat
              autoFocus
              value={amount.isZero() ? '0' : amount.toString(10)}
              onValueChange={({ value }) => {
                setAmount(new BigNumber(value))
              }}
              isAllowed={({ value }) => {
                return new BigNumber(value || 0).isLessThanOrEqualTo(
                  BigNumber.minimum(usxBalance, userUsxMinted)
                )
              }}
              thousandSeparator
              allowNegative={false}
              placeholder="0"
            />
            <span className="pointer max" onClick={() => handleMaxAmount()}>
              MAX
            </span>
          </div>
        ) : (
          <>
            <img src={usxImg} alt="asset" />
            <p className="center warning-label">
              To repay USX to the Source Marketplace, you need to approve it
              first.
            </p>
          </>
        )}
      </div>
      <TabContent className="flex flex-column align-center just-content usx-content-section">
        <div className="flex flex-column just-center align-center apy-content">
          <div className="description">
            <div className="flex align-center">
              <img className="asset-img" src={usxImg} alt="asset" />
              <div className="usx-balance">
                <span>Repay USX</span>
                <span>Balance</span>
              </div>
            </div>
            <span>{format(userUsxMinted.dp(2, 1).toString(10))} USX</span>
          </div>
        </div>
        {(usxBalance.isZero() || amount.isGreaterThan(usxBalance)) && (
          <p className="center warning-label">Your balance is not enough.</p>
        )}
        {!settings.userUsxEnabled ? (
          <Button
            className="button"
            disabled={isLoading || usxBalance.isZero()}
            onClick={() => {
              onUsxApprove()
            }}
          >
            {isLoading && <Icon type="loading" />} Enable
          </Button>
        ) : (
          <Button
            className="button usx-auto"
            disabled={
              isLoading ||
              amount.isNaN() ||
              amount.isZero() ||
              amount.isGreaterThan(userUsxMinted) ||
              amount.isGreaterThan(usxBalance)
            }
            onClick={handleRepayUSX}
          >
            {isLoading && <Icon type="loading" />} Repay USX
          </Button>
        )}
        <div className="description">
          <span>USX Balance</span>
          <span>{format(usxBalance.dp(2, 1).toString(10))} USX</span>
        </div>
      </TabContent>
    </TabSection>
  )
}

RepayUsxTab.propTypes = {
  settings: PropTypes.object
}

RepayUsxTab.defaultProps = {
  settings: {}
}

const mapStateToProps = ({ account }) => ({
  settings: account.setting
})

export default compose(connectAccount(mapStateToProps, undefined))(RepayUsxTab)
