import React from 'react'
import { createIcon } from '@chakra-ui/react'

export const ArrowRight = createIcon({
  displayName: 'ArrowRight',
  path: (
    <g>
      <polygon points="5.06,3.38 8.5,0 21.94,13.5 8.5,27 5.06,23.62 15.19,13.5 	" />
    </g>
  ),
  viewBox: '0 0 27 27'
})
