import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import { compose } from 'recompose'
import { bindActionCreators } from 'redux'
import commaNumber from 'comma-number'
import { Row, Col, Icon } from 'antd'
import styled from 'styled-components'
import { connectAccount, accountActionCreators } from 'core'
import {
  getTokenContract,
  getComptrollerContract,
  methods
} from 'utilities/ContractService'
import MainLayout from 'containers/Layout/MainLayout'
import { GridItem, Text, Center, Spinner, Flex, Box } from '@chakra-ui/react'
import * as constants from 'utilities/constants'
import usxImg from 'assets/img/coins/usx.png'
import { Grid } from 'components/Basic/Grid'
import { Card } from 'components/Basic/Card'
import { Src1 } from 'components/Icons/SRC1'
import LineProgressBar from 'components/Basic/LineProgressBar'
import { useWeb3React } from '@web3-react/core'
import { MarketsContext } from 'context/MarketsContext'
import { BASE_BSC_SCAN_URL } from '../../config'
import { logErrorService } from '../../utilities/errorHandler'

const TableWrapper = styled.div`
  position: relative;
  width: 100%;
  background: var(--color-bg-primary);
  border: 1px solid var(--color-bg-primary);
  box-sizing: content-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  max-width: 1200px;

  @media (max-width: 768px) {
    width: 90%;
  }

  .header-title {
    padding: 20px;
    font-weight: 600;
    font-size: 20px;
    color: var(--color-white);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  .table-title {
    padding: 20px;
  }

  .divider {
    border: 0;
    border-color: rgba(255, 255, 255, 0.1) !important;
    border-style: solid !important;
    border-bottom-width: 1px !important;
    width: 100%;
  }

  .table_header {
    padding: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);

    > div {
      color: var(--color-white);
      font-weight: bold;
      cursor: pointer;

      img {
        width: 16px;
        height: 16px;
        margin: 0 10px;
      }
    }

    @media (max-width: 1050px) {
      .borrow-apy,
      .per-day,
      .supply-apy,
      .total-distributed {
        display: none;
      }
    }
  }

  .table_content {
    .table_item {
      padding: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);

      div {
        color: var(--color-white);
        max-width: 100%;
      }

      .mobile-label {
        display: none;
        @media (max-width: 1050px) {
          font-weight: bold;
          display: block;
        }
      }

      .market {
        .highlight {
          word-break: break-all;
          white-space: break-spaces;
        }

        .asset-img {
          width: 30px;
          height: 30px;
          margin-right: 10px;
        }

        .usx-img {
          width: 26px;
          height: 26px;
          margin-right: 14px;
        }
      }
    }
  }
`

const format = commaNumber.bindWith(',', '.')

function SRC1({ settings }) {
  const [markets, setMarkets] = useState([])
  const [dailyDistribution, setDailyDistribution] = useState('0')
  const [totalDistributed, setTotalDistributed] = useState('0')
  const [remainAmount, setRemainAmount] = useState('0')
  const [sortInfo, setSortInfo] = useState({ field: '', sort: 'desc' })
  const { account } = useWeb3React()
  const { availableMarkets } = React.useContext(MarketsContext)
  const mintedAmount = '23700000'

  useEffect(() => {
    let mounted = true
    const getSRC1Info = async () => {
      const tempMarkets = []
      const sum = (settings.markets || []).reduce((accumulator, market) => {
        return new BigNumber(accumulator).plus(
          new BigNumber(market.totalDistributed)
        )
      }, 0)
      const compContract = getComptrollerContract()

      // total info
      let sourceOneUSXVaultRate = await methods.call(
        compContract.methods.sourceOneUSXVaultRate,
        []
      )
      sourceOneUSXVaultRate = new BigNumber(sourceOneUSXVaultRate)
        .div(1e18)
        .times(20 * 60 * 24)
      const tokenContract = getTokenContract(constants.SRC1_SYMBOL)
      const remainedAmount = await methods.call(
        tokenContract.methods.balanceOf,
        [constants.CONTRACT_COMPTROLLER_ADDRESS]
      )
      if (mounted) {
        setDailyDistribution(
          new BigNumber(settings.dailySourceOne)
            .div(new BigNumber(10).pow(18))
            .plus(sourceOneUSXVaultRate)
            .dp(2, 1)
            .toString(10)
        )
        setTotalDistributed(sum.toString(10))
        setRemainAmount(
          new BigNumber(remainedAmount)
            .div(new BigNumber(10).pow(18))
            .dp(2, 1)
            .toString(10)
        )
      }
      for (let i = 0; i < settings.markets.length; i += 1) {
        tempMarkets.push({
          underlyingSymbol: settings.markets[i].underlyingSymbol,
          perDay: +new BigNumber(settings.markets[i].supplierDailySourceOne)
            .plus(new BigNumber(settings.markets[i].borrowerDailySourceOne))
            .div(new BigNumber(10).pow(18))
            .dp(2, 1)
            .toString(10),
          supplyAPY: +(new BigNumber(
            settings.markets[i].supplySourceOneApy
          ).isLessThan(0.01)
            ? '0.01'
            : new BigNumber(settings.markets[i].supplySourceOneApy)
                .dp(2, 1)
                .toString(10)),
          borrowAPY: +(new BigNumber(
            settings.markets[i].borrowSourceOneApy
          ).isLessThan(0.01)
            ? '0.01'
            : new BigNumber(settings.markets[i].borrowSourceOneApy)
                .dp(2, 1)
                .toString(10))
        })
      }
      tempMarkets.push({
        underlyingSymbol: 'USX',
        perDay: +sourceOneUSXVaultRate.dp(2, 1).toString(10),
        supplyAPY: settings.usxAPY || 0,
        borrowAPY: 0
      })
      if (mounted) {
        setMarkets(tempMarkets)
      }
    }
    try {
      if (settings.markets && settings.dailySourceOne && mounted) {
        getSRC1Info()
      }
    } catch (error) {
      logErrorService(error)
    }
    return () => {
      mounted = false
    }
  }, [settings.markets, settings.dailySourceOne, settings.usxAPY])

  const handleSort = field => {
    setSortInfo({
      field,
      sort:
        sortInfo.field === field && sortInfo.sort === 'desc' ? 'asc' : 'desc'
    })
  }

  return (
    <MainLayout isHeader={false}>
      {(!account || settings.accountLoading || settings.wrongNetwork) && (
        <Center>
          <Spinner color="primary.500" size="xl" speed="1s" />
        </Center>
      )}
      {account && !settings.accountLoading && !settings.wrongNetwork && (
        <Grid
          mobile={
            <>
              <GridItem colSpan={{ base: 3, sm: 2 }} mb={6}>
                <Card flexDir="column" justifyContent="flex-start">
                  <Box w="100%" mb={6}>
                    <Text fontSize="2xl">Market Distribution</Text>
                  </Box>
                  <TableWrapper>
                    <div className="table_content">
                      <Row className="divider table_header text">
                        <Col
                          xs={{ span: 24 }}
                          lg={{ span: 6 }}
                          className="flex align-center market"
                        >
                          <Text fontWeight="bold">Market</Text>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          lg={{ span: 6 }}
                          className="per-day right"
                        >
                          <Text fontWeight="bold">
                            <span onClick={() => handleSort('perDay')}>
                              <Src1
                                w="15px"
                                h="15px"
                                mr="10px"
                                fill="primary.500"
                              />
                              Per Day{' '}
                              {sortInfo.field === 'perDay' && (
                                <Icon
                                  type={
                                    sortInfo.sort === 'desc'
                                      ? 'caret-down'
                                      : 'caret-up'
                                  }
                                />
                              )}
                            </span>
                          </Text>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          lg={{ span: 6 }}
                          className="supply-apy right"
                        >
                          <Text fontWeight="bold">
                            <span onClick={() => handleSort('supplyAPY')}>
                              Supply
                              <Src1
                                w="15px"
                                h="15px"
                                mx="10px"
                                fill="primary.500"
                              />
                              APY{' '}
                              {sortInfo.field === 'supplyAPY' && (
                                <Icon
                                  type={
                                    sortInfo.sort === 'desc'
                                      ? 'caret-down'
                                      : 'caret-up'
                                  }
                                />
                              )}
                            </span>
                          </Text>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          lg={{ span: 6 }}
                          className="borrow-apy right"
                        >
                          <Text fontWeight="bold">
                            <span onClick={() => handleSort('borrowAPY')}>
                              Borrow
                              <Src1
                                w="15px"
                                h="15px"
                                mx="10px"
                                fill="primary.500"
                              />
                              APY{' '}
                              {sortInfo.field === 'borrowAPY' && (
                                <Icon
                                  type={
                                    sortInfo.sort === 'desc'
                                      ? 'caret-down'
                                      : 'caret-up'
                                  }
                                />
                              )}
                            </span>
                          </Text>
                        </Col>
                      </Row>
                      {markets &&
                        (markets || [])
                          .sort((a, b) => {
                            if (sortInfo.field) {
                              if (sortInfo.field === 'perDay') {
                                return sortInfo.sort === 'desc'
                                  ? +new BigNumber(b.perDay)
                                      .minus(new BigNumber(a.perDay))
                                      .toString(10)
                                  : +new BigNumber(a.perDay)
                                      .minus(new BigNumber(b.perDay))
                                      .toString(10)
                              }
                              if (sortInfo.field === 'supplyAPY') {
                                return sortInfo.sort === 'desc'
                                  ? +new BigNumber(b.supplyAPY)
                                      .minus(new BigNumber(a.supplyAPY))
                                      .toString(10)
                                  : +new BigNumber(a.supplyAPY)
                                      .minus(new BigNumber(b.supplyAPY))
                                      .toString(10)
                              }
                              if (sortInfo.field === 'borrowAPY') {
                                return sortInfo.sort === 'desc'
                                  ? +new BigNumber(b.borrowAPY)
                                      .minus(new BigNumber(a.borrowAPY))
                                      .toString(10)
                                  : +new BigNumber(a.borrowAPY)
                                      .minus(new BigNumber(b.borrowAPY))
                                      .toString(10)
                              }
                            }
                            return +new BigNumber(b.perDay)
                              .minus(new BigNumber(a.perDay))
                              .toString(10)
                          })
                          .map((item, index) => (
                            <Row
                              className="divider table_item text"
                              key={index}
                            >
                              <Col
                                xs={{ span: 24 }}
                                lg={{ span: 6 }}
                                className="flex align-center market"
                              >
                                {item.underlyingSymbol !== 'USX' ? (
                                  <img
                                    className="asset-img"
                                    src={
                                      availableMarkets[
                                        item.underlyingSymbol.toLowerCase()
                                      ].asset
                                    }
                                    alt="asset"
                                  />
                                ) : (
                                  <img
                                    className="usx-img"
                                    src={usxImg}
                                    alt="asset"
                                  />
                                )}
                                <p>{item.underlyingSymbol}</p>
                              </Col>
                              <Col
                                xs={{ span: 24 }}
                                lg={{ span: 6 }}
                                className="per-day right"
                              >
                                <p className="mobile-label">Per day</p>
                                <p>{item.perDay}</p>
                              </Col>
                              <Col
                                xs={{ span: 24 }}
                                lg={{ span: 6 }}
                                className="supply-apy right"
                              >
                                <p className="mobile-label">Supply APY</p>
                                <p>{item.supplyAPY}%</p>
                              </Col>
                              <Col
                                xs={{ span: 24 }}
                                lg={{ span: 6 }}
                                className="borrow-apy right"
                              >
                                <p className="mobile-label">Borrow APY</p>
                                {item.underlyingSymbol !== 'USX' ? (
                                  <p>{item.borrowAPY}%</p>
                                ) : (
                                  <p>-</p>
                                )}
                              </Col>
                              {/* <Col xs={{ span: 24 }} lg={{ span: 4 }} className="total-distributed right">
                            <p className="mobile-label">Total Distributed</p>
                            <p>{format(item.totalDistributed.toString())}</p>
                          </Col> */}
                            </Row>
                          ))}
                      <div className="divider" />
                    </div>
                  </TableWrapper>
                </Card>
              </GridItem>
              <GridItem colSpan={{ md: 1, sm: 1 }} mb={3}>
                <Card justifyContent="space-between" height="auto">
                  <Flex alignItems="center" overflow="hidden">
                    <Src1 w="25px" h="25px" mr="16px" fill="primary.500" />
                    <a
                      fontSize={{ base: 'xs', sm: 'md' }}
                      href={`${BASE_BSC_SCAN_URL}/token/${constants.CONTRACT_SRC1_TOKEN_ADDRESS}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {constants.CONTRACT_SRC1_TOKEN_ADDRESS}
                    </a>
                  </Flex>
                </Card>
                <Card
                  justifyContent="space-between"
                  height="auto"
                  flexDir="column"
                >
                  <Flex w="100%" flexDir="row">
                    <Box mr={6}>
                      <Text>Daily Distribution</Text>
                      <Text fontSize="2xl">{format(dailyDistribution)}</Text>
                    </Box>
                    {/* <Box>
                        <Text>Total Distributed</Text>
                        <Text>{format(totalDistributed)}</Text>
                      </Box> */}
                    <Box>
                      <Text>Remaining</Text>
                      <Text fontSize="2xl">{format(remainAmount)}</Text>
                    </Box>
                  </Flex>
                  <Flex flexDir="column">
                    <LineProgressBar
                      progressColor="gray"
                      percent={new BigNumber(totalDistributed)
                        .dividedBy(new BigNumber(mintedAmount))
                        .multipliedBy(100)
                        .toNumber()}
                    />
                  </Flex>
                </Card>
              </GridItem>
            </>
          }
        />
      )}
    </MainLayout>
  )
}

SRC1.propTypes = {
  settings: PropTypes.object
}

SRC1.defaultProps = {
  settings: {}
}

const mapStateToProps = ({ account }) => ({
  settings: account.setting
})

const mapDispatchToProps = dispatch => {
  const { getVoterAccounts } = accountActionCreators

  return bindActionCreators(
    {
      getVoterAccounts
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connectAccount(mapStateToProps, mapDispatchToProps)
)(SRC1)
